/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			example component description

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card, Divider, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material/'
import React, { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetDocument, DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { returnProjectMilestoneTimelineDates, returnSortedWorkflowPhasesArray } from '../services/project_phases'

///////////////////////////////
// Typescript
///////////////////////////////

// TODO: Rename and fill out
interface TsInterface_ComponentName {
  // propKey: type
  clientKeyOverride?: string
  projectKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

// TODO: Rename
export const ProjectPacingTimeline = forwardRef((props: TsInterface_ComponentName, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ComponentName['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ComponentName['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)

  // Hooks - useContext, useState, useReducer, other
  const [us_milestoneTimelineDates, us_setMilestoneTimelineDates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseWorkflowTasks, us_setPhaseWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_sortedMilestonePhaseTasksArray, us_setSortedMilestonePhaseTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_project, us_setProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProject(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProject({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    if (us_project != null && us_project.associated_task_workflow_key != null && us_project.associated_task_workflow_key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_TaskWorkflow_Document(actualClientKey, us_project.associated_task_workflow_key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setTaskWorkflow({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_project, pr_clientKey])

  useEffect(() => {
    if (us_project != null && us_project.associated_task_workflow_key != null && us_project.associated_task_workflow_key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(actualClientKey, us_project.associated_task_workflow_key as string))
            .then((res_DGD) => {
              us_setPhaseWorkflowTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setPhaseWorkflowTasks({})
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_project, pr_clientKey])

  useEffect(() => {
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestones'] != null && us_phaseWorkflowTasks != null && us_phaseWorkflowTasks['tasks'] != null) {
      let sortedMilestonePhaseTasksArray = returnSortedWorkflowPhasesArray(us_phaseWorkflowTasks['tasks'], us_taskWorkflow)
      us_setSortedMilestonePhaseTasksArray(sortedMilestonePhaseTasksArray)
      // let sortedMilestoneTaskObject: TsInterface_UnspecifiedObject = {}
      // for (let loopMilestoneTaskIndex in sortedMilestonePhaseTasksArray) {
      //   let loopMilestoneTask = sortedMilestonePhaseTasksArray[loopMilestoneTaskIndex]
      //   sortedMilestoneTaskObject[loopMilestoneTask.key] = getProp(loopMilestoneTask, 'phase_tasks', {})
      // }
      // us_setSortedMilestonePhaseTasksObject(sortedMilestoneTaskObject)
    }
  }, [us_taskWorkflow, us_phaseWorkflowTasks])

  useEffect(() => {
    if (us_sortedMilestonePhaseTasksArray != null && us_sortedMilestonePhaseTasksArray.length > 0) {
      // Loop through milestones and calculate phase days data
      let milestoneDates = returnProjectMilestoneTimelineDates(us_sortedMilestonePhaseTasksArray, us_project, us_projectTasks)
      us_setMilestoneTimelineDates(milestoneDates)
    }
  }, [us_sortedMilestonePhaseTasksArray, us_project, us_projectTasks])

  // Functions
  const returnMilestoneColorsAndIcons = (
    milestoneTimelineData: TsInterface_UnspecifiedObject,
  ): { milestoneColor: string; milestoneStatusIconJSX: JSX.Element } => {
    let milestoneStatusIconJSX: JSX.Element = <></>
    let milestoneTimelineColor = themeVariables.gray_600
    if (milestoneTimelineData != null && milestoneTimelineData.status === 'complete') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-check"
          className="tw-text-info_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.info_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'in_progress' && milestoneTimelineData.end_date_type === 'estimated_delayed') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-error_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.error_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.using_extension_days === true && milestoneTimelineData.status === 'in_progress') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-warning_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.warning_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'in_progress') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-success_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.success_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'not_started') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-pause"
          className="tw-text-gray_600 tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.gray_600
    }
    return {
      milestoneStatusIconJSX: milestoneStatusIconJSX,
      milestoneColor: milestoneTimelineColor,
    }
  }

  // JSX Generation
  const rJSX_PhaseDate = (phaseDate: number, phaseDateType: string, phaseDateOriginal: number | null, typeOverride: string | null): JSX.Element => {
    let phaseStartDateJSX: JSX.Element = <></>
    if (typeOverride === 'sticky_note') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-secondary_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="note"
            className="tw-ml-2"
            tooltip={rLIB('Sticky Note')}
          />
        </Box>
      )
    } else if (phaseDateType === 'actual') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-check"
            className="tw-ml-2"
            tooltip={rLIB('Actual Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'estimated') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-ml-2 tw-text-info_main"
            tooltip={rLIB('Estimated Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'estimated_delayed') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-ml-2 tw-text-info_main"
            tooltip={rLIB('Estimated Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'corrected_actual') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-warning_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {phaseDateOriginal != null ? (
            <>
              <Box className="tw-inline-block tw-mr-2">{returnFormattedDate(phaseDateOriginal, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          {phaseDate != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1 tw-line-through tw-italic tw-opacity-50">{returnFormattedDate(phaseDate, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          <Icon
            icon="circle-info"
            className=" tw-ml-2"
            tooltip={rLIB('Milestone Completed Out of Order')}
          />
        </Box>
      )
    } else if (phaseDateType === 'range') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-gray_600"
          sx={{ minWidth: '110px', textAlign: 'center', color: themeVariables.gray_600 }}
        >
          {phaseDate != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1">{returnFormattedDate(phaseDate, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          {phaseDate != null && phaseDateOriginal != null ? <>{'- '}</> : <></>}
          {phaseDateOriginal != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1">{returnFormattedDate(phaseDateOriginal, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          <Icon
            icon="circle-info"
            className=" tw-ml-2"
            tooltip={rLIB('Estimated Date Range')}
          />
        </Box>
      )
    } else if (phaseDateType === 'corrected_estimated') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-warning_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-text-warning_main tw-ml-2"
            tooltip={rLIB('Milestone Completed Out of Order')}
          />
        </Box>
      )
    }
    return phaseStartDateJSX
  }

  const rJSX_InPhaseDays = (actualDays: number, estimatedDays: number, extensionDays: number, status: string): JSX.Element => {
    let phaseDaysJSX: JSX.Element = <></>
    if (actualDays == null) {
      actualDays = 0
    }
    if (estimatedDays == null) {
      estimatedDays = 0
    }
    if (extensionDays == null) {
      extensionDays = 0
    }
    let color = themeVariables.gray_600
    if (actualDays < estimatedDays) {
      color = themeVariables.success_main
    } else if (actualDays < estimatedDays + extensionDays) {
      color = themeVariables.warning_main
    } else {
      color = themeVariables.error_main
    }
    let textColor = themeVariables.white
    let boxType = 'contained'
    if (status === 'complete') {
      boxType = 'contained'
    } else if (status === 'in_progress') {
      boxType = 'outlined'
    } else if (status === 'not_started') {
      boxType = 'outlined'
      color = themeVariables.gray_600
      textColor = themeVariables.gray_600
    }
    if (boxType === 'contained') {
      if (extensionDays > 0) {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md "
            sx={{ backgroundColor: color, color: textColor }}
          >
            {actualDays} /{' '}
            <Box
              component="span"
              className="tw-italic tw-line-through tw-opacity-50"
            >
              {estimatedDays}
            </Box>{' '}
            {estimatedDays + extensionDays} {estimatedDays + extensionDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      } else {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md "
            sx={{ backgroundColor: color, color: textColor }}
          >
            {actualDays} / {estimatedDays} {estimatedDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      }
    } else {
      if (extensionDays > 0) {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md"
            sx={{ border: `1px solid ${color}`, color: textColor }}
          >
            {actualDays} /{' '}
            <Box
              component="span"
              className="tw-italic tw-line-through tw-opacity-50"
            >
              {estimatedDays}
            </Box>{' '}
            {estimatedDays + extensionDays} {estimatedDays + extensionDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      } else {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md"
            sx={{ border: `1px solid ${color}`, color: textColor }}
          >
            {actualDays} / {estimatedDays} {estimatedDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      }
    }

    // phaseDaysJSX = <Box className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-gray_700">{estimatedDays}</Box>

    return phaseDaysJSX
  }

  const rJSX_PhaseListContainer = (): JSX.Element => {
    return (
      <Box sx={{ maxWidth: '1200px', width: '100%', margin: '0 auto' }}>
        {us_sortedMilestonePhaseTasksArray.map((milestoneTask: TsInterface_UnspecifiedObject, index: number) => (
          <Card
            key={index}
            className="tw-mb-4 tw-p-2"
            sx={{ borderLeft: `8px solid ${returnMilestoneColorsAndIcons(us_milestoneTimelineDates[milestoneTask.key]).milestoneColor}` }}
          >
            <Stack
              direction="row"
              spacing={1}
              className="tw-mb-2"
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 900 }}
              >
                {returnMilestoneColorsAndIcons(us_milestoneTimelineDates[milestoneTask.key]).milestoneStatusIconJSX}
                {milestoneTask.name}
              </Typography>
              {rJSX_InPhaseDays(
                getProp(us_milestoneTimelineDates, milestoneTask.key, {}).actual_days,
                getProp(us_milestoneTimelineDates, milestoneTask.key, {}).expected_days,
                getProp(us_milestoneTimelineDates, milestoneTask.key, {}).extension_days,
                getProp(us_milestoneTimelineDates, milestoneTask.key, {}).status,
              )}
            </Stack>
            <Divider className="tw-my-2" />
            <Box className="tw-ml-8">
              {/* <Json
                data={getProp(us_milestoneTimelineDates, milestoneTask.key, {})}
                alphabetized={true}
              /> */}
              <Table
                sx={{ tableLayout: 'auto', width: 'auto' }}
                size="small"
              >
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: 'none', padding: '4px' }}>
                      {rJSX_PhaseDate(
                        getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date,
                        getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date_type,
                        getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date_original,
                        null,
                      )}
                    </TableCell>
                    <TableCell sx={{ border: 'none', padding: '4px' }}>
                      <Typography variant="subtitle1">
                        {getProp(getProp(us_milestoneTimelineDates, milestoneTask.key, {}), 'previous_milestone_name', '')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {objectToArray(getProp(getProp(us_milestoneTimelineDates, milestoneTask.key, {}), 'phase_events', {}))
                    .sort(dynamicSort('timestamp', 'asc'))
                    .map((event: TsInterface_UnspecifiedObject, index: number) => (
                      <TableRow key={index}>
                        <TableCell sx={{ border: 'none', padding: '4px' }}>{rJSX_PhaseDate(event.timestamp, 'actual', null, event.type)}</TableCell>
                        <TableCell sx={{ border: 'none', padding: '4px' }}>
                          <Typography variant="subtitle1">{event.name}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  {objectToArray(getProp(getProp(us_milestoneTimelineDates, milestoneTask.key, {}), 'incomplete_milestone_tasks', {}))
                    .sort(dynamicSort('name', 'asc'))
                    .map((incompleteTask: TsInterface_UnspecifiedObject, index: number) => (
                      <TableRow key={index}>
                        <TableCell sx={{ border: 'none', padding: '4px' }}>
                          {rJSX_PhaseDate(
                            getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date,
                            'range',
                            getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date,
                            null,
                          )}
                        </TableCell>
                        <TableCell sx={{ border: 'none', padding: '4px' }}>
                          <Typography variant="subtitle1">{incompleteTask.name}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell sx={{ border: 'none', padding: '4px' }}>
                      {rJSX_PhaseDate(
                        getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date,
                        getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date_type,
                        getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date_original,
                        null,
                      )}
                    </TableCell>
                    <TableCell sx={{ border: 'none', padding: '4px' }}>
                      <Typography variant="subtitle1">{getProp(getProp(us_milestoneTimelineDates, milestoneTask.key, {}), 'task_name', '')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            {/* <Json
              data={milestoneTask}
              alphabetized={true}
            /> */}
          </Card>
        ))}
      </Box>
    )
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = <Box>{rJSX_PhaseListContainer()}</Box>
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
})
