/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { getDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_Imports_Missing_Tasks,
  DatabaseRef_Material_Imports_Collection,
  DatabaseRef_Material_Imports_Document,
} from 'rfbp_aux/services/database_endpoints/materials/material_imports'
import { DatabaseRef_Suppliers_Collection } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_ActiveUsers_Query } from 'rfbp_aux/services/database_endpoints/users'
import { Icon } from 'rfbp_core/components/icons'
import AdvancedImportButtonAndDialog, { performPreImportChecks } from 'rfbp_core/components/imports/material_import_button_and_dialog'

import { TableBasic, TableCellBasic, TsInterface_TableColumns, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

// -----------------------------------------------------------------------------
// Additional references & queries
// -----------------------------------------------------------------------------
type TsInterface_QueryOperatorsArray = any
type TsInterface_OrderByArray = any

type Supplier = { key: string; name: string }

// -----------------------------------------------------------------------------
// Helper to sanitize row data
// -----------------------------------------------------------------------------
const sanitizeRow = (row: any): any => {
  return Object.keys(row).reduce((acc: any, key: string) => {
    if (row[key] !== undefined) acc[key] = row[key]
    return acc
  }, {})
}

// -----------------------------------------------------------------------------
// Build a doc key
// -----------------------------------------------------------------------------
export const buildUniqueKey = (rowData: any): string => {
  // Updated cleanup: replace all spaces with underscores and all slashes with underscores.
  const cleanup = (value: any): string => {
    return value != null ? String(value).replace(/\s+/g, '_').replace(/\//g, '_') : ''
  }
  const supp = cleanup(rowData.associated_supplier_name).toUpperCase().slice(0, 3)
  const inv = cleanup(rowData.invoice_reference_number).toUpperCase()
  const job = cleanup(rowData.project_job_code)
  // Use sku_number if available; otherwise, fallback to sku_description; then cleanup.
  const skuNumber = typeof rowData.sku_number === 'string' ? cleanup(rowData.sku_number) : ''
  const skuDescription = typeof rowData.sku_description === 'string' ? cleanup(rowData.sku_description) : ''
  const sku = skuNumber !== '' ? skuNumber : skuDescription !== '' ? skuDescription : 'N/A'
  const last4 = job.slice(-4)
  const combined = `${supp}_${inv}_${last4}_${sku}`
  return combined || 'fallback_missing_key'
}

// -----------------------------------------------------------------------------
// Final import submission
// -----------------------------------------------------------------------------
const baseFinalImportSubmission = (finalDataToImport: any, importHooks: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const { uc_setUserInterface_ErrorDialogDisplay } = importHooks

    getClientKey(importHooks.uc_RootData_ClientKey, importHooks.uc_setRootData_ClientKey)
      .then(async (res_GCK) => {
        const clientKey = res_GCK.clientKey

        // Track duplicates & inserted docIds
        const duplicates: string[] = []
        const insertedDocIDs: string[] = []

        // Prepare batch updates
        const updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        const originalCount = finalDataToImport.length

        for (const item of finalDataToImport) {
          const row = sanitizeRow(item.row)
          const docId = buildUniqueKey(row)

          // If ORDERED_WITH is missing => set missing
          if (!row.ORDERED_WITH || String(row.ORDERED_WITH).trim() === '') {
            row.ordered_with_user_key = 'missing'
            row.ordered_with_user_name = 'missing'
          }

          const existingDocRef = DatabaseRef_Material_Imports_Document(clientKey, docId)
          try {
            const docSnap = await getDoc(existingDocRef)
            if (docSnap.exists()) {
              duplicates.push(docId) // skip
            } else {
              updateArray.push({
                type: 'setMerge',
                ref: existingDocRef,
                data: {
                  ...row,
                  status: 'pending',
                  key: docId,
                },
              })
              insertedDocIDs.push(docId)
            }
          } catch (e) {
            console.error('Error checking doc existence for docId:', docId, e)
            // Decide whether to skip or fail. We'll skip for now
          }
        }

        if (updateArray.length === 0) {
          const summary = {
            originalCount,
            duplicatesCount: duplicates.length,
            insertedCount: 0,
            insertedDocIDs: [],
          }
          resolve({ success: true, summary })
          return
        }

        // We have something to insert => do staged batch
        DatabaseStagedBatchUpdate(updateArray)
          .then(() => {
            const summary = {
              originalCount,
              duplicatesCount: duplicates.length,
              insertedCount: insertedDocIDs.length,
              insertedDocIDs,
            }
            resolve({ success: true, summary })
          })
          .catch((rej_DSBU) => {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                code: 'BATCH-UPDATE',
                message: 'Batch Update Error',
                details: JSON.stringify(rej_DSBU, null, 2),
              },
            })
            reject(rej_DSBU)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'CLIENT-KEY',
            message: 'Client Key Error',
            details: JSON.stringify(rej_GCK, null, 2),
          },
        })
        reject(rej_GCK)
      })
  })
}

// -----------------------------------------------------------------------------
// MAIN CONTAINER
// -----------------------------------------------------------------------------
export const Container: React.FC = (): JSX.Element => {
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  const [us_materialImports, us_setMaterialImports] = useState<TsInterface_UnspecifiedObject>({})
  const [us_materialImportsTaskCleanUp, us_setMaterialImportsTaskCleanUp] = useState<TsInterface_UnspecifiedObject>({})
  const [us_suppliers, us_setSuppliers] = useState<TsInterface_UnspecifiedObject>({})
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>({ key: '', name: '' })

  // For "ordered with" user editing
  const [us_users, us_setUsers] = useState<TsInterface_UnspecifiedObject>({})

  // Task edit dialog
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogData, setDialogData] = useState<any>({})
  const [dialogOptions, setDialogOptions] = useState<any[]>([])
  const [selectedOptionKey, setSelectedOptionKey] = useState<string>('')

  // Ordered with user edit dialog
  const [orderedWithDialogOpen, setOrderedWithDialogOpen] = useState(false)
  const [orderedWithDialogData, setOrderedWithDialogData] = useState<any>({})
  const [orderedWithDialogOptions, setOrderedWithDialogOptions] = useState<any[]>([])
  const [selectedOrderedWithOptionKey, setSelectedOrderedWithOptionKey] = useState<string>('')

  // Post-import summary dialog
  const [us_importSummaryDialogOpen, us_setImportSummaryDialogOpen] = useState(false)

  // Summaries
  interface ImportSummary {
    originalCount?: number
    duplicatesCount?: number
    insertedCount?: number
    insertedDocIDs?: string[]
    totalImported?: number
  }
  const [us_importSummary, us_setImportSummary] = useState<ImportSummary>({})

  // ---------------------------------
  // Effects
  // ---------------------------------
  useEffect(() => {
    document.title = 'Material Imports'
  }, [])

  // LISTEN: Material Imports
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction | undefined
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setMaterialImports(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Material_Imports_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error('Error fetching Material Imports:', rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // LISTEN: Missing Tasks
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction | undefined
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setMaterialImportsTaskCleanUp(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Imports_Missing_Tasks(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error('Error fetching Task Clean Up:', rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // LISTEN: Suppliers
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction | undefined
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSuppliers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Suppliers_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error('Error fetching Suppliers:', rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // LISTEN: Active Users
  useEffect(() => {
    let unsubscribeLiveUsers: TsType_VoidFunction | undefined

    const updateLiveUsers: any = (newData: TsInterface_UnspecifiedObject[]) => {
      // Filter to only include users with associated_organization_type === "internal"
      const filteredUsers = objectToArray(newData).filter((user) => user.associated_organization_type === 'internal')

      // Sort alphabetically by the 'name' property.
      // (Change "name" to whatever key you want to sort by if needed.)
      const sortedUsers = filteredUsers.sort((a, b) => a.name.localeCompare(b.name))

      us_setUsers(sortedUsers)
      ur_forceRerender()
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveUsers = DatabaseGetLiveCollection(DatabaseRef_ActiveUsers_Query(res_GCK.clientKey), updateLiveUsers)
      })
      .catch((rej_GCK) => {
        console.error('Error fetching users:', rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveUsers === 'function') {
        unsubscribeLiveUsers()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // ---------------------------------
  // Dialog logic
  // ---------------------------------
  const handleDialogSubmit = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const docId = dialogData.docId || dialogData.key
        if (res_GCK.clientKey && docId) {
          const selectedOption = dialogOptions.find((option) => option && option.key === selectedOptionKey)
          if (!selectedOption) {
            console.error('No valid task selected')
            return
          }
          const updatedData = {
            associated_task_key: selectedOption.value,
            associated_task_name: selectedOption.display,
            associated_team_name: selectedOption.teamName,
            associated_team_key: selectedOption.teamKey,
          }
          DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(res_GCK.clientKey, docId), updatedData)
            .then(() => setDialogOpen(false))
            .catch((error) => {
              console.error('Update failed', error)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
        } else {
          console.error('Missing client key or docId')
        }
      })
      .catch((error) => {
        console.error('Client key error', error)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
      })
  }

  const handleOrderedWithDialogSubmit = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const docId = orderedWithDialogData.docId || orderedWithDialogData.key
        if (res_GCK.clientKey && docId) {
          const selectedUserOption = orderedWithDialogOptions.find((option) => option && option.key === selectedOrderedWithOptionKey)
          if (!selectedUserOption) {
            console.error('No valid user selected')
            return
          }
          const updatedData = {
            ordered_with_user_key: selectedUserOption.value,
            ordered_with_user_name: selectedUserOption.display,
          }
          DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(res_GCK.clientKey, docId), updatedData)
            .then(() => setOrderedWithDialogOpen(false))
            .catch((error) => {
              console.error('Update failed', error)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
        } else {
          console.error('Missing client key or docId')
        }
      })
      .catch((error) => {
        console.error('Client key error', error)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
      })
  }

  // ------------------------------------------------------------------
  // Dialog components
  // ------------------------------------------------------------------
  const EditTaskDialog: React.FC = () => (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
    >
      <DialogTitle>{rLIB('Edit Task Name')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="edit-task-select-label">{rLIB('Task Name')}</InputLabel>
          <Select
            labelId="edit-task-select-label"
            value={selectedOptionKey}
            onChange={(e) => setSelectedOptionKey(e.target.value as string)}
            input={<OutlinedInput label={rLIB('Task Name')} />}
          >
            {dialogOptions.map((option) => (
              <MenuItem
                key={option.key}
                value={option.key}
              >
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setDialogOpen(false)}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDialogSubmit}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )

  const EditOrderedWithUserDialog: React.FC = () => (
    <Dialog
      open={orderedWithDialogOpen}
      onClose={() => setOrderedWithDialogOpen(false)}
    >
      <DialogTitle>{rLIB('Select User')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="edit-user-select-label">{rLIB('User')}</InputLabel>
          <Select
            labelId="edit-user-select-label"
            value={selectedOrderedWithOptionKey}
            onChange={(e) => setSelectedOrderedWithOptionKey(e.target.value as string)}
            input={<OutlinedInput label={rLIB('User')} />}
          >
            {orderedWithDialogOptions.map((option) => (
              <MenuItem
                key={option.key}
                value={option.key}
              >
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOrderedWithDialogOpen(false)}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleOrderedWithDialogSubmit}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )

  // ------------------------------------------------------------------
  // Table columns & settings
  // ------------------------------------------------------------------
  const tableColumns_TaskCleanUp: TsInterface_TableColumns = {
    associated_task_name: {
      header: {
        header_jsx: () => rLIB('Task Name'),
        header_sort_by: 'associated_task_name',
      },
      cell: {
        cell_jsx: (rowData, tableAdditionalData, tableHooks) => (
          <Box className="tw-flex tw-items-center">
            {rowData.associated_task_name}
            <Icon
              icon="pen-to-square"
              onClick={() => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    const projectKey = rowData.associated_project_key
                    if (!projectKey) {
                      console.error('No project key found in row data:', rowData)
                      return
                    }
                    const tasksQuery = DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, projectKey as any)
                    DatabaseGetCollection(tasksQuery)
                      .then((res_tasks) => {
                        const tasksMapping = res_tasks.success && res_tasks.data ? res_tasks.data : {}
                        const tasksOptionsRaw = Object.keys(tasksMapping).map((taskKey, index) => {
                          const task = tasksMapping[taskKey]
                          const computedTeamKey = task.associated_team_key || task.team_key || ''
                          if (!computedTeamKey || !task.associated_task_key) return null
                          return {
                            key: `${taskKey}_${index}`,
                            value: taskKey,
                            display: task.name || task.associated_task_name || taskKey,
                            teamName: task.associated_team_name || task.team_name || '',
                            teamKey: computedTeamKey,
                          }
                        })
                        const tasksOptions = tasksOptionsRaw.filter((option) => option !== null)
                        tasksOptions.unshift({
                          key: 'default',
                          value: '',
                          display: 'Select a Task',
                          teamName: '',
                          teamKey: '',
                        })
                        const initialOption = tasksOptions.find((opt) => opt && opt.value === rowData.associated_task_key)
                        const initialTaskOptionKey = initialOption ? initialOption.key : ''
                        setDialogData(rowData)
                        setDialogOptions(tasksOptions)
                        setSelectedOptionKey(initialTaskOptionKey)
                        setDialogOpen(true)
                      })
                      .catch((error) => {
                        console.error('Error fetching tasks:', error)
                      })
                  })
                  .catch((error) => {
                    console.error('Error getting client key:', error)
                  })
              }}
              className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Edit')}
              tooltipPlacement="right"
            />
          </Box>
        ),
      },
    },
    associated_team_name: TableCellBasic('associated_team_name', 'Team Name', 'associated_team_name'),
    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    date: TableCellBasic('date', 'Date', 'date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Ref', 'invoice_reference_number'),
    project_job_code: TableCellBasic('project_job_code', 'Project Job Code', 'project_job_code'),
    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
    sku_number: TableCellBasic('sku_number', 'SKU Number', 'sku_number'),
    sku_description: TableCellBasic('sku_description', 'SKU Description', 'sku_description'),
  }

  const tableSettings_TaskRedos: TsInterface_TableSettings = {
    paginated: true,
    pagination_rows_per_page_default: 100,
    pagination_rows_per_page_options: [10, 25, 50, 100],
    show_header: true,
    size: 'small',
    sortable: true,
    sort_direction: 'asc',
    sort_property_default: 'timestamp',
  }

  const tableColumns_MaterialImports: TsInterface_TableColumns = {
    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    date: TableCellBasic('date', 'Date', 'date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Reference Number', 'invoice_reference_number'),
    project_job_code: TableCellBasic('project_job_code', 'Project Job Code', 'project_job_code'),
    associated_task_name: TableCellBasic('associated_task_name', 'Task Name', 'associated_task_name'),
    associated_team_name: TableCellBasic('associated_team_name', 'Team Name', 'associated_team_name'),
    ordered_with_user_name: TableCellBasic('ordered_with_user_name', 'Person', 'ordered_with_user_name'),
    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
    sku_number: TableCellBasic('sku_number', 'SKU Number', 'sku_number'),
    sku_description: TableCellBasic('sku_description', 'SKU Description', 'sku_description'),
  }

  const tableColumns_MaterialImportsPersonCleanUp: TsInterface_TableColumns = {
    action: {
      header: { header_jsx: () => rLIB('Action') },
      cell: {
        cell_jsx: (rowData, tableAdditionalData, tableHooks) => (
          <Box className="tw-flex tw-items-center tw-gap-2">
            {/* GREEN CHECK => auto-assign suggested user */}
            <Icon
              icon="check"
              className="tw-cursor-pointer tw-text-success_main tw-opacity-80 hover:tw-opacity-100"
              tooltip={rLIB('Use Suggested User')}
              onClick={() => {
                if (!rowData.suggested_user_key) {
                  console.log('No suggested user found. Doing nothing.')
                  return
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    const docId: any = rowData.docId || rowData.key
                    if (!docId) {
                      console.error('No docId found on row.')
                      return
                    }
                    const updatedData = {
                      ordered_with_user_key: rowData.suggested_user_key,
                      ordered_with_user_name: rowData.suggested_user_name,
                    }
                    DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(res_GCK.clientKey, docId), updatedData)
                      .then(() => console.log('Successfully updated doc with suggested user.'))
                      .catch((error) => {
                        console.error('Error updating doc with suggested user', error)
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                      })
                  })
                  .catch((error) => {
                    console.error('Error retrieving client key:', error)
                  })
              }}
            />
            {/* RED X => open user selection dialog */}
            <Icon
              icon="xmark"
              className="tw-cursor-pointer tw-text-error_main tw-opacity-80 hover:tw-opacity-100"
              tooltip={rLIB('Select Different User')}
              onClick={() => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    const userObjects = objectToArray(us_users)
                    const userOptionsRaw = userObjects.map((u: any) => ({
                      key: u.key,
                      value: u.key,
                      display: u.displayName || u.name || u.key,
                    }))
                    userOptionsRaw.unshift({
                      key: 'default',
                      value: '',
                      display: 'Select a User',
                    })
                    setOrderedWithDialogData(rowData)
                    setOrderedWithDialogOptions(userOptionsRaw)
                    const initialKey = rowData.ordered_with_user_key || rowData.suggested_user_key || ''
                    const userOption = userOptionsRaw.find((opt) => opt.value === initialKey)
                    const initialOptionKey = userOption ? userOption.key : 'default'
                    setSelectedOrderedWithOptionKey(initialOptionKey)
                    setOrderedWithDialogOpen(true)
                  })
                  .catch((error) => {
                    console.error('Error fetching client key for user selection dialog:', error)
                  })
              }}
            />
          </Box>
        ),
      },
    },
    suggested_user_name: TableCellBasic('suggested_user_name', 'Suggested User', 'suggested_user_name'),
    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    date: TableCellBasic('date', 'Date', 'date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Reference Number', 'invoice_reference_number'),
    project_job_code: TableCellBasic('project_job_code', 'Project Job Code', 'project_job_code'),
    associated_task_name: TableCellBasic('associated_task_name', 'Task Name', 'associated_task_name'),
    associated_team_name: TableCellBasic('associated_team_name', 'Team Name', 'associated_team_name'),
    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
    sku_number: TableCellBasic('sku_number', 'SKU Number', 'sku_number'),
    sku_description: TableCellBasic('sku_description', 'SKU Description', 'sku_description'),
  }

  // ---------------------------------------------------------
  // RENDER METHODS FOR TABS
  // ---------------------------------------------------------
  const rJSX_deductionValues = (): JSX.Element => {
    return (
      <Card className="tw-p-4 tw-mt-4">
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_MaterialImports}
          tableData={objectToArray(us_materialImports)}
          tableSettings={tableSettings_TaskRedos}
        />
      </Card>
    )
  }

  const rJSX_taskCleanUp = (): JSX.Element => {
    return (
      <Card className="tw-p-4 tw-mt-4">
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_TaskCleanUp}
          tableData={objectToArray(us_materialImportsTaskCleanUp)}
          tableSettings={tableSettings_TaskRedos}
        />
      </Card>
    )
  }

  const rJSX_PersonCleanUp = (): JSX.Element => {
    const allRows = objectToArray(us_materialImports)
    const filteredRows = allRows.filter((row) => row.ordered_with_user_key === 'missing')
    return (
      <Card className="tw-p-4 tw-mt-4">
        <TableBasic
          tableAdditionalData={{
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            uc_setUserInterface_ErrorDialogDisplay,
          }}
          tableColumns={tableColumns_MaterialImportsPersonCleanUp}
          tableData={filteredRows}
          tableSettings={tableSettings_TaskRedos}
        />
      </Card>
    )
  }

  const taskCleanUpCount = Object.keys(us_materialImportsTaskCleanUp).length
  const allRows = objectToArray(us_materialImports)
  const personCleanUpCount = allRows.filter((row) => row.ordered_with_user_key === 'missing').length

  // ---------------------------------------------------------
  // POST-IMPORT SUMMARY
  // ---------------------------------------------------------
  const ImportSummaryDialog: React.FC = () => (
    <Dialog
      open={us_importSummaryDialogOpen}
      onClose={() => us_setImportSummaryDialogOpen(false)}
    >
      <DialogTitle>{rLIB('Import Summary')}</DialogTitle>
      <DialogContent>
        {us_importSummary.originalCount !== undefined && (
          <Box className="tw-mb-2">
            <strong>Rows in file:</strong> {us_importSummary.originalCount}
          </Box>
        )}
        {us_importSummary.duplicatesCount !== undefined && (
          <Box className="tw-mb-2">
            <strong>Duplicates Skipped:</strong> {us_importSummary.duplicatesCount}
          </Box>
        )}
        {us_importSummary.insertedCount !== undefined && (
          <Box className="tw-mb-2">
            <strong>Rows Added:</strong> {us_importSummary.insertedCount}
          </Box>
        )}
        {Array.isArray(us_importSummary.insertedDocIDs) && us_importSummary.insertedDocIDs.length > 0 && (
          <>
            <Box className="tw-mb-2">
              <strong>Inserted Doc IDs:</strong>
            </Box>
            {us_importSummary.insertedDocIDs.map((docId) => (
              <Box
                key={docId}
                className="tw-ml-4"
              >
                - {docId}
              </Box>
            ))}
          </>
        )}
        {us_importSummary.totalImported !== undefined && (
          <Box className="tw-mb-2">
            <strong>Total Imported:</strong> {us_importSummary.totalImported}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => us_setImportSummaryDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )

  // ---------------------------------------------------------
  // ADVANCED IMPORT
  // ---------------------------------------------------------
  // columns we allow in the advanced import
  const allowedColumnsMapping = {
    invoice_reference_number: {
      key: 'invoice_reference_number',
      required: true,
      label: 'Invoice Reference Number',
      automatch_properties: ['INVOICE_NUMBER', 'Transaction ID'],
    },
    sku_number: {
      key: 'sku_number',
      required: false,
      label: 'SKU Number',
      automatch_properties: ['SKU Number'],
    },
    sku_description: {
      key: 'sku_description',
      required: false,
      label: 'SKU Description',
      automatch_properties: ['DESC_COL', 'SKU Description'],
    },
    quantity: {
      key: 'quantity',
      required: true,
      label: 'Quantity',
      automatch_properties: ['ORDER_QTY_COL', 'SHIP_QTY_COL', 'Quantity'],
    },
    unit_price: {
      key: 'unit_price',
      required: false,
      label: 'Unit Price',
      automatch_properties: ['NET_UNIT_PRICE_COL', 'Net Unit Price'],
    },
    total_price: {
      key: 'total_price',
      required: false,
      label: 'Total Price',
      automatch_properties: ['NET_AMOUNT_COL', 'TOTAL_DUE', 'Extended Retail (before discount)'],
    },
    date: {
      key: 'date',
      required: false,
      label: 'Date',
      automatch_properties: ['INVOICE_DATE', 'Date'],
    },
    project_job_code: {
      key: 'project_job_code',
      required: false,
      label: 'Project Job Code',
      automatch_properties: ['PO_NUMBER', 'Job Name'],
    },
    ORDERED_WITH: {
      key: 'ORDERED_WITH',
      required: false,
      label: 'Ordered With',
      automatch_properties: ['ORDERED_WITH', 'Purchaser'],
    },
  }

  // custom import submission => calls baseFinalImportSubmission
  const customImportSubmission = (finalDataToImport: any, importHooks: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      baseFinalImportSubmission(finalDataToImport, importHooks)
        .then((res: any) => {
          if (res?.success && res.summary) {
            us_setImportSummary(res.summary)
            us_setImportSummaryDialogOpen(true)
          }
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  // The import button + dialog wrapper
  const ImportMaterialsWrapper = ({ shrink, selectedSupplier }: { shrink: boolean; selectedSupplier: any }): JSX.Element => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginRight: '10px' }}>
        <AdvancedImportButtonAndDialog
          importMappingOptions={allowedColumnsMapping}
          importSubmission={customImportSubmission}
          preImportChecks={performPreImportChecks}
          importButtonText="Import Materials (Advanced)"
          importButtonColor="info"
          importButtonShrink={shrink}
          importDialogHeader="Advanced Import for Materials"
          importButtonDisabled={!selectedSupplier.key}
          importAdditionalData={{
            uc_RootData_ClientKey,
            associated_supplier_key: selectedSupplier.key,
            associated_supplier_name: selectedSupplier.name,
          }}
        />
      </Box>
    )
  }

  // Supplier dropdown
  const rJSX_supplierDropdown = (): JSX.Element => {
    const suppliersArray = [
      { key: 'LXYEUvHezEaq82TeYUio', name: 'Crawford - Austin/San Antonio' },
      { key: 'home_depot', name: 'Home Depot' },
    ]

    const handleChange = (event: SelectChangeEvent<string>) => {
      const supplierKey = event.target.value
      const supplier = suppliersArray.find((s) => s.key === supplierKey)
      const newSupplier = { key: supplierKey, name: supplier ? supplier.name : '' }
      setSelectedSupplier(newSupplier)
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginRight: '10px' }}>
        <FormControl
          variant="outlined"
          size="small"
          sx={{ minWidth: 200 }}
        >
          <InputLabel>Select Supplier</InputLabel>
          <Select
            value={selectedSupplier.key}
            onChange={handleChange}
            input={<OutlinedInput label="Select Supplier" />}
            sx={{ minWidth: 200 }}
          >
            <MenuItem
              value=""
              disabled
            >
              -- Choose a Supplier --
            </MenuItem>
            {suppliersArray.map((supplier) => (
              <MenuItem
                key={supplier.key}
                value={supplier.key}
              >
                {supplier.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  // TABS
  const rJSX_PerformanceManagementTabs = (): JSX.Element => {
    let temp = <>Under Construction</>

    return temp
    return (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Record_Deduction',
            tabHeader: 'All Material Import Data',
            tabContent: rJSX_deductionValues(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },
          {
            tabUrlKey: 'Task_Clean_Up',
            tabHeader: (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <span>Task Clean Up</span>
                {taskCleanUpCount > 0 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    color="warning.main"
                  >
                    <Icon
                      icon="triangle-exclamation"
                      tooltip={`Records: ${taskCleanUpCount}`}
                    />
                    <span>{taskCleanUpCount}</span>
                  </Box>
                )}
              </Box>
            ),
            tabContent: rJSX_taskCleanUp(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },
          {
            tabUrlKey: 'Person_Clean_Up',
            tabHeader: (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <span>Person Clean Up</span>
                {personCleanUpCount > 0 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    color="warning.main"
                  >
                    <Icon
                      icon="triangle-exclamation"
                      tooltip={`Records: ${personCleanUpCount}`}
                    />
                    <span>{personCleanUpCount}</span>
                  </Box>
                )}
              </Box>
            ),
            tabContent: rJSX_PersonCleanUp(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.AdminDatabaseMaterialImportsIndexPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: 'Material Imports',
        }}
      />
    )
  }

  // Main page render
  const rJSX_Page = (): JSX.Element => {
    return (
      <Box>
        <AuthenticatedContainer
          pageHeader="Material Imports"
          pageKey={ApplicationPages.AdminDatabaseMaterialImportsIndexPage.key}
          content={
            <>
              {rJSX_PerformanceManagementTabs()}
              <EditTaskDialog />
              <EditOrderedWithUserDialog />
              <ImportSummaryDialog />
            </>
          }
        />
      </Box>
    )
  }

  return <>{rJSX_Page()}</>
}

export default Container
