///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getDocs, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Main
export const DatabaseRef_Projects_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main')
}

export const DatabaseRef_Project_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey)
}

// Project Subcollections
export const DatabaseRef_Project_Documents_Collection = (clientKey: string, projectKey: string, folderKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'documents', folderKey, 'files')
}

export const DatabaseRef_Project_AllDocuments_Collection = (clientKey: string, projectKey: string): Query<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'documents')
}

// Fetch folder keys
export const DatabaseRef_Project_FolderKeys_Query = (clientKey: string, projectKey: string): Promise<string[]> => {
  const foldersRef = collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'documents')

  // Use getDocs to retrieve all folder documents and their IDs
  return getDocs(foldersRef).then((querySnapshot) => {
    const folderKeys: string[] = []
    querySnapshot.forEach((doc) => {
      folderKeys.push(doc.id)
    })
    return folderKeys
  })
}

export const DatabaseRef_Project_FolderDocuments_Query = (clientKey: string, projectKey: string, folderKey: string): Promise<any[]> => {
  const filesRef = collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'documents', folderKey, 'files')

  // Return a Promise that resolves with an array of document data
  return getDocs(filesRef).then((querySnapshot) => {
    const docs: any[] = []
    querySnapshot.forEach((doc) => docs.push({ id: doc.id, ...doc.data() }))
    return docs
  })
}

export const DatabaseRef_Project_Documents_Document = (
  clientKey: string,
  projectKey: string,
  folderKey: string,
  fileKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'documents', folderKey, 'files', fileKey)
}

export const DatabaseRef_ProjectContactLogs_Collection = (clientKey: string, projectKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'contact_logs')
}

export const DatabaseRef_ProjectContactLogs_Document = (clientKey: string, projectKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'contact_logs', logKey)
}

export const DatabaseRef_ProjectLogs_Document = (clientKey: string, projectKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'logs', logKey)
}

export const DatabaseRef_ProjectNoteThreads_Collection = (clientKey: string, projectKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'note_threads')
}

export const DatabaseRef_ProjectNoteThreads_Document = (clientKey: string, projectKey: string, noteThreadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'note_threads', noteThreadKey)
}

export const DatabaseRef_ProjectNoteThreadNotes_Collection = (
  clientKey: string,
  projectKey: string,
  noteThreadKey: string,
): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'note_threads', noteThreadKey, 'notes')
}

export const DatabaseRef_ProjectNoteThreadNotes_Document = (
  clientKey: string,
  projectKey: string,
  noteThreadKey: string,
  noteKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'note_threads', noteThreadKey, 'notes', noteKey)
}

export const DatabaseRef_ProjectTaskWorkflow_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'project_data', 'task_workflow')
}

export const DatabaseRef_ProjectAdditionalData_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'project_data', 'additional_project_data')
}

export const DatabaseRef_ProjectRoleAssignments_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'project_data', 'role_assignments')
}

export const DatabaseRef_ProjectDesignPreferences_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'project_data', 'design_preferences')
}

export const DatabaseRef_ProjectTaskFormData_Collection = (clientKey: string, projectKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'task_form_data')
}

export const DatabaseRef_ProjectTaskFormData_Document = (clientKey: string, projectKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'task_form_data', taskKey)
}

export const DatabaseRef_ProjectAdditionalImages_Collection = (clientKey: string, projectKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'additional_images')
}

export const DatabaseRef_ProjectMLAggregateEvaluations_Collection = (clientKey: string, projectKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'ml_aggregate_evaluations')
}

export const DatabaseRef_ProjectAdditionalImages_Document = (clientKey: string, projectKey: string, imageKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'additional_images', imageKey)
}

export const DatabaseRef_ProjectMLAggregateEvaluations_Document = (
  clientKey: string,
  projectKey: string,
  taskPageFolderKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'ml_aggregate_evaluations', taskPageFolderKey)
}

export const DatabaseRef_ProjectMLImageEvaluations_Collection = (clientKey: string, projectKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'ml_image_evaluations')
}

export const DatabaseRef_ProjectMLImageEvaluations_Document = (clientKey: string, projectKey: string, imageKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'ml_image_evaluations', imageKey)
}

// Queries
export const DatabaseRef_ProjectCustomerEmail_Query = (clientKey: string, email: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_customer_email', comparator: '==', value: email }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ProjectMLImageEvaluationsForTask_Query = (clientKey: string, projectKey: string, taskKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_task_keys.' + taskKey, comparator: '==', value: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'ml_image_evaluations'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}

export const DatabaseRef_ProjectsByFICDate_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    // { prop: "timestamp_fic_date", comparator: ">=", value: startTimestamp },
    // { prop: "timestamp_fic_date", comparator: "<=", value: endTimestamp },
    { prop: 'timestamp_construction_complete', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_construction_complete', comparator: '<=', value: endTimestamp },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ProjectsByFICOverrideDate_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_fic_override_date', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_fic_override_date', comparator: '<=', value: endTimestamp },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ProjectsTemp_Query = // Used for doing database data movement
  (clientKey: string, startAt: string, limit: number): Query<unknown> => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let queryCursorsObject = { startAt: startAt }
    let orderByArray = [{ prop: 'key', desc: false }]
    return generateDatabaseQuery(
      collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

export const DatabaseRef_UnassignedProjects_AdminNav_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'unassigned' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, 1)
}

export const DatabaseRef_OpenProjects_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status_complete', comparator: '==', value: false }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_OpenSearchProjects_Query = (clientKey: string, searchProp: string, searchValue: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status_complete', comparator: '==', value: false },
    { prop: searchProp, comparator: '==', value: searchValue },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_RecentlyCompletedProjects_Query = (clientKey: string, cutoffDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status_complete', comparator: '==', value: true },
    { prop: 'timestamp_completed', comparator: '>=', value: cutoffDate },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ActiveProjects_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ActiveProjectsForSpecificWorkflow_Query = (clientKey: string, workflowKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_task_workflow_key', comparator: '==', value: workflowKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ActiveProjectsForSpecificWorkflowAndSalesPartner_Query = (
  clientKey: string,
  workflowKey: string,
  salesPartnerKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_task_workflow_key', comparator: '==', value: workflowKey },
    { prop: 'associated_sales_partner_key', comparator: '==', value: salesPartnerKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ActiveProjectsForSalesPartner_Query = (clientKey: string, salesPartnerKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_sales_partner_key', comparator: '==', value: salesPartnerKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_UnassignedProjects_Query = (clientKey: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'unassigned' }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'internal_id_number', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, limit)
}

export const DatabaseRef_ProjectsByIdNumber_Query = (clientKey: string, idNumber: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'id_number', comparator: '==', value: idNumber }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ProjectIdNumber_Query = (clientKey: string, idNumber: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'id_number', comparator: '==', value: idNumber }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

// Imports
export const DatabaseRef_ProjectImportResults_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  // return doc( getFirestore(), "clients", clientKey, "operations", "projects", "imports", "last_results" ) }
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'imports', 'last_results', 'projects', projectKey)
}

export const DatabaseRef_ProjectImportResults_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'imports', 'last_results', 'projects')
}

// Proto
export const DatabaseRef_ProtoProjects_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'proto')
}

export const DatabaseRef_ProtoProject_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'proto', projectKey)
}

export const DatabaseRef_ProtoProjectDesignPreferences_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'proto', projectKey, 'project_data', 'design_preferences')
}

export const DatabaseRef_ActiveProtoProjects_Query = (clientKey: string, limit: number | null): Query<unknown> => {
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'proto'),
    queryOperatorsArray,
    orderByArray,
    {},
    limit,
  )
}

export const DatabaseRef_ProtoProjects_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'proto'),
    queryOperatorsArray,
    orderByArray,
    {},
    limit,
  )
}

// Other
export const DatabaseRef_ProjectAggregateStats_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'aggregate_stats', 'current')
}

export const DatabaseRef_ProjectStats_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'stats', projectKey)
}

export const DatabaseRef_ProjectNextIdNumber_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'id_number', 'current')
}

export const DatabaseRef_ProjectTimestamps_Document = (clientKey: string, projectKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'timestamps', projectKey)
}

export const DatabaseRef_ActiveProjectTimestampsForSpecificWorkflow_Query = (clientKey: string, workflowKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_task_workflow_key', comparator: '==', value: workflowKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'timestamps'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_ActiveProjectTimestampsForSpecificWorkflowAndSalesPartner_Query = (
  clientKey: string,
  workflowKey: string,
  salesPartnerKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_task_workflow_key', comparator: '==', value: workflowKey },
    { prop: 'associated_sales_partner_key', comparator: '==', value: salesPartnerKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'timestamps'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_ProjectsForSpecificRegion_Query = (clientKey: string, regionKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_region_key', comparator: '==', value: regionKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main'), queryOperatorsArray, orderByArray, {}, null)
}
// Project Finance
export const DatabaseRef_ProjectFinances_Collection = (clientKey: string, projectKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'finance')
}

export const DatabaseRef_ProjectFinance_Document = (clientKey: string, projectKey: string, financeKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'main', projectKey, 'finance', financeKey)
}
