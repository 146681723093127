/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

import { Box, Button, Card, CardHeader, Chip, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'

import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_OrdersForSpecificProjectQuery,
  DatabaseRef_Orders_Collection,
  DatabaseRef_Order_Document,
} from 'rfbp_aux/services/database_endpoints/materials/orders'
import { DatabaseRef_MaterialUserPoNumber_Document } from 'rfbp_aux/services/database_endpoints/materials/po_number_counters'
import { DatabaseRef_ActiveSuppliers_Query, DatabaseRef_Supplier_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseTransactionIncrement,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

/*
DESCRIPTION / USAGE:
This component displays a table of material orders for a given project.

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_MaterialsTab {
  projectKey: string
  viewType: 'full' | 'sales'
  readOrWrite: 'read' | 'write'
}

///////////////////////////////
// Variables
///////////////////////////////

const tableSettings_SubkMaterialOrders: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  sortable: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: '',
}

const tableColumns_SubkMaterialOrders: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'new',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash" />,
              header: rLIB('Delete Order'),
              text: rLIB('Are you sure that you want to delete this order?'),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        let updateObject = {
                          status: 'deleted',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="magic-wand-sparkles"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'deleted',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'warning',
              icon: <Icon icon="magic-wand-sparkles" />,
              header: rLIB('Undelete Order'),
              text: rLIB('Are you sure that you want to undelete this order?'),
              submit_text: rLIB('Undelete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        let updateObject = {
                          status: 'new',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  po_number: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('PO Number')
      },
      header_sort_by: 'po_number',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        switch (rowData.status) {
          case 'new':
            chipColor = 'primary'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="square-pen"
                className="tw-mr-2"
              />
            )
            break
          case 'deleted':
            chipColor = 'error'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="trash"
                className="tw-mr-2"
              />
            )
            break
          case 'locked':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="lock"
                className="tw-mr-2"
              />
            )
            break
          case 'submitted':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="paper-plane"
                className="tw-mr-2"
              />
            )
            break
          case 'confirmed':
            chipColor = 'success'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="badge-check"
                className="tw-mr-2"
              />
            )
            break
          // case "picked_up":
          // iconJSX =
          // <Icon sx={{ fontSize: "16px" }}  icon="truck-ramp-box" className="tw-mr-2" />
          // break
          case 'invoiced':
            chipColor = 'info'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="message-dollar"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Chip
            label={
              <Box className="tw-align-top">
                {iconJSX}
                {rowData.po_number}
              </Box>
            }
            color={chipColor}
            variant="outlined"
            className="tw-cursor-pointer"
            sx={{ margin: '2px' }}
            onClick={(event) => {
              onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(rowData.key as string))
            }}
          />
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  timestamp_scheduled_pickup_date_key: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled Date')
      },
      header_sort_by: 'delivery_timestamp_scheduled_pickup_date_keytype',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            {getProp(rowData, 'timestamp_scheduled_pickup_date_key', <></>)}
            <Icon
              icon="pen-to-square"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: {
                        timestamp_scheduled_pickup_date_key: {
                          data_type: 'string',
                          input_type: 'timestamp_date',
                          key: 'timestamp_scheduled_pickup_date_key',
                          label: rLIB('Scheduled Date'),
                          required: true,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              // Add 'scheduled_status' based on whether a date is provided
                              const updatedData: any = {
                                timestamp_scheduled_pickup_date_key: formSubmittedData.timestamp_scheduled_pickup_date_key,
                                scheduled_status: formSubmittedData.timestamp_scheduled_pickup_date_key ? 'scheduled' : 'unscheduled',
                              }

                              DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updatedData)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: rLIB('Scheduled Date'),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
              className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Edit')}
              tooltipPlacement="right"
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  associated_supplier_name: TableCellBasic('associated_supplier_name', rLIB('Supplier'), 'associated_supplier_name'),
  associated_supplier_location_name: TableCellBasic('associated_supplier_location_name', rLIB('Supplier Location'), 'associated_supplier_location_name'),
  // delivery_type: TableCellBasic( "delivery_type", s_DELIVERY_TYPE, "delivery_type" ),
  delivery_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Delivery Type')
      },
      header_sort_by: 'delivery_type',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        let textJSX = <></>
        if (rowData.delivery_type == null) {
          rowData.delivery_type = 'pickup'
        }
        switch (rowData.delivery_type) {
          case 'pickup':
            chipColor = 'primary'
            textJSX = <>{rLIB('Pickup')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="person-dolly"
                className="tw-mr-2"
              />
            )
            break
          case 'delivery':
            chipColor = 'secondary'
            textJSX = <>{rLIB('Delivery')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="truck"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Chip
            label={
              <Box className="tw-align-top">
                {iconJSX}
                {textJSX}
              </Box>
            }
            color={chipColor}
            variant="outlined"
            sx={{ margin: '2px' }}
          />
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  // delivery_notes: TableCellBasic('delivery_notes', rLIB('Delivery Notes'), 'delivery_notes'),
  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
  associated_task_name: TableCellBasic('associated_task_name', rLIB('Task'), 'associated_task_name'),
  // associated_team_name: TableCellBasic('associated_team_name', rLIB('Team'), 'associated_team_name'),
  // associated_user_name: TableCellBasic('associated_user_name', rLIB('Requestor'), 'associated_user_name'),
  // // timestamp_scheduled_pickup_date_key: TableCellBasic( "timestamp_scheduled_pickup_date_key", s_REQUESTED_PICKUP_DATE, "timestamp_scheduled_pickup_date_key" ),
}

///////////////////////////////
// Container
///////////////////////////////

export const MaterialsTab = forwardRef((props: TsInterface_MaterialsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey = getProp(props, 'projectKey', null)
  let pr_viewType = getProp(props, 'viewType', 'full')
  let pr_readOrWrite = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  const [us_ordersForProject, us_setOrdersForProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_project, us_setProject] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_allActiveSuppliers, us_setAllActiveSuppliers] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect

  useEffect(() => {
    let unsubscribeLiveData: (() => void) | undefined
    const updateLiveData = (newData: any) => {
      us_setProject(newData)
      ur_forceRerender()
    }

    if (pr_projectKey) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((error) => {
          console.error(error)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
        })
    }

    return () => {
      if (unsubscribeLiveData) {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    console.log('===== useEffect triggered. pr_projectKey:', pr_projectKey)

    let unsubscribeLiveData: (() => void) | undefined

    const updateLiveData = (newData: any) => {
      console.log('===== updateLiveData fired, raw newData:', newData)
      console.log('===== newData converted to array:', objectToArray(newData))

      // Setting state and forcing a re-render
      us_setOrdersForProject(newData)
      ur_forceRerender()
    }

    if (pr_projectKey) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          console.log('===== getClientKey resolved =>', res_GCK)
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_OrdersForSpecificProjectQuery(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((error) => {
          console.error(error)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
        })
    }

    return () => {
      if (unsubscribeLiveData) {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (!us_project?.associated_region_key) {
        console.warn('Project region key not available. Clearing suppliers.')
        us_setAllActiveSuppliers({})
        return
      }

      // Filter suppliers based on the project's region key
      const filteredSuppliers = Object.fromEntries(
        Object.entries(newData).filter(([supplierKey, supplier]: [string, any]) => {
          const matchingLocations = Object.values(supplier.locations || {}).filter(
            (location: any) => location.associated_region_key === us_project.associated_region_key && location.status === 'active',
          )

          if (matchingLocations.length > 0) {
            return true
          } else {
            return false
          }
        }),
      )

      us_setAllActiveSuppliers(filteredSuppliers)
      ur_forceRerender()
    }

    if (us_project?.associated_region_key) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSuppliers_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error('Error fetching suppliers:', rej_GCK)
        })
    } else {
      console.warn('Project data does not contain an associated region key.')
    }

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_project])

  //Functions
  // Functions
  const createNewOrder = (): void => {
    // Define the form inputs for creating a new order
    let formInputs_NewOrder: TsInterface_FormInputs = {
      // Header Information
      header: {
        data_type: 'string',
        key: 'header',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: false,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          return (
            <Box className="tw-mb-4">
              <Typography
                variant="body1"
                sx={{ color: themeVariables.warning_main }}
                className="tw-mb-2"
              >
                <Icon
                  icon="circle-exclamation"
                  className="tw-mr-2"
                />
                {rLIB('Orders must be placed by 3pm the day before pickup.')}
              </Typography>
            </Box>
          )
        },
      },

      // Supplier Selection
      associated_supplier_key: {
        data_type: 'string',
        key: 'associated_supplier_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          const supplierOptions = objectToArray(getProp(formAdditionalData, 'associated_supplier_options', {})).sort(dynamicSort('name', 'asc'))

          return (
            <Box className="tw-mt-4">
              <Box className="tw-opacity-70">{rLIB('Supplier')}</Box>
              <FormControl>
                {supplierOptions.length > 0 ? (
                  <RadioGroup
                    onChange={(event) => {
                      formInputChange('associated_supplier_key', event.target.value, true)
                      formInputChange('associated_supplier_location_key', null, true)
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseGetDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, event.target.value))
                            .then((res_DGD) => {
                              let supplier = res_DGD.data
                              let supplierLocations: TsInterface_UnspecifiedObject = {}
                              for (let loopLocationKey in getProp(supplier, 'locations', {})) {
                                let loopLocation = supplier.locations[loopLocationKey]
                                if (loopLocation != null && loopLocation.status === 'active') {
                                  supplierLocations[loopLocationKey] = {
                                    key: loopLocationKey,
                                    value: loopLocation.name,
                                  }
                                }
                              }
                              formInputChange('associated_supplier_location_options', supplierLocations, true)
                            })
                            .catch((rej_DGD) => {
                              console.error('Error fetching supplier details:', rej_DGD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error('Error fetching client key:', rej_GCK)
                        })
                    }}
                    value={formData.associated_supplier_key || ''}
                  >
                    {supplierOptions.map((supplier, index) => (
                      <Box
                        className="tw-ml-8 tw-block"
                        key={index}
                      >
                        <FormControlLabel
                          value={supplier.key}
                          control={<Radio />}
                          label={supplier.value}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                ) : (
                  <Typography
                    variant="body2"
                    className="tw-text-red-500"
                  >
                    {rLIB('No suppliers available for the selected project region.')}
                  </Typography>
                )}
              </FormControl>
            </Box>
          )
        },
      },

      // Supplier Location Selection
      associated_supplier_location_key: {
        data_type: 'string',
        key: 'associated_supplier_location_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          const locationOptions = objectToArray(getProp(formData, 'associated_supplier_location_options', {})).sort(dynamicSort('name', 'asc'))

          return (
            <Box className="tw-mt-4">
              <Box className="tw-opacity-70">{rLIB('Supplier Location')}</Box>
              <FormControl>
                {locationOptions.length > 0 ? (
                  <RadioGroup
                    onChange={(event) => {
                      formInputChange('associated_supplier_location_key', event.target.value, true)
                    }}
                    value={formData.associated_supplier_location_key || ''}
                  >
                    {locationOptions.map((location, index) => (
                      <Box
                        className="tw-ml-8 tw-block"
                        key={index}
                      >
                        <FormControlLabel
                          value={location.key}
                          control={<Radio />}
                          label={location.value}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                ) : (
                  <Typography
                    variant="body2"
                    className="tw-text-red-500"
                  ></Typography>
                )}
              </FormControl>
            </Box>
          )
        },
      },

      // Delivery Type Selection
      delivery_type: {
        key: 'delivery_type',
        label: rLIB('Delivery Type'),
        input_type: 'multiple_choice_radio',
        required: true,
        data_type: 'string',
        options: [
          { key: 'pickup', value: rLIB('Pickup') },
          { key: 'delivery', value: rLIB('Delivery') },
        ],
      },

      // Delivery Notes
      delivery_notes: {
        key: 'delivery_notes',
        label: rLIB('Delivery Notes'),
        input_type: 'text_multiline',
        required: false,
        data_type: 'string',
        conditional_display: {
          active: true,
          logic: {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'delivery_type',
            comparator: '==',
            value: 'delivery',
            conditions: [],
          },
        },
      },

      // Task Selection
      associated_task_key: {
        data_type: 'string',
        key: 'associated_task_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          // Filter active tasks
          const activeTasks = Object.keys(us_projectTasks || {}).filter((taskKey) => us_projectTasks[taskKey]?.status === 'active')

          return (
            <Box className="tw-mt-4">
              <Typography
                variant="body1"
                sx={{ color: themeVariables.primary_main, display: 'flex', alignItems: 'center' }}
                className="tw-mb-2"
              >
                {rLIB('Select Task')}
                <Tooltip
                  placement="left"
                  title={rLIB(
                    'Which task is this material order for? Selecting a task allows for cost allocation, integrity checks and reminders to reduce errors on-site.',
                  )}
                >
                  <Box
                    className="tw-inline-block tw-ml-1"
                    sx={{ color: 'white', cursor: 'pointer' }}
                  >
                    <Icon icon="info-circle" />
                  </Box>
                </Tooltip>
              </Typography>
              <FormControl fullWidth>
                {activeTasks.length > 0 ? (
                  <Select
                    className="tw-mb-2"
                    value={formData.associated_task_key || ''}
                    onChange={(event) => {
                      // Explicitly cast event.target.value to string | number
                      const selectedTaskKey = event.target.value as string | number
                      formInputChange('associated_task_key', selectedTaskKey, true)

                      // Get the task name from us_projectTasks and set it in formAdditionalData
                      const taskName = us_projectTasks[selectedTaskKey]?.name || ''
                      formInputChange('associated_task_name', taskName, true)
                    }}
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      {rLIB('Select Task')}
                    </MenuItem>
                    {activeTasks.map((taskKey) => {
                      const task = us_projectTasks[taskKey]
                      return (
                        <MenuItem
                          key={taskKey}
                          value={taskKey}
                        >
                          {task.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                ) : (
                  <Typography
                    variant="body2"
                    className="tw-text-red-500"
                  >
                    {rLIB('No active tasks available for this project.')}
                  </Typography>
                )}
              </FormControl>
            </Box>
          )
        },
      },
    }

    // Set up supplier options
    let supplierOptions: TsInterface_UnspecifiedObject = {}
    for (let loopSupplierKey in us_allActiveSuppliers) {
      let loopSupplier = us_allActiveSuppliers[loopSupplierKey]
      if (loopSupplier != null) {
        supplierOptions[loopSupplierKey] = { key: loopSupplierKey, value: loopSupplier.name }
      }
    }

    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: { associated_supplier_options: supplierOptions },
          formData: {},
          formInputs: formInputs_NewOrder,
          formOnChange: () => {},
          formSettings: {},
          formSubmission: (formSubmittedData) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  createNewOrderProper(
                    res_GCK.clientKey,
                    formSubmittedData.associated_task_key, // Pass the selected task key
                    formSubmittedData.associated_supplier_key,
                    formSubmittedData.associated_supplier_location_key,
                    formSubmittedData.timestamp_scheduled_pickup,
                    formSubmittedData.delivery_type,
                    formSubmittedData.delivery_notes,
                  )
                    .then(resolve)
                    .catch(reject)
                })
                .catch(reject)
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Order'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const createNewOrderProper = (
    clientKey: string,
    taskKey: string,
    supplierKey: string,
    supplierLocationKey: string,
    requestedPickupDateKey: string,
    deliveryType: string,
    deliveryNotes: string | null,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // Validate required parameters
      if (clientKey != null && taskKey != null && supplierKey != null) {
        let promiseArray: TsType_UnknownPromise[] = []
        let task: any = {}
        let supplier: any = {}

        // Fetch Task Details
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_Task_Document(clientKey, taskKey))
            .then((res_GGD) => {
              task = res_GGD.data
            })
            .catch((rej_GGD) => {
              console.error('Error fetching task:', rej_GGD)
              throw rej_GGD
            }),
        )

        // Fetch Supplier Details
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_Supplier_Document(clientKey, supplierKey))
            .then((res_GGD) => {
              supplier = res_GGD.data
            })
            .catch((rej_GGD) => {
              console.error('Error fetching supplier:', rej_GGD)
              throw rej_GGD
            }),
        )

        // Once both Task and Supplier data are fetched
        Promise.all(promiseArray)
          .then(async () => {
            // Ensure supplier has PO abbreviation and task exists
            if (supplier != null && supplier['po_abbreviation'] != null && task != null) {
              // Generate PO Number
              const projectId = getProp(task, 'associated_project_id_number', null)
              if (!projectId) {
                console.error('Project ID is missing, cannot create PO number.')
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to create new order'),
                    details: rLIB('Missing Project ID'),
                    code: 'ER-D-MI-NOPROJECTID',
                  },
                })
                return
              }

              const basePONumber = `${supplier['po_abbreviation']}-${projectId}-`

              // Increment PO Number Counter
              DatabaseTransactionIncrement(DatabaseRef_MaterialUserPoNumber_Document(clientKey, projectId), 'counter', 1)
                .then((res_DTI: any) => {
                  // Create Full PO Number
                  let incrementNumber = res_DTI.incrementValue
                  let numStr = incrementNumber.toString().padStart(3, '0')
                  let fullPONumber = basePONumber + numStr

                  // Determine Scheduled Status
                  // const scheduledStatus = requestedPickupDateKey ? 'scheduled' : 'unscheduled'

                  // Construct the Order Object
                  let updateObject = {
                    associated_project_id_number: projectId,
                    associated_project_key: getProp(task, 'associated_project_key', null),
                    associated_supplier_key: supplierKey,
                    associated_supplier_name: getProp(supplier, 'name', null),
                    associated_supplier_location_key: supplierLocationKey,
                    associated_supplier_location_name: getProp(getProp(getProp(supplier, 'locations', {}), supplierLocationKey, {}), 'name', null),
                    associated_task_key: taskKey,
                    associated_task_name: getProp(task, 'name', null),
                    delivery_type: deliveryType,
                    delivery_notes: deliveryNotes || null,
                    email_sent: false,
                    po_number: fullPONumber,
                    status: 'new',
                    timestamp_created: new Date(),
                    // Conditionally include scheduled pickup date key or status
                    ...(requestedPickupDateKey ? { timestamp_scheduled_pickup_date_key: requestedPickupDateKey } : { scheduled_status: 'unscheduled' }),
                  }

                  // Save the Order to Firestore
                  DatabaseAddDocument(DatabaseRef_Orders_Collection(clientKey), updateObject, true)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      console.error('Error saving order:', rej_DAD)
                      reject(rej_DAD)
                    })
                })
                .catch((rej_DTI) => {
                  console.error('Error incrementing PO number:', rej_DTI)
                  reject(rej_DTI)
                })
            } else {
              console.error('Missing required parameters for order creation.')
              reject({
                success: false,
                error: {
                  message: rLIB('Failed to create new order'),
                  details: rLIB('Missing Required Parameters'),
                  code: 'ER-D-MI-CNOP-01',
                },
              })
            }
          })
          .catch((error) => {
            console.error('Error in Promise.all:', error)
            reject({
              success: false,
              error: {
                message: rLIB('Failed to create new order'),
                details: rLIB('Error fetching required data'),
                code: 'ER-D-MI-CNOP-03',
              },
            })
          })
      } else {
        console.error('Invalid input parameters for createNewOrderProper.')
        reject({
          success: false,
          error: {
            message: rLIB('Failed to create new order'),
            details: rLIB('Missing Required Parameters'),
            code: 'ER-D-MI-CNOP-02',
          },
        })
      }
    })
  }

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    return (
      <Card>
        <CardHeader
          title={
            <Button
              variant="contained"
              color="success"
              startIcon={<Icon icon="circle-plus" />}
              onClick={() => {
                createNewOrder()
              }}
            >
              {rLIB('Create New Order')}
            </Button>
          }
        />
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_SubkMaterialOrders}
          tableData={objectToArray(us_ordersForProject)}
          tableSettings={tableSettings_SubkMaterialOrders}
        />
      </Card>
    )
  }

  // Render
  return <>{rJSX_Tab()}</>
})
