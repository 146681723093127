/* eslint-disable react/prop-types */
//////////////////////////////////////////
//            ADVANCED IMPORT           //
//////////////////////////////////////////

import { AppBar, Box, Button, Card, Dialog, DialogContent, Divider, IconButton, MenuItem, Select, Toolbar, Typography } from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_PromptDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import * as XLSX from 'xlsx'
import { Icon } from '../icons/icon'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableDataRow, TsInterface_TableSettings } from '../table'

// Firestore imports
import { doc, getDoc, getDocs, getFirestore } from 'firebase/firestore'
import { DatabaseRef_ActiveUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'

// Task and project queries
import { DatabaseRef_ProjectsByIdNumber_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'

///////////////////////////////
// Types
///////////////////////////////

export interface TsInterface_AdvancedImportHooksObject {
  uc_RootData_ClientKey: any
  uc_RootData_ClientPermissions: any
  uc_RootData_ClientUser: any
  uc_RootData_GlobalUser: any
  uc_setRootData_ClientKey: any
  uc_setUserInterface_AlertDialogDisplay: any
  uc_setUserInterface_ConfirmDialogDisplay: any
  uc_setUserInterface_CustomDialogDisplay: any
  uc_setUserInterface_ErrorDialogDisplay: any
  uc_setUserInterface_FormDialogDisplay: any
  uc_setUserInterface_LoadingBarDisplay: any
  uc_setUserInterface_PromptDialogDisplay: any
  un_routerNavigation: any
  ur_forceRerender: any

  // Optional
  localClientKey?: any
  importAdditionalData?: TsInterface_UnspecifiedObject
}

export type TsType_PreImportChecks = (
  mappedData: TsInterface_UnspecifiedObject,
  rawData: TsInterface_UnspecifiedObject,
  importHooks: TsInterface_AdvancedImportHooksObject,
) => Promise<{
  success: boolean
  processedData: Array<{ docId: string; row: any }>
  summary: TsInterface_UnspecifiedObject
}>

export type TsType_ImportSubmission = (
  finalDataToImport: Array<{ docId: string; row: any }>,
  importHooks: TsInterface_AdvancedImportHooksObject,
) => TsType_UnknownPromise

export interface TsInterface_AdvancedImportButtonAndDialog {
  importMappingOptions: {
    [key: string]: {
      automatch_properties: string[]
      key: string
      label: string | JSX.Element
      required: boolean
      possible_values?: string[]
    }
  }
  importSubmission: TsType_ImportSubmission
  preImportChecks: TsType_PreImportChecks

  importButtonText: string | JSX.Element
  importButtonColor: TsType_MuiComponentColors
  importButtonDisabled?: boolean
  importButtonShrink: boolean
  importDialogHeader: string | JSX.Element

  importAdditionalData?: TsInterface_UnspecifiedObject
}

interface TsInterface_AdvancedImportDialog {
  importMappingOptions: TsInterface_AdvancedImportButtonAndDialog['importMappingOptions']
  importDialogHeader: JSX.Element
  importHooks: TsInterface_AdvancedImportHooksObject
  importRawData: TsInterface_UnspecifiedObject
  importRawHeaders: string[]
  importSubmission: TsType_ImportSubmission
  preImportChecks: TsType_PreImportChecks
  importAdditionalData: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Table Settings
///////////////////////////////
const tableSettings_ImportPreview: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 10,
  pagination_rows_per_page_options: [10, 25, 50, 10],
  show_header: true,
  size: 'small',
  sortable: false,
}

///////////////////////////////
// HELPER: parse date
///////////////////////////////
const parseDateString = (dateString: string): Date | null =>
  !dateString
    ? null
    : (() => {
        const d = new Date(dateString)
        return isNaN(d.getTime()) ? null : d
      })()

// Helper: Convert Excel serial date to string (YYYY/MM/DD)
const convertExcelDate = (excelDateValue: any): string => {
  let serialNumber: number
  if (typeof excelDateValue === 'number') {
    serialNumber = excelDateValue
  } else {
    serialNumber = parseFloat(excelDateValue)
  }
  if (isNaN(serialNumber)) {
    return String(excelDateValue)
  }
  const jsDate = new Date((serialNumber - 25569) * 86400 * 1000)
  const year = jsDate.getFullYear()
  const month = String(jsDate.getMonth() + 1).padStart(2, '0')
  const day = String(jsDate.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}

/**
 * Build doc ID => remove spaces, remove slashes.
 * Uses sku_number if available; falls back to sku_description.
 */
export const buildUniqueKey = (rowData: any): string => {
  // Updated cleanup: replace all spaces with underscores and all slashes with underscores.
  const cleanup = (value: any): string => {
    return value != null ? String(value).replace(/\s+/g, '_').replace(/\//g, '_') : ''
  }
  const supp = cleanup(rowData.associated_supplier_name).toUpperCase().slice(0, 3)
  const inv = cleanup(rowData.invoice_reference_number).toUpperCase()
  const job = cleanup(rowData.project_job_code)
  // Use sku_number if available; otherwise, fallback to sku_description; then cleanup.
  const skuNumber = typeof rowData.sku_number === 'string' ? cleanup(rowData.sku_number) : ''
  const skuDescription = typeof rowData.sku_description === 'string' ? cleanup(rowData.sku_description) : ''
  const sku = skuNumber !== '' ? skuNumber : skuDescription !== '' ? skuDescription : 'N/A'
  const last4 = job.slice(-4)
  const combined = `${supp}_${inv}_${last4}_${sku}`
  return combined || 'fallback_missing_key'
}
/**
 * Helper: Query active users.
 */
const queryAllUsers = async (clientKey: string): Promise<any[]> => {
  try {
    const q = DatabaseRef_ActiveUsers_Query(clientKey)
    const result = await DatabaseGetCollection(q)
    if (result.success && result.data) {
      const users = Object.entries(result.data).map(([docId, data]) => ({
        key: docId,
        ...data,
      }))

      // Filter to only include users with associated_organization_type === "internal"
      const internalUsers = objectToArray(users).filter((user) => user.associated_organization_type === 'internal')

      // Sort the filtered users alphabetically by the 'name' property.
      objectToArray(internalUsers).sort((a, b) => a.name.localeCompare(b.name))

      return internalUsers
    }
  } catch (err) {
    console.error('Error in queryAllUsers:', err)
  }
  return []
}

/**
 * Helper: Calculate a simple match score.
 */
const simpleMatchScore = (str1: string, str2: string): number => {
  if (!str1 || !str2) return 0
  const s1 = str1.toLowerCase().replace(/\s+/g, '')
  const s2 = str2.toLowerCase().replace(/\s+/g, '')
  let score = 0
  const minLen = Math.min(s1.length, s2.length)
  for (let i = 0; i < minLen; i++) {
    if (s1[i] === s2[i]) score++
  }
  return score
}

/**
 * Helper: Find the closest scheduled date in a task.
 */
function findClosestScheduledDate(task: any, targetDate: Date): string | null {
  if (!Array.isArray(task.task_completion_scheduled_dates)) return null
  let minDiff = Number.MAX_SAFE_INTEGER
  let chosenDate: string | null = null
  for (const scheduled of task.task_completion_scheduled_dates) {
    const scheduledDate = parseDateString(scheduled)
    if (!scheduledDate) continue
    const diff = Math.abs(scheduledDate.getTime() - targetDate.getTime())
    if (diff < minDiff) {
      minDiff = diff
      chosenDate = scheduled
    }
  }
  return chosenDate
}

/**
 * Updated performPreImportChecks.
 * Combines rows with identical invoice, project (capitalized), and SKU,
 * sums quantities, recalculates total_price, and integrates task/team logic.
 */
export const performPreImportChecks: TsType_PreImportChecks = async (
  mappedData: { [rowKey: string]: any },
  rawData: { [rowKey: string]: any },
  importHooks: TsInterface_AdvancedImportHooksObject,
) => {
  const finalRows: Array<{ docId: string; row: any }> = []
  let skippedQtyZero = 0,
    skippedNoProject = 0,
    skippedNoTask = 0,
    tasksFound = 0,
    teamsFound = 0,
    duplicatesFound = 0

  const clientKey = importHooks.localClientKey || importHooks.uc_RootData_ClientKey
  if (!clientKey) {
    return { success: false, processedData: [], summary: { error: 'No clientKey found' } }
  }

  const allUsers = await queryAllUsers(clientKey)
  const allRowKeys = Object.keys(mappedData)

  // Group rows by composite key (invoice, project, SKU)
  const combinedRows: { [compositeKey: string]: any } = {}

  // Local cleanup function: replaces spaces and slashes with underscores.
  const cleanup = (value: any): string => {
    return value != null ? String(value).replace(/\s+/g, '_').replace(/\//g, '_') : ''
  }

  for (const rowKey of allRowKeys) {
    const rowData = mappedData[rowKey] || {}
    const rawRowData = rawData[rowKey] || {}

    // 1) Skip rows with invalid or zero quantity.
    const qty = parseFloat(rowData.quantity)
    if (!rowData.quantity || isNaN(qty) || qty <= 0) {
      skippedQtyZero++
      continue
    }

    // 2) Determine candidate project ID.
    let candidateProjId = ''
    const supplierKey = importHooks.importAdditionalData?.associated_supplier_key || ''
    if (supplierKey === 'LXYEUvHezEaq82TeYUio') {
      // For Crawford: try raw RELEASE_NUMBER then PO_NUMBER.
      if (typeof rawRowData.RELEASE_NUMBER === 'string' && rawRowData.RELEASE_NUMBER.trim() !== '') {
        candidateProjId = rawRowData.RELEASE_NUMBER.trim()
      }
      if (!candidateProjId && typeof rawRowData.PO_NUMBER === 'string' && rawRowData.PO_NUMBER.trim() !== '') {
        candidateProjId = rawRowData.PO_NUMBER.trim()
      }
    } else {
      // For other suppliers: use mapped project_job_code.
      if (typeof rowData.project_job_code === 'string' && rowData.project_job_code.trim() !== '') {
        candidateProjId = rowData.project_job_code.trim()
      }
    }
    // Capitalize the project ID.
    candidateProjId = candidateProjId.toUpperCase()
    rowData.project_job_code = candidateProjId
    if (!candidateProjId) {
      skippedNoProject++
      continue
    }

    // 3) Determine target date from the mapped 'date' field.
    // Instead of skipping if missing or unparseable, we’ll mark the task as missing later.
    let targetDate: Date | null = null
    if (rowData.date) {
      const dateRaw = String(rowData.date).trim()
      if (dateRaw.includes('/')) {
        targetDate = parseDateString(dateRaw)
      } else {
        const serial = parseFloat(dateRaw)
        if (!isNaN(serial)) {
          targetDate = new Date((serial - 25569) * 86400 * 1000)
        }
      }
    }

    // Prepare variables for task lookup.
    let foundTaskKey: string | null = null
    let foundTaskDoc: any = null

    if (!targetDate) {
      // If the date is missing or unparseable, mark task as missing.
      skippedNoTask++
      foundTaskKey = 'missing'
      foundTaskDoc = { name: 'missing', associated_team_key: 'missing', associated_team_name: 'missing' }
    } else {
      // 4) Project lookup.
      try {
        const projSnap = await getDocs(DatabaseRef_ProjectsByIdNumber_Query(clientKey, candidateProjId))
        if (!projSnap.empty) {
          const doc = projSnap.docs[0]
          rowData.associated_project_key = doc.id
        } else {
          skippedNoProject++
          // If no project is found, we skip the row.
          continue
        }
      } catch (err) {
        console.error(`Row ${rowKey}: Error querying project document:`, err)
        skippedNoProject++
        continue
      }

      // 5) Task & Team lookup logic.
      try {
        const tasksSnap = await getDocs(DatabaseRef_AllProjectTasks_Query(clientKey, rowData.associated_project_key))
        if (tasksSnap.empty) {
          // Instead of skipping, mark as missing.
          skippedNoTask++
          foundTaskKey = 'missing'
          foundTaskDoc = { name: 'missing', associated_team_key: 'missing', associated_team_name: 'missing' }
        } else {
          let smallestDiff = Number.MAX_SAFE_INTEGER
          let ambiguous = false
          for (const doc of tasksSnap.docs) {
            const tdata = doc.data()
            const maybeClosest = findClosestScheduledDate(tdata, targetDate)
            if (!maybeClosest) continue
            const scheduledDate = parseDateString(maybeClosest)
            if (!scheduledDate) continue
            if (scheduledDate.getTime() < targetDate.getTime()) continue
            const diff = scheduledDate.getTime() - targetDate.getTime()
            if (diff < smallestDiff) {
              smallestDiff = diff
              foundTaskKey = doc.id
              foundTaskDoc = tdata
              ambiguous = false
            } else if (diff === smallestDiff) {
              ambiguous = true
            }
          }
          if (ambiguous || !foundTaskKey) {
            foundTaskKey = 'missing'
            foundTaskDoc = { name: 'missing', associated_team_key: 'missing', associated_team_name: 'missing' }
            skippedNoTask++
          } else {
            tasksFound++
            teamsFound++
          }
        }
      } catch (err) {
        console.error(`Row ${rowKey}: Error querying tasks:`, err)
        skippedNoTask++
        foundTaskKey = 'missing'
        foundTaskDoc = { name: 'missing', associated_team_key: 'missing', associated_team_name: 'missing' }
      }
    }

    // Update the rowData with the task and team information.
    rowData.associated_task_key = foundTaskKey
    rowData.associated_task_name = foundTaskDoc?.name || ''
    rowData.associated_team_key = foundTaskDoc?.associated_team_key || ''
    rowData.associated_team_name = foundTaskDoc?.associated_team_name || ''

    // 4b) ORDERED_WITH logic.
    // 4b) ORDERED_WITH logic.
    if (rowData.ORDERED_WITH && typeof rowData.ORDERED_WITH === 'string') {
      const rawOrdered = rowData.ORDERED_WITH.trim()

      // Check for an exact match (ignoring case).
      const exactUser = allUsers.find((usr) => (usr.name || usr.displayName || '').trim().toLowerCase() === rawOrdered.toLowerCase())

      if (exactUser) {
        // If an exact match is found, assign it directly.
        rowData.ordered_with_user_key = exactUser.key
        rowData.ordered_with_user_name = exactUser.name
      } else {
        // No exact match found: use fuzzy matching for a suggested match.
        let best = null,
          bestScore = -1,
          tie = false
        for (const usr of allUsers) {
          const score = simpleMatchScore(rawOrdered, usr.name || usr.displayName || '')
          if (score > bestScore) {
            best = usr
            bestScore = score
            tie = false
          } else if (score === bestScore) {
            tie = true
          }
        }
        if (best && !tie && bestScore > 0) {
          rowData.suggested_user_key = best.key
          rowData.suggested_user_name = best.name
        } else {
          rowData.suggested_user_key = ''
          rowData.suggested_user_name = ''
        }
        // Since no exact match was found, mark ordered_with as missing.
        rowData.ordered_with_user_key = 'missing'
        rowData.ordered_with_user_name = 'missing'
      }
    }

    // 4c) Optionally convert the mapped date (Excel conversion if needed).
    rowData.date = rowData.date ? convertExcelDate(rowData.date) : ''

    // 5) Create a composite key using the invoice, candidateProjId, and SKU.
    const inv = cleanup(rowData.invoice_reference_number).toUpperCase()
    const candidateProjIdKey = cleanup(candidateProjId).toUpperCase()
    const skuNumber = typeof rowData.sku_number === 'string' ? cleanup(rowData.sku_number) : ''
    const skuDescription = typeof rowData.sku_description === 'string' ? cleanup(rowData.sku_description) : ''
    const sku = skuNumber !== '' ? skuNumber.toUpperCase() : skuDescription !== '' ? skuDescription.toUpperCase() : 'N/A'
    const compositeKey = `${inv}_${candidateProjIdKey}_${sku}`

    console.log(`Row ${rowKey} composite key: ${compositeKey}`)

    // Combine rows with the same composite key.
    if (combinedRows[compositeKey]) {
      const existingRow = combinedRows[compositeKey]
      const currentQty = parseFloat(rowData.quantity) || 0
      const existingQty = parseFloat(existingRow.quantity) || 0
      const newQuantity = existingQty + currentQty
      existingRow.quantity = newQuantity.toString()
      // Only recalc total_price when combining rows.
      const unitPrice = parseFloat(rowData.unit_price)
      if (!isNaN(unitPrice)) {
        existingRow.total_price = unitPrice * newQuantity
      }
    } else {
      // For a new row, leave total_price as is if it's already mapped.
      if (!rowData.total_price) {
        const unitPrice = parseFloat(rowData.unit_price)
        if (!isNaN(unitPrice)) {
          rowData.total_price = unitPrice * (parseFloat(rowData.quantity) || 0)
        }
      }
      combinedRows[compositeKey] = rowData
    }
  }

  // 6) Duplicate check: For each combined row, build a document key and check Firestore.
  for (const compositeKey in combinedRows) {
    const rowData = combinedRows[compositeKey]
    const docId = buildUniqueKey(rowData)
    try {
      const docRef = doc(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main', docId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        duplicatesFound++
        continue
      } else {
        finalRows.push({ docId: `preCheck_${docId}`, row: rowData })
      }
    } catch (err) {
      console.error('Error checking doc existence => skipping duplication check', err)
      finalRows.push({ docId: `preCheck_${docId}`, row: rowData })
    }
  }

  const summary = {
    totalRows: Object.keys(mappedData).length,
    finalRows: finalRows.length,
    skippedQtyZero,
    skippedNoProject,
    skippedNoTask,
    tasksFound,
    teamsFound,
    duplicatesFound,
  }

  return { success: true, processedData: finalRows, summary }
}

///////////////////////////////
// AdvancedImportDialog Component
///////////////////////////////
const AdvancedImportDialog: React.FC<TsInterface_AdvancedImportDialog> = ({
  importMappingOptions,
  importDialogHeader,
  importHooks,
  importRawData,
  importRawHeaders,
  importSubmission,
  preImportChecks,
  importAdditionalData,
}): JSX.Element => {
  const { uc_RootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  const [us_importMapping, us_setImportMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_invertedMapping, us_setInvertedMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mappedData, us_setMappedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableColumns, us_setTableColumns] = useState<TsInterface_TableColumns>({})
  const [us_automappingAttemptComplete, us_setAutomappingAttemptComplete] = useState<boolean>(false)

  const [us_hasRunPreImportChecks, us_setHasRunPreImportChecks] = useState<boolean>(false)
  const [us_preImportInProgress, us_setPreImportInProgress] = useState<boolean>(false)
  const [us_preImportSummary, us_setPreImportSummary] = useState<TsInterface_UnspecifiedObject>({})
  const [us_preImportProcessedData, us_setPreImportProcessedData] = useState<Array<{ docId: string; row: any }>>([])
  const [us_isImporting, us_setIsImporting] = useState<boolean>(false)

  // 1) Attempt autoMapping
  useEffect(() => {
    if (!us_automappingAttemptComplete) {
      const initialMapping: TsInterface_UnspecifiedObject = {}
      for (const rawHeader of importRawHeaders) {
        for (const fieldKey in importMappingOptions) {
          if (importMappingOptions[fieldKey].automatch_properties.includes(rawHeader)) {
            initialMapping[rawHeader] = fieldKey
            break
          }
        }
      }
      us_setImportMapping(initialMapping)
      us_setAutomappingAttemptComplete(true)
      ur_forceRerender()
    }
  }, [importMappingOptions, importRawHeaders, us_automappingAttemptComplete, ur_forceRerender])

  // 2) Invert mapping
  useEffect(() => {
    const inverted: TsInterface_UnspecifiedObject = {}
    for (const rawHeader in us_importMapping) {
      if (us_importMapping[rawHeader]) {
        inverted[us_importMapping[rawHeader]] = rawHeader
      }
    }
    us_setInvertedMapping(inverted)
  }, [us_importMapping])

  // 3) Build mapped data
  useEffect(() => {
    const newMapped: TsInterface_UnspecifiedObject = {}
    for (const rowKey in importRawData) {
      const row = importRawData[rowKey]
      const mappedRow: TsInterface_UnspecifiedObject = {}
      for (const rawHeader in us_importMapping) {
        const mappedField = us_importMapping[rawHeader]
        if (mappedField) {
          mappedRow[mappedField] = row[rawHeader]
        }
      }
      newMapped[rowKey] = mappedRow
    }
    us_setMappedData(newMapped)
  }, [importRawData, us_importMapping])

  const renderMappingSummary = () => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5, mb: 2 }}>
        {Object.keys(importMappingOptions).map((fieldKey) => {
          const field = importMappingOptions[fieldKey]
          const isMapped = Boolean(us_invertedMapping[fieldKey])
          return (
            <Box
              key={fieldKey}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                p: 1,
                borderRadius: '16px',
                backgroundColor: isMapped ? 'rgba(76,175,80,0.1)' : 'rgba(244,67,54,0.1)',
                border: `1px solid ${isMapped ? 'green' : 'red'}`,
              }}
            >
              <Icon
                icon={isMapped ? 'check' : 'xmark'}
                sx={{ color: isMapped ? 'green' : 'red', fontSize: 16 }}
              />
              <Typography variant="body2">{field.label}</Typography>
            </Box>
          )
        })}
      </Box>
    )
  }

  // 4) Build table columns with sorted headers and dynamic opacity.
  useEffect(() => {
    const sortedHeaders = [...importRawHeaders].sort((a, b) => {
      const aMapped = us_importMapping[a]
      const bMapped = us_importMapping[b]
      if (aMapped && !bMapped) return -1
      if (!aMapped && bMapped) return 1
      return 0
    })
    const newCols: TsInterface_TableColumns = {}
    sortedHeaders.forEach((rawHeader) => {
      newCols[rawHeader] = {
        header: {
          header_jsx: () => (
            <Box>
              <Box>
                <Select
                  className="bp_thin_select_input"
                  color="primary"
                  value={us_importMapping[rawHeader] || ''}
                  onChange={(event: any) => {
                    const newVal = event.target.value
                    us_setImportMapping((prev) => ({
                      ...prev,
                      [rawHeader]: newVal === '' ? null : newVal,
                    }))
                  }}
                  variant="outlined"
                >
                  <MenuItem
                    value=""
                    sx={{ color: themeVariables.error_light }}
                  >
                    <em>Remove Mapping</em>
                  </MenuItem>
                  {Object.keys(importMappingOptions).map((fieldKey) => {
                    const field = importMappingOptions[fieldKey]
                    const isDisabled = us_invertedMapping[fieldKey] && us_invertedMapping[fieldKey] !== rawHeader
                    return (
                      <MenuItem
                        key={fieldKey}
                        value={fieldKey}
                        disabled={isDisabled}
                      >
                        {field.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
              <Divider className="tw-my-2" />
              <Box sx={{ fontWeight: 'bold', opacity: us_importMapping[rawHeader] ? 1 : 0.5 }}>{rawHeader}</Box>
            </Box>
          ),
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow) => <Box sx={{ opacity: us_importMapping[rawHeader] ? 1 : 0.5 }}>{rowData[rawHeader]}</Box>,
        },
      }
    })
    us_setTableColumns(newCols)
  }, [importMappingOptions, importRawHeaders, us_importMapping, us_invertedMapping])

  function allRequiredFieldsMapped(): boolean {
    for (const fieldKey in importMappingOptions) {
      if (importMappingOptions[fieldKey].required) {
        if (!us_invertedMapping[fieldKey]) return false
      }
    }
    return true
  }

  /**
   * Pre-Import Handler.
   */
  const handlePreImportChecks = () => {
    us_setPreImportInProgress(true)
    const newHooks = { ...importHooks, localClientKey: uc_RootData_ClientKey }
    preImportChecks(us_mappedData, importRawData, newHooks)
      .then((res) => {
        us_setPreImportInProgress(false)
        if (res.success) {
          us_setPreImportProcessedData(res.processedData)
          us_setPreImportSummary(res.summary)
          us_setHasRunPreImportChecks(true)
        } else {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              code: 'PRE-IMPORT-CHECKS',
              message: 'Pre-Import Checks failed',
              details: JSON.stringify(res, null, 2),
            },
          })
        }
      })
      .catch((err) => {
        us_setPreImportInProgress(false)
        console.error('PreImportChecks error:', err)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'PRE-IMPORT-CHECKS-EXCEPTION',
            message: 'Pre-Import Checks threw an exception',
            details: JSON.stringify(err, null, 2),
          },
        })
      })
  }

  /**
   * Final import handler.
   */
  const handleConfirmImport = () => {
    if (!us_hasRunPreImportChecks) return
    us_setIsImporting(true)
    const processedDataWithSupplier = Array.isArray(us_preImportProcessedData)
      ? us_preImportProcessedData.map((item: any) => ({
          ...item,
          row: {
            ...item.row,
            associated_supplier_key: importAdditionalData.associated_supplier_key,
            associated_supplier_name: importAdditionalData.associated_supplier_name,
          },
        }))
      : us_preImportProcessedData
    importSubmission(processedDataWithSupplier, { ...importHooks, localClientKey: uc_RootData_ClientKey })
      .then(() => {
        us_setIsImporting(false)
        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        ur_forceRerender()
      })
      .catch((err) => {
        us_setIsImporting(false)
        console.error('importSubmission error:', err)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'FINAL-IMPORT-ERROR',
            message: 'Import Submission failed',
            details: JSON.stringify(err, null, 2),
          },
        })
      })
  }

  const rJSX_PreImportSummary = () => {
    if (!us_hasRunPreImportChecks) return null
    const { totalRows, finalRows, skippedQtyZero, skippedNoProject, skippedNoTask, tasksFound, teamsFound, duplicatesFound } = us_preImportSummary
    return (
      <Card sx={{ margin: '8px', padding: '8px' }}>
        <Typography
          variant="h6"
          gutterBottom
        >
          Pre-Import Summary
        </Typography>
        <Box sx={{ marginLeft: '16px', marginBottom: '8px' }}>
          <Box>
            • <strong>Total Rows:</strong> {totalRows ?? 0}
          </Box>
          <Box>
            • <strong>Skipped Qty Zero:</strong> {skippedQtyZero ?? 0}
          </Box>
          <Box>
            • <strong>Skipped No Project:</strong> {skippedNoProject ?? 0}
          </Box>
          <Box>
            • <strong>Skipped No Task:</strong> {skippedNoTask ?? 0}
          </Box>
          <Box>
            • <strong>Tasks Found:</strong> {tasksFound ?? 0}
          </Box>
          <Box>
            • <strong>Teams Found:</strong> {teamsFound ?? 0}
          </Box>
          <Box>
            • <strong>Duplicates Found (Pre-Check):</strong> {duplicatesFound ?? 0}
          </Box>
          <Box>
            • <strong>Final Rows (non-skipped):</strong> {finalRows ?? 0}
          </Box>
        </Box>
      </Card>
    )
  }

  const rJSX_BottomActions = () => {
    const canRunPreImport = allRequiredFieldsMapped()
    const importButtonDisabled = !us_hasRunPreImportChecks || us_isImporting
    return (
      <>
        {!us_hasRunPreImportChecks && (
          <Button
            variant="contained"
            color="info"
            disabled={!canRunPreImport || us_preImportInProgress}
            onClick={handlePreImportChecks}
            sx={{ marginRight: '8px' }}
          >
            {us_preImportInProgress ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="gears" />
            )}
            <Box sx={{ marginLeft: '4px' }}>Run Pre-Import Checks</Box>
          </Button>
        )}
        {us_hasRunPreImportChecks && (
          <Button
            variant="contained"
            color="success"
            onClick={handleConfirmImport}
            disabled={importButtonDisabled}
          >
            {us_isImporting ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="cloud-arrow-up" />
            )}
            <Box sx={{ marginLeft: '4px' }}>Confirm Import</Box>
          </Button>
        )}
      </>
    )
  }

  return (
    <Dialog
      className="bp_dialog_xl_width"
      keepMounted
      onClose={() => uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)}
      open={true}
    >
      <AppBar
        position="static"
        color="info"
      >
        <Toolbar>
          <IconButton
            aria-label="menu"
            color="inherit"
            disabled
            edge="start"
            size="large"
            sx={{ mr: 2, color: '#fff !important' }}
          >
            <Icon icon="upload" />
          </IconButton>
          <Typography
            component="span"
            variant="h6"
            sx={{ flexGrow: 1 }}
          >
            <Box className="tw-inline-block">{importDialogHeader}</Box>
          </Typography>
          {rJSX_BottomActions()}
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ padding: '8px' }}>
        {renderMappingSummary()}
        {rJSX_PreImportSummary()}
        <Card sx={{ margin: '8px' }}>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={us_tableColumns}
            tableData={objectToArray(importRawData)}
            tableSettings={tableSettings_ImportPreview}
          />
        </Card>
      </DialogContent>
    </Dialog>
  )
}

///////////////////////////////
// AdvancedImportButtonAndDialog Component
///////////////////////////////
const AdvancedImportButtonAndDialog: React.FC<TsInterface_AdvancedImportButtonAndDialog> = (props) => {
  const pr_importMappingOptions = getProp(props, 'importMappingOptions', {})
  const pr_preImportChecks = getProp(props, 'preImportChecks', performPreImportChecks)
  const pr_importSubmission = getProp(props, 'importSubmission', () => Promise.resolve({ success: true }))
  const pr_importAdditionalData = getProp(props, 'importAdditionalData', {})

  const pr_importButtonText = getProp(props, 'importButtonText', 'Advanced Import')
  const pr_importButtonColor = getProp(props, 'importButtonColor', 'info')
  const pr_importButtonDisabled = getProp(props, 'importButtonDisabled', false)
  const pr_importButtonShrink = getProp(props, 'importButtonShrink', false)
  const pr_importDialogHeader = getProp(props, 'importDialogHeader', 'Advanced Import')

  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  const importHooks: TsInterface_AdvancedImportHooksObject = {
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    uc_RootData_ClientPermissions,
    uc_RootData_ClientUser,
    uc_RootData_GlobalUser,
    uc_setUserInterface_AlertDialogDisplay,
    uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_CustomDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_LoadingBarDisplay,
    uc_setUserInterface_PromptDialogDisplay,
    un_routerNavigation,
    ur_forceRerender,
    importAdditionalData: pr_importAdditionalData,
  }

  // This triggers after file selection.
  // Supplier-specific logic: if supplier is home_depot, assume header row is at index 6 (7th row) and data begins at index 7.
  const openSpreadsheetCustomDialog = (event: React.ChangeEvent<HTMLInputElement>): TsType_UnknownPromise =>
    new Promise((resolve, reject) => {
      if (event?.target?.files?.length) {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (readEvent) => {
          if (typeof readEvent?.target?.result === 'string') {
            try {
              const binaryString = readEvent.target.result
              const workbook = XLSX.read(binaryString, { type: 'binary' })
              const sheetName = workbook.SheetNames[0]
              const sheet = workbook.Sheets[sheetName]
              const originalParsedData: any[] = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                blankrows: false, // skip empty rows
                defval: null,
              })

              // Determine header and data row indexes based on supplier.
              const supplierKey = pr_importAdditionalData.associated_supplier_key || ''
              // For home_depot, we assume header row is row 7 (index 6) and data starts on row 8 (index 7).
              const headerRowIndex = supplierKey === 'home_depot' ? 5 : 0
              const dataRowIndex = supplierKey === 'home_depot' ? 6 : 1

              console.log('Original Parsed Data:', originalParsedData)
              console.log('Determined header row index:', headerRowIndex)

              const headersArray: string[] = originalParsedData[headerRowIndex] || []
              const dataRows: any[] = originalParsedData.slice(dataRowIndex)

              const spreadsheetObjectData: TsInterface_UnspecifiedObject = {}
              dataRows.forEach((row, idx) => {
                const rowKey = (idx + 1).toString()
                spreadsheetObjectData[rowKey] = {}
                headersArray.forEach((colProp, colIdx) => {
                  const colValue = row[colIdx]
                  if (colValue != null && colValue !== '') {
                    spreadsheetObjectData[rowKey][colProp] = colValue
                  }
                })
              })

              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <AdvancedImportDialog
                      importMappingOptions={pr_importMappingOptions}
                      importDialogHeader={pr_importDialogHeader}
                      importHooks={importHooks}
                      importRawData={spreadsheetObjectData}
                      importRawHeaders={headersArray}
                      importSubmission={pr_importSubmission}
                      preImportChecks={pr_preImportChecks}
                      importAdditionalData={pr_importAdditionalData}
                    />
                  ),
                  settings: { max_width: 'lg' },
                },
              })

              resolve({ success: true })
            } catch (err) {
              console.error('Parsing error:', err)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  code: 'SPREADSHEET-PARSE',
                  message: 'Failed to parse spreadsheet',
                  details: JSON.stringify(err, null, 2),
                },
              })
              reject({ success: false, error: err })
            }
          } else {
            const error = {
              code: 'SPREADSHEET-READ',
              message: 'Failed to read file',
              details: 'Unknown error occurred',
            }
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
            reject({ success: false, error })
          }
        }
        reader.readAsBinaryString(file)
      } else {
        const error = {
          code: 'NO-FILE-SELECTED',
          message: 'No file selected',
          details: 'Please select a valid spreadsheet',
        }
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
        reject({ success: false, error })
      }
    })

  const rJSX_ImportButton = () => {
    if (!pr_importButtonShrink) {
      return (
        <FileUploadButton
          multiple={false}
          button={{
            text: pr_importButtonText,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
                sx={{ fontSize: '20px' }}
              />
            ),
            color: pr_importButtonColor,
            className: 'tw-mr-2',
            variant: 'contained',
            disabled: pr_importButtonDisabled,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={pr_importAdditionalData || {}}
        />
      )
    } else {
      return (
        <FileUploadButton
          multiple={false}
          button={{
            text: <></>,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                sx={{ fontSize: '20px' }}
              />
            ),
            color: pr_importButtonColor,
            className: 'bp_icon_only_button tw-mr-2',
            variant: 'contained',
            disabled: pr_importButtonDisabled,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={pr_importAdditionalData || {}}
        />
      )
    }
  }

  return <>{rJSX_ImportButton()}</>
}

export default AdvancedImportButtonAndDialog
