// TODO: Used by sales partners for now

///////////////////////////////
// Imports
///////////////////////////////

import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Tooltip, Typography } from '@mui/material'
import { activeTasksCell } from 'app/models/projects/project_table'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  millisecondsPerDay,
  objectToArray,
  replaceNewlinesWithHtmlBreaks,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { formInputs_NewStickyNote, formInputs_StickyNote, formSettings_StickyNote } from '../forms/sticky_notes'

///////////////////////////////
// Variables
///////////////////////////////

const tableColumns_ProjectsByMilestone: TsInterface_TableColumns = {
  // associated_customer_risk_status: customerRiskStatusCell,
  id_number: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages[tableAdditionalData.projectUrl].url(rowData.key as string))
                }
              }}
            >
              {rowData.id_number}
            </Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Job Code')
      },
      header_sort_by: null,
    },
  },
  TEMP_days_in_phase: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <>
            {rowData.TEMP_last_milestone_timestamp != null ? (
              <>
                <Box>
                  {rowData.TEMP_days_in_phase} {rowData.TEMP_days_in_phase === 1 ? <>{rLIB('day')}</> : <>{rLIB('days')}</>}
                </Box>
                <Box className="tw-opacity-30 tw-capitalize">{returnFormattedDate(rowData.TEMP_last_milestone_timestamp, 'D MMM YY')}</Box>
              </>
            ) : (
              <></>
            )}
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Days in Phase')
      },
      header_sort_by: 'TEMP_days_in_phase',
    },
  },
  associated_customer_name: TableCellBasic('associated_customer_name', rLIB('Customer'), 'associated_customer_name'),
  location_jurisdiction: TableCellBasic('location_jurisdiction', rLIB('AHJ'), 'location_jurisdiction'),
  associated_finance_partner_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <>
            <Box>{rowData.associated_finance_partner_name}</Box>
            <Box className="tw-opacity-30 tw-capitalize">{rowData.associated_financing_type}</Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Finance Partner')
      },
      header_sort_by: null,
    },
  },
  TEMP_active_tasks: activeTasksCell,
  notes: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let stickyNoteContent = <></>
        let editIcon = <></>
        let addIcon = <></>
        if (tableAdditionalData.readOrWrite === 'write') {
          addIcon = (
            <Icon
              icon="circle-plus"
              type="solid"
              className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
              tooltip={rLIB('New Sticky Note')}
              tooltipPlacement="top"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: {
                        date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
                      },
                      formInputs: formInputs_NewStickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let newNoteId = uuidv4()
                          let updateObject = {
                            sticky_notes: {
                              [newNoteId]: {
                                timestamp: returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)),
                                note: formSubmittedData.note,
                                key: newNoteId,
                              },
                            },
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Add Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          type="solid"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
          editIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main tw-cursor-pointer"
              size="xl"
              tooltip={rLIB('Edit Sticky Note')}
              tooltipPlacement="right"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: formInputs_StickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Edit Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          className="tw-mr-2"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
        } else {
          editIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main"
              size="xl"
            />
          )
        }

        let stickyNoteContentOld = <></>
        let hasOldStickyNote = false
        if (rowData != null && rowData.sticky_note != null && rowData.sticky_note !== '') {
          hasOldStickyNote = true
          stickyNoteContentOld = (
            <Box>
              <Box
                className="tw-inline-block tw-align-top"
                sx={{ width: '32px' }}
              >
                {editIcon}
              </Box>
              <Box
                className="tw-inline-block"
                sx={{ width: 'calc(100% - 32px' }}
              >
                {replaceNewlinesWithHtmlBreaks(rowData.sticky_note as string)}
              </Box>
            </Box>
          )
        }

        const rJSX_EditIcon = (
          readOrWrite: 'read' | 'write',
          note: TsInterface_UnspecifiedObject,
          uc_RootData_ClientKey: any,
          uc_setRootData_ClientKey: any,
          uc_setUserInterface_FormDialogDisplay: any,
          uc_setUserInterface_ErrorDialogDisplay: any,
          projectKey: any,
        ) => {
          let editIconJSX = <></>
          if (readOrWrite === 'write') {
            editIconJSX = (
              <Tooltip title={rLIB('Edit Sticky Note')}>
                <Box
                  className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
                  onClick={() => {
                    uc_setUserInterface_FormDialogDisplay({
                      display: true,
                      form: {
                        form: {
                          formAdditionalData: {},
                          formData: {
                            note: note.note,
                            date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
                          },
                          formInputs: formInputs_NewStickyNote,
                          formOnChange: (
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formData: TsInterface_FormData,
                            formInputs: TsInterface_FormInputs,
                            formSettings: TsInterface_FormSettings,
                          ) => {},
                          formSettings: formSettings_StickyNote,
                          formSubmission: (
                            formSubmittedData: TsInterface_FormSubmittedData,
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formHooks: TsInterface_FormHooksObject,
                          ) => {
                            return new Promise((resolve, reject) => {
                              let updateObject = {
                                sticky_notes: {
                                  [note.key]: {
                                    note: formSubmittedData.note,
                                    timestamp: returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)),
                                  },
                                },
                              }
                              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                                DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                                  .then((res_DSMD) => {
                                    resolve(res_DSMD)
                                  })
                                  .catch((rej_DSMD) => {
                                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                    reject(rej_DSMD)
                                  })
                              })
                            })
                          },
                        },
                        dialog: {
                          formDialogHeaderColor: 'warning',
                          formDialogHeaderText: rLIB('Edit Sticky Note'),
                          formDialogIcon: (
                            <Icon
                              icon="note"
                              type="solid"
                            />
                          ),
                        },
                      },
                    })
                  }}
                >
                  <Icon icon="pen-to-square"></Icon>
                </Box>
              </Tooltip>
            )
          }
          return editIconJSX
        }

        const rJSX_DeleteIcon = (
          readOrWrite: 'read' | 'write',
          noteKey: any,
          note: TsInterface_UnspecifiedObject,
          uc_RootData_ClientKey: any,
          uc_setRootData_ClientKey: any,
          uc_setUserInterface_ErrorDialogDisplay: any,
          uc_setUserInterface_ConfirmDialogDisplay: any,
          projectKey: any,
        ) => {
          let deleteIconJSX = <></>
          if (readOrWrite === 'write') {
            deleteIconJSX = (
              <Tooltip title={rLIB('Delete Sticky Note')}>
                <Box className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2">
                  <Icon
                    icon="trash"
                    onClick={() => {
                      uc_setUserInterface_ConfirmDialogDisplay({
                        display: true,
                        confirm: {
                          color: 'error',
                          header: <>{rLIB('Delete Sticky Note')}</>,
                          icon: <Icon icon="trash" />,
                          submit_callback: () => {
                            return new Promise((resolve, reject) => {
                              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  let updateObject = {
                                    sticky_notes: {
                                      [noteKey]: null,
                                    },
                                    sticky_notes_deleted: {
                                      [noteKey]: note,
                                    },
                                  }
                                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                      reject(rej_DSMD)
                                    })
                                })
                                .catch((rej_GCK) => {
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                  reject(rej_GCK)
                                })
                            })
                          },
                          submit_text: rLIB('Delete'),
                          text: rLIB('Are you sure that you want to delete this sticky note?'),
                        },
                      })
                    }}
                  />
                </Box>
              </Tooltip>
            )
          }
          return deleteIconJSX
        }

        let stickyNotes = getProp(rowData, 'sticky_notes', {})
        if (objectToArray(stickyNotes).length > 0) {
          stickyNoteContent = (
            <Box>
              {objectToArray(stickyNotes)
                .sort(dynamicSort('timestamp', 'desc'))
                .map((stickyNote: TsInterface_UnspecifiedObject, index: number) => (
                  <Box key={index}>
                    <Icon
                      icon="note"
                      className="tw-mr-1"
                      sx={{ color: themeVariables.secondary_main }}
                    />
                    <Box
                      component="span"
                      className="tw-inline-block tw-mr-1"
                      sx={{ color: themeVariables.secondary_main }}
                    >
                      {returnFormattedDate(stickyNote.timestamp, 'M/D/YY')}
                    </Box>
                    {stickyNote.note}
                    {rJSX_EditIcon(
                      tableAdditionalData.readOrWrite,
                      stickyNote,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                      tableHooks.uc_setUserInterface_FormDialogDisplay,
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      rowData.key as string,
                    )}
                    {rJSX_DeleteIcon(
                      tableAdditionalData.readOrWrite,
                      stickyNote.key,
                      stickyNote,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      tableHooks.uc_setUserInterface_ConfirmDialogDisplay,
                      rowData.key as string,
                    )}
                  </Box>
                ))}
            </Box>
          )
        } else if (hasOldStickyNote === true) {
          stickyNoteContent = <></>
        } else {
          stickyNoteContent = (
            <Box>
              <Typography className="tw-italic tw-opacity-50 tw-inline-block">
                <Box className="tw-inline-block">{rLIB('No Sticky Notes')}</Box>
              </Typography>
            </Box>
          )
        }

        cellJSX = (
          <Box sx={{ maxWidth: '400px' }}>
            {stickyNoteContent}
            {stickyNoteContentOld}
            <Box>{addIcon}</Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Project Notes')
      },
      header_sort_by: null,
    },
  },
}

const tableSettings_ProjectsByMilestone: TsInterface_TableSettings = {
  paginated: false,
  // pagination_rows_per_page_default: 100,
  // pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'asc',
  sort_property_default: 'id_number',
}

///////////////////////////////
// Functions
///////////////////////////////

const rJSX_SummaryStats = (milestoneTaskKey: string, us_projectsSortedByMilestone: TsInterface_UnspecifiedObject | null): JSX.Element => {
  let statsJSX = <></>
  let projectCount = 0
  let redCount = 0
  let yellowCount = 0
  let greenCount = 0
  let stalledProjectsCount = 0
  let projectsMissingTasksCount = 0
  let endTimestamp = new Date().getTime()
  if (us_projectsSortedByMilestone != null) {
    projectCount = objectToArray(us_projectsSortedByMilestone[milestoneTaskKey]).length
    for (let loopProjectKey in us_projectsSortedByMilestone[milestoneTaskKey]) {
      let loopProject = us_projectsSortedByMilestone[milestoneTaskKey][loopProjectKey]
      if (loopProject != null && loopProject.current_active_tasks != null && loopProject.current_active_tasks.length === 0) {
        stalledProjectsCount++
      }
      if (objectToArray(loopProject['current_active_tasks']).length > 0) {
        for (let loopTaskKey in loopProject['current_active_tasks']) {
          let loopTask = loopProject['current_active_tasks'][loopTaskKey]
          // Get
          let elapsedDays = parseInt(((endTimestamp - returnTimestampFromUnknownDateFormat(loopTask.timestamp_start)) / millisecondsPerDay).toString())
          if (loopTask['status_green_days_cutoff'] != null && elapsedDays <= loopTask['status_green_days_cutoff']) {
            greenCount += 1
          } else if (loopTask['status_yellow_days_cutoff'] != null && elapsedDays <= loopTask['status_yellow_days_cutoff']) {
            yellowCount += 1
          } else {
            redCount += 1
          }
        }
      } else {
        projectsMissingTasksCount += 1
      }
    }
  }

  let projectCountJSX = <></>
  let stalledCountJSX = <></>
  let redCountJSX = <></>
  let yellowCountJSX = <></>
  let greenCountJSX = <></>
  let projectsMissingTasksCountJSX = <></>
  if (projectCount > 0) {
    projectCountJSX = (
      <Box>
        {projectCount} {rLIB('Projects')}
      </Box>
    )
  } else {
    projectCountJSX = (
      <Box className="tw-opacity-30">
        {projectCount} {rLIB('Projects')}
      </Box>
    )
  }
  if (stalledProjectsCount > 0) {
    stalledCountJSX = (
      <Box sx={{ color: themeVariables.error_main }}>
        <Icon
          icon="circle-pause"
          className="tw-mr-2"
        />
        {stalledProjectsCount} {rLIB('projects')}
      </Box>
    )
  } else {
    stalledCountJSX = (
      <Box sx={{ color: themeVariables.white, opacity: 0.2 }}>
        <Icon
          icon="circle-pause"
          className="tw-mr-2"
        />
        {stalledProjectsCount} {rLIB('projects')}
      </Box>
    )
  }
  if (redCount > 0) {
    redCountJSX = (
      <Box sx={{ color: themeVariables.error_main }}>
        <Icon
          icon="siren-on"
          className="tw-mr-2"
        />
        {redCount} {rLIB('tasks')}
      </Box>
    )
  } else {
    redCountJSX = (
      <Box sx={{ color: themeVariables.white, opacity: 0.2 }}>
        <Icon
          icon="siren-on"
          className="tw-mr-2"
        />
        {redCount} {rLIB('tasks')}
      </Box>
    )
  }
  if (yellowCount > 0) {
    yellowCountJSX = (
      <Box sx={{ color: themeVariables.warning_dark }}>
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
        />
        {yellowCount} {rLIB('tasks')}
      </Box>
    )
  } else {
    yellowCountJSX = (
      <Box sx={{ color: themeVariables.white, opacity: 0.2 }}>
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
        />
        {yellowCount} {rLIB('tasks')}
      </Box>
    )
  }
  if (greenCount > 0) {
    greenCountJSX = (
      <Box sx={{ color: themeVariables.success_main }}>
        <Icon
          icon="circle-play"
          className="tw-mr-2"
        />
        {greenCount} {rLIB('tasks')}
      </Box>
    )
  } else {
    greenCountJSX = (
      <Box sx={{ color: themeVariables.white, opacity: 0.2 }}>
        <Icon
          icon="circle-play"
          className="tw-mr-2"
        />
        {greenCount} {rLIB('tasks')}
      </Box>
    )
  }
  if (projectsMissingTasksCount > 0) {
    projectsMissingTasksCountJSX = <Box></Box>
  } else {
    projectsMissingTasksCountJSX = <Box></Box>
  }

  statsJSX = (
    <Stack
      direction="row"
      spacing={2}
    >
      {projectCountJSX}
      <Box>|</Box>
      {stalledCountJSX}
      <Box>|</Box>
      {redCountJSX}
      {yellowCountJSX}
      {greenCountJSX}
      {projectsMissingTasksCountJSX}
    </Stack>
  )

  return statsJSX
}

const rJSX_ProjectsForWorkflowMilestoneTableList = (
  milestoneTaskKey: string,
  us_projectsSortedByMilestone: TsInterface_UnspecifiedObject | null,
  projectUrl: string,
  readOrWrite: 'read' | 'write',
) => {
  let tableJSX = <></>
  if (
    milestoneTaskKey != null &&
    us_projectsSortedByMilestone != null &&
    us_projectsSortedByMilestone[milestoneTaskKey] != null &&
    objectToArray(us_projectsSortedByMilestone[milestoneTaskKey]).length > 0
  ) {
    tableJSX = (
      <Box
        sx={{ border: '1px solid ' + themeVariables.gray_700 }}
        className="tw-rounded-sm"
      >
        <TableBasic
          tableAdditionalData={{ projectUrl: projectUrl, readOrWrite: readOrWrite }}
          tableColumns={tableColumns_ProjectsByMilestone}
          tableData={objectToArray(us_projectsSortedByMilestone[milestoneTaskKey])}
          tableSettings={tableSettings_ProjectsByMilestone}
        />
      </Box>
    )
  } else {
    tableJSX = (
      <Box>
        <Typography
          variant="body1"
          className="tw-my-1"
        >
          {rLIB('No projects for this milestone')}
        </Typography>
      </Box>
    )
  }
  return tableJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const rJSX_ProjectPhasesTabContent = (
  us_phaseWorkflowKey: string | null,
  us_taskWorkflowsObject: TsInterface_UnspecifiedObject | null,
  us_sortedMilestonePhaseTasksArray: TsInterface_UnspecifiedObject[] | null,
  us_projectsSortedByMilestone: TsInterface_UnspecifiedObject | null,
  un_routerNavigation: any,
  projectUrl: string,
  readOrWrite: 'read' | 'write',
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
): JSX.Element => {
  let contentJSX = <></>
  if (us_phaseWorkflowKey == null || us_phaseWorkflowKey === '') {
    contentJSX = (
      <Box className="tw-p-4 tw-text-center tw-opacity-50">
        <Typography
          variant="h6"
          className="tw-my-1"
        >
          {rLIB('Select Workflow')}
        </Typography>
      </Box>
    )
  }
  if (
    us_phaseWorkflowKey != null &&
    us_taskWorkflowsObject != null &&
    us_taskWorkflowsObject[us_phaseWorkflowKey] != null &&
    us_taskWorkflowsObject[us_phaseWorkflowKey]['filter_milestones'] != null &&
    us_sortedMilestonePhaseTasksArray != null &&
    us_sortedMilestonePhaseTasksArray.length > 0
  ) {
    contentJSX = (
      <Box>
        {us_sortedMilestonePhaseTasksArray.map((milestone: TsInterface_UnspecifiedObject, milestoneIndex: number) => (
          <Box
            className="tw-mt-2"
            key={milestoneIndex}
          >
            <Accordion>
              <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
                <Box>
                  <Typography
                    variant="h5"
                    className="tw-my-1 tw-opacity-40 tw-inline-block"
                  >
                    {milestone['name']}
                  </Typography>
                  {rJSX_SummaryStats(milestone.key, us_projectsSortedByMilestone)}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {rJSX_ProjectsForWorkflowMilestoneTableList(milestone.key, us_projectsSortedByMilestone, projectUrl, readOrWrite)}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    )
  } else if (us_phaseWorkflowKey != null && us_taskWorkflowsObject != null && us_taskWorkflowsObject[us_phaseWorkflowKey] != null) {
    contentJSX = (
      <Box className="tw-p-4 tw-text-center tw-opacity-50">
        <Typography
          variant="h6"
          className="tw-my-1"
        >
          {rLIB('Milestone tasks not set on workflow')}
        </Typography>
      </Box>
    )
  }
  return contentJSX
}
