/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Masonry } from '@mui/lab'
import { Avatar, Box, Button, Card, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { returnProjectMilestoneTimelineDates, returnSortedWorkflowPhasesArray } from 'app/pages/pacing/services/project_phases'
import dayjs from 'dayjs'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ActiveFinancePartners_Query } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_Material_Imports_By_Project } from 'rfbp_aux/services/database_endpoints/materials/material_imports'
import { DatabaseRef_ProjectFeedback_Query } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import {
  DatabaseRef_ProjectAdditionalData_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ProjectReminders_Query } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { stringAvatar, stringToColor } from 'rfbp_core/components/chat'
import { DirectMultipleChoiceEdit, tsI_MultipleChoiceOption } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import { DirectNumberEdit } from 'rfbp_core/components/direct_edits/direct_number_edit'
import { DirectParagraphEdit } from 'rfbp_core/components/direct_edits/direct_paragraph_edit'
import { DirectTextEdit } from 'rfbp_core/components/direct_edits/direct_text_edit'
import { FeedbackNpsComments } from 'rfbp_core/components/feedback'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { listOfUnitedStates } from 'rfbp_core/components/form/inputs/multiple_choice_select_state_usa_abbreviated'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { formInputs_WarehouseDistance } from '../forms/project_customer_details'
import { homeBuildingTypeOptions, roofTypeOptions } from '../forms/project_home_details'
import { formInputs_NewStickyNote, formSettings_StickyNote } from '../forms/sticky_notes'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_ProjectDetailsTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

///////////////////////////////
// Variables
///////////////////////////////

const tabKey = 'project_details'

///////////////////////////////
// Functions
///////////////////////////////

const checkAndRepairTaskStatuses = (clientKey: string, tasks: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let messedUpTasks: TsInterface_UnspecifiedObject = {}
    let taskRepairUpdateObjects: TsInterface_UnspecifiedObject = {}
    let hasTaskRepairs = false
    // Loop through tasks
    for (let loopTaskKey in tasks) {
      let loopTask = tasks[loopTaskKey]
      // If there are prerequisite tasks
      if (loopTask.prerequisite_tasks == null || objectToArray(loopTask.prerequisite_tasks).length === 0) {
        if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
          taskRepairUpdateObjects[loopTaskKey] = { ready_to_start: true }
        }
      }
      if (loopTask.prerequisite_tasks != null) {
        // Loop through prerequisite tasks
        for (let loopPrereqTaskKey in loopTask.prerequisite_tasks) {
          let loopPrereqTask = tasks[loopPrereqTaskKey]
          if (loopPrereqTask != null && loopPrereqTask.status_complete === true) {
            if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
              messedUpTasks[loopTaskKey] = loopTask
              if (taskRepairUpdateObjects[loopTaskKey] == null) {
                taskRepairUpdateObjects[loopTaskKey] = {
                  ready_to_start: true,
                  prerequisite_tasks_completion: {},
                }
              }
              if (loopPrereqTask['timestamp_completed'] != null) {
                taskRepairUpdateObjects[loopTaskKey]['prerequisite_tasks_completion'][loopPrereqTaskKey] = loopPrereqTask['timestamp_completed']
              }
              hasTaskRepairs = true
            }
            loopTask.prerequisite_tasks[loopPrereqTaskKey] = loopPrereqTaskKey
          }
        }
      }
    }
    if (hasTaskRepairs === true) {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      for (let loopTaskKey in taskRepairUpdateObjects) {
        taskRepairUpdateObjects[loopTaskKey]['timestamp_last_updated'] = new Date()
        updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: taskRepairUpdateObjects[loopTaskKey] })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      resolve({ success: true })
    }
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewProjectDetailsTab = forwardRef((props: TsInterface_ProjectView_ProjectDetailsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_ProjectDetailsTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ProjectView_ProjectDetailsTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_readOrWrite: TsInterface_ProjectView_ProjectDetailsTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_additionalProjectData, us_setAdditionalProjectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_minimizedCustomerDetails, us_setMinimizedCustomerDetails] = useState<boolean>(false)
  const [us_minimizedSalesPartnerDetails, us_setMinimizedSalesPartnerDetails] = useState<boolean>(false)
  const [us_minimizedFinancingDetails, us_setMinimizedFinancingDetails] = useState<boolean>(false)
  const [us_minimizedCustomerHappiness, us_setMinimizedCustomerHappiness] = useState<boolean>(false)
  const [us_minimizedEstimatedTimeline, us_setMinimizedEstimatedTimeline] = useState<boolean>(false)
  const [us_minimizedCustomerFeedback, us_setMinimizedCustomerFeedback] = useState<boolean>(false)
  const [us_minimizedHomeDetails, us_setMinimizedHomeDetails] = useState<boolean>(true)
  const [us_minimizedLastContact, us_setMinimizedLastContact] = useState<boolean>(false)
  const [us_minimizedProgressDetails, us_setMinimizedProgressDetails] = useState<boolean>(false)
  const [us_minimizedProjectFinancialData, us_setMinimizedProjectFinancialData] = useState<boolean>(true)
  const [us_minimizedProjectTasks, us_setMinimizedProjectTasks] = useState<boolean>(false)
  const [us_minimizedProjectTimestampsData, us_setMinimizedProjectTimestampsData] = useState<boolean>(true)
  const [us_minimizedReminderDetails, us_setMinimizedReminderDetails] = useState<boolean>(false)
  const [us_minimizedSalesPartnerSpecificData, us_setMinimizedSalesPartnerSpecificData] = useState<boolean>(true)
  const [us_minimizedStickyNotes, us_setMinimizedStickyNotes] = useState<boolean>(false)
  const [us_minimizedSystemDetails, us_setMinimizedSystemDetails] = useState<boolean>(false)
  const [us_projectFeedback, us_setProjectFeedback] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectOpenReminders, us_setProjectOpenReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflowUserRoles, us_setProjectTaskWorkflowUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectUsersFlatObject, us_setProjectUsersFlatObject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_materialImports, us_setMaterialImports] = useState<TsInterface_UnspecifiedObject>({})
  const [us_refreshingData, us_setRefreshingData] = useState<boolean>(false)
  const [us_refreshingProgressBar, us_setRefreshingProgressBar] = useState<boolean>(false)
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProjectLoaded, us_setRootProjectLoaded] = useState<boolean>(false)
  const [us_repairedTasks, us_setRepairedTasks] = useState<boolean>(false)
  const [us_reloadTaskWorkflow] = useState<number>(0)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_sortedMilestonePhaseTasksArray, us_setSortedMilestonePhaseTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_milestoneTimelineDates, us_setMilestoneTimelineDates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseWorkflowTasks, us_setPhaseWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect
  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage(tabKey)
    if (getProp(pageLocalStorageData, 'us_minimizedCustomerDetails', null) != null) {
      us_setMinimizedCustomerDetails(getProp(pageLocalStorageData, 'us_minimizedCustomerDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedSalesPartnerDetails', null) != null) {
      us_setMinimizedSalesPartnerDetails(getProp(pageLocalStorageData, 'us_minimizedSalesPartnerDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedFinancingDetails', null) != null) {
      us_setMinimizedFinancingDetails(getProp(pageLocalStorageData, 'us_minimizedFinancingDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedSystemDetails', null) != null) {
      us_setMinimizedSystemDetails(getProp(pageLocalStorageData, 'us_minimizedSystemDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedLastContact', null) != null) {
      us_setMinimizedLastContact(getProp(pageLocalStorageData, 'us_minimizedLastContact', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedHomeDetails', null) != null) {
      us_setMinimizedHomeDetails(getProp(pageLocalStorageData, 'us_minimizedHomeDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedProjectTasks', null) != null) {
      us_setMinimizedProjectTasks(getProp(pageLocalStorageData, 'us_minimizedProjectTasks', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedStickyNotes', null) != null) {
      us_setMinimizedStickyNotes(getProp(pageLocalStorageData, 'us_minimizedStickyNotes', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedEstimatedTimeline', null) != null) {
      us_setMinimizedEstimatedTimeline(getProp(pageLocalStorageData, 'us_minimizedEstimatedTimeline', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedCustomerFeedback', null) != null) {
      us_setMinimizedCustomerFeedback(getProp(pageLocalStorageData, 'us_minimizedCustomerFeedback', null))
    }
  }, [])

  // TODO: TEMP
  useEffect(() => {
    if (us_rootProject != null && us_rootProject['sticky_note'] != null) {
      let noteKey = uuidv4()
      let updateObject = {
        sticky_note: null,
        sticky_notes: {
          [noteKey]: {
            timestamp: new Date(),
            note: us_rootProject['sticky_note'],
            key: noteKey,
          },
        },
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        DatabaseSetMergeDocument(DatabaseRef_Project_Document(actualClientKey, pr_projectKey), updateObject)
      })
    }
    return () => {}
  }, [us_rootProject, uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAdditionalProjectData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectAdditionalData_Document(actualClientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFeedback(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectFeedback_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectFeedback({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectOpenReminders(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectReminders_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectOpenReminders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
        // us_setProjectTasksLoaded(true)
        if (us_repairedTasks === false) {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
            let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
            checkAndRepairTaskStatuses(actualClientKey, newData)
          })
          us_setRepairedTasks(true)
        }
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
      // us_setProjectTasksLoaded(true)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_repairedTasks, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      us_setRootProjectLoaded(true)
      // TODO - TEMP
      if (newData != null && newData['task_completion_stats'] != null && newData['task_completion_stats']['unknown'] != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: actualClientKey,
            project_key: pr_projectKey,
          })
          ur_forceRerender()
        })
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(actualClientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(actualClientKey, pr_projectKey))
          .then((res_DGD) => {
            // us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorkflow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
            us_setProjectTaskWorkflowUserRoles(taskWorkflowUserRolesList)
            // us_setUsersWithDirectOrScheduledTasksRoles(taskWorkflowUserRolesWithDirectOrScheduledTasksList)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow, pr_clientKey])

  useEffect(() => {
    let projectUsersFlatObject: TsInterface_UnspecifiedObject = {}
    if (us_rootProject != null) {
      if (us_rootProject['associated_customer_key'] != null && us_rootProject['associated_customer_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_customer_key']]] = us_rootProject['associated_customer_name']
      }
      if (us_rootProject['associated_sales_rep_key'] != null && us_rootProject['associated_sales_rep_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_sales_rep_key']]] = us_rootProject['associated_sales_rep_name']
      }
      if (us_rootProject['associated_css_rep_key'] != null && us_rootProject['associated_css_rep_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_css_rep_key']]] = us_rootProject['associated_css_rep_name']
      }
      for (let loopRoleKey in us_projectTaskWorkflowUserRoles) {
        if (us_rootProject['associated_' + loopRoleKey + '_key'] != null && us_rootProject['associated_' + loopRoleKey + '_name'] != null) {
          projectUsersFlatObject[us_rootProject['associated_' + loopRoleKey + '_key']] = us_rootProject['associated_' + loopRoleKey + '_name']
        }
      }
    }
    us_setProjectUsersFlatObject(projectUsersFlatObject)
  }, [us_rootProject, us_projectTaskWorkflowUserRoles])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setMaterialImports(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Material_Imports_By_Project(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setMaterialImports({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestones'] != null && us_phaseWorkflowTasks != null && us_phaseWorkflowTasks['tasks'] != null) {
      let sortedMilestonePhaseTasksArray = returnSortedWorkflowPhasesArray(us_phaseWorkflowTasks['tasks'], us_taskWorkflow)
      us_setSortedMilestonePhaseTasksArray(sortedMilestonePhaseTasksArray)
    }
  }, [us_taskWorkflow, us_phaseWorkflowTasks])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    if (us_rootProject != null && us_rootProject.associated_task_workflow_key != null && us_rootProject.associated_task_workflow_key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_TaskWorkflow_Document(actualClientKey, us_rootProject.associated_task_workflow_key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setTaskWorkflow({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootProject, pr_clientKey])

  useEffect(() => {
    if (us_rootProject != null && us_rootProject.associated_task_workflow_key != null && us_rootProject.associated_task_workflow_key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(actualClientKey, us_rootProject.associated_task_workflow_key as string))
            .then((res_DGD) => {
              us_setPhaseWorkflowTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setPhaseWorkflowTasks({})
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootProject, pr_clientKey])

  useEffect(() => {
    if (us_sortedMilestonePhaseTasksArray != null && us_sortedMilestonePhaseTasksArray.length > 0) {
      // Loop through milestones and calculate phase days data
      let milestoneDates = returnProjectMilestoneTimelineDates(us_sortedMilestonePhaseTasksArray, us_rootProject, us_projectTasks)
      us_setMilestoneTimelineDates(milestoneDates)
    }
  }, [us_sortedMilestonePhaseTasksArray, us_rootProject, us_projectTasks])

  // Functions
  const getUniqueTeamMembers = (teamNamesMap: Record<string, Record<string, string>>): string[] => {
    const uniqueNamesSet = new Set<string>()
    if (teamNamesMap && typeof teamNamesMap === 'object') {
      Object.values(teamNamesMap).forEach((dailyMap) => {
        if (dailyMap && typeof dailyMap === 'object') {
          Object.values(dailyMap).forEach((memberName) => {
            uniqueNamesSet.add(memberName)
          })
        }
      })
    }
    return Array.from(uniqueNamesSet)
  }

  const returnMilestoneColorsAndIcons = (
    milestoneTimelineData: TsInterface_UnspecifiedObject,
  ): { milestoneColor: string; milestoneStatusIconJSX: JSX.Element } => {
    let milestoneStatusIconJSX: JSX.Element = <></>
    let milestoneTimelineColor = themeVariables.gray_600
    if (milestoneTimelineData != null && milestoneTimelineData.status === 'complete') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-check"
          className="tw-text-info_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.info_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'in_progress' && milestoneTimelineData.end_date_type === 'estimated_delayed') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-error_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.error_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.using_extension_days === true && milestoneTimelineData.status === 'in_progress') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-warning_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.warning_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'in_progress') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-success_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.success_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'not_started') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-pause"
          className="tw-text-gray_600 tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.gray_600
    }
    return {
      milestoneStatusIconJSX: milestoneStatusIconJSX,
      milestoneColor: milestoneTimelineColor,
    }
  }

  const openDetailsEditDialog = (
    formInputs: TsInterface_FormInputs,
    uc_setUserInterface_FormDialogDisplay: any,
    rootProject: any,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    projectKey: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
  ): void => {
    // Copy of Form Data
    let copyOfFormData = { ...rootProject }
    // Correct Timestamp Formats for Form
    for (let loopInputKey in formInputs) {
      if (formInputs[loopInputKey].input_type === 'timestamp_datetime' && copyOfFormData[loopInputKey] != null) {
        copyOfFormData[loopInputKey] = returnFormattedDate(copyOfFormData[loopInputKey], 'YYYY-MM-DD HH:mm:ss')
      }
    }
    // Open Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: copyOfFormData,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Correct Timestamp Formats for Database
                  for (let loopInputKey in formInputs) {
                    if (formInputs[loopInputKey].input_type === 'timestamp_datetime') {
                      formSubmittedData[loopInputKey] = new Date(formSubmittedData[loopInputKey])
                    }
                  }
                  // Save to database
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Edit Project Details'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_NonEditableDateLineItem = (label: string | JSX.Element, icon: string, object: TsInterface_UnspecifiedObject, propKey: string): JSX.Element => {
    let formattedDateJSX = <></>
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (getProp(object, propKey, null) != null) {
      formattedDateJSX = <>{returnFormattedDate(getProp(object, propKey, null), 'D MMM YYYY')}</>
    } else {
      formattedDateJSX = missingJSX
    }
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {formattedDateJSX}
        </Typography>
      </Box>
    )
  }

  const rJSX_NonEditableTextLineItem = (label: string | JSX.Element, icon: string, object: TsInterface_UnspecifiedObject, propKey: string): JSX.Element => {
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {getProp(object, propKey, missingJSX)}
        </Typography>
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableTextLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectTextEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableParagraphLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectParagraphEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableNumberLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectNumberEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            number={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: number) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    // @ts-ignore
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    options: tsI_MultipleChoiceOption[],
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, propKey, ''), value: getProp(object, propKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'static'}
            options={options}
            onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: selectedOption.value,
                    }
                    if (selectedOption.value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                          ur_forceRerender()
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                          ur_forceRerender()
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    keyPropKey: string,
    valuePropKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    getOptions: () => Promise<tsI_MultipleChoiceOption[]>,
  ): JSX.Element => {
    if (getProp(object, keyPropKey, null) === '') {
      object[keyPropKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, valuePropKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, keyPropKey, ''), value: getProp(object, valuePropKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'dynamic'}
            loadOptions={getOptions}
            onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
              return new Promise((resolve, reject) => {
                resolve({ success: true })
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [keyPropKey]: selectedOption.key,
                      [valuePropKey]: selectedOption.value,
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs - have to look at each instance of this
  const rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    keyPropKey: string,
    valuePropKey: string,
    getOptions: () => Promise<tsI_MultipleChoiceOption[]>,
    onEnter: (selectedOption: TsInterface_UnspecifiedObject) => Promise<boolean>,
  ): JSX.Element => {
    if (getProp(object, keyPropKey, null) === '') {
      object[keyPropKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, valuePropKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, keyPropKey, ''), value: getProp(object, valuePropKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'dynamic'}
            loadOptions={getOptions}
            onEnter={onEnter}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Icon
          icon={icon}
          type="solid"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        />
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  const rJSX_MinimizeSectionIcons = (minimized: boolean, setMinimized: React.Dispatch<React.SetStateAction<boolean>>, sectionKey: string): JSX.Element => {
    return (
      <Box component="span">
        {minimized ? (
          <Icon
            icon="square-caret-down"
            tooltip={rLIB('Expand')}
            className="tw-ml-2 tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimized(false)
              setPageLocalStorage(tabKey, sectionKey, false)
            }}
          />
        ) : (
          <Icon
            icon="square-caret-up"
            tooltip={rLIB('Minimize')}
            className="tw-ml-2 tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimized(true)
              setPageLocalStorage(tabKey, sectionKey, true)
            }}
          />
        )}
      </Box>
    )
  }

  // TODO: Example
  const rJSX_ExampleTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Example')}</Box>
          {/* {rJSX_MinimizeSectionIcons(us_minimizedCustomerDetails, us_setMinimizedCustomerDetails, 'us_minimizedCustomerDetails')} */}
        </Typography>
        {us_minimizedCustomerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                // us_setMinimizedCustomerDetails(false)
                // setPageLocalStorage(tabKey, 'us_minimizedCustomerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2"></Card>
        )}
      </Box>
    )
  }

  // JSX - Details
  const rJSX_Avatar = (size: number): JSX.Element => {
    let avatarJSX = <></>
    avatarJSX = (
      <Avatar
        // eslint-disable-next-line react/no-children-prop
        children={stringAvatar(getProp(us_rootProject, 'associated_customer_name', ''))['children']}
        sx={{ width: size, height: size, margin: 'auto', bgcolor: stringToColor(getProp(us_rootProject, 'associated_customer_name', '')) }}
      />
    )
    return avatarJSX
  }

  const rJSX_DetailsTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Details')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedCustomerDetails, us_setMinimizedCustomerDetails, 'us_minimizedCustomerDetails')}
        </Typography>
        {us_minimizedCustomerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedCustomerDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedCustomerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box
              className="tw-inline-block tw-align-top"
              sx={{ width: '55px', height: '55px' }}
            >
              {rJSX_Avatar(55)}
            </Box>
            <Box
              className="tw-pl-4 tw-inline-block"
              sx={{ width: 'calc(100% - 55px)' }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700 }}
              >
                {getProp(us_rootProject, 'associated_customer_name', '')}
              </Typography>
              <Typography variant="body1">{getProp(us_rootProject, 'id_number', '')}</Typography>
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Contact')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Phone'), 'phone', us_rootProject, 'associated_customer_phone', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Additional Phone'), 'phone', us_rootProject, 'associated_customer_additional_phone', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Email'), 'envelope', us_rootProject, 'associated_customer_email', 'rootProject')}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Address')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Address'), 'location-dot', us_rootProject, 'location_address', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('City'), 'location-dot', us_rootProject, 'location_city', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('County'), 'location-dot', us_rootProject, 'location_county', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Zip'), 'location-dot', us_rootProject, 'location_zip', 'rootProject')}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('State'),
                'location-dot',
                us_rootProject,
                'location_state',
                'rootProject',
                objectToArray(listOfUnitedStates) as unknown as tsI_MultipleChoiceOption[],
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Jurisdiction'), 'location-dot', us_rootProject, 'location_jurisdiction', 'rootProject')}
              {rJSX_NonEditableTextLineItem(rLIB('Latitude'), 'location-dot', us_rootProject, 'location_latitude')}
              {rJSX_NonEditableTextLineItem(rLIB('Longitude'), 'location-dot', us_rootProject, 'location_longitude')}
              {rJSX_DirectlyEditableParagraphLineItem(
                rLIB('Gate Code / Access Instructions'),
                'location-dot',
                us_rootProject,
                'location_access_instructions',
                'rootProject',
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Region')}
              </Typography>

              {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions(
                rLIB('Region'),
                'compass',
                us_rootProject,
                'associated_region_key',
                'associated_region_name',
                'rootProject',
                () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseGetCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey))
                          .then((res_DB) => {
                            let formattedOptions: { key: string; value: string }[] = []
                            for (let loopOptionKey in res_DB.data) {
                              formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                            }
                            resolve(formattedOptions)
                          })
                          .catch((rej_DB) => {
                            reject(rej_DB)
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                      })
                  })
                },
              )}
              <Stack
                direction="row"
                className="group"
                sx={{
                  '&:hover .edit-icon-box': {
                    opacity: 1,
                  },
                }}
              >
                {rJSX_NonEditableTextLineItem(rLIB('Distance from warehouse'), 'compass', us_rootProject, 'location_distance_from_warehouse')}
                <Box
                  className="edit-icon-box"
                  sx={{ opacity: 0 }}
                >
                  <Icon
                    icon="pen-circle"
                    className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main tw-cursor-pointer tw-mt-1 tw-align-top"
                    tooltip={rLIB('Edit')}
                    tooltipPlacement="right"
                    onClick={() => {
                      openDetailsEditDialog(
                        formInputs_WarehouseDistance,
                        uc_setUserInterface_FormDialogDisplay,
                        us_rootProject,
                        uc_RootData_ClientKey,
                        uc_setRootData_ClientKey,
                        pr_projectKey,
                        uc_setUserInterface_ErrorDialogDisplay,
                      )
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Sales Partner
  const rJSX_SalesPartnerLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Sales Partner'),
          'building',
          us_rootProject,
          'associated_sales_partner_key',
          'associated_sales_partner_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Sales Partner') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the sales partner on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_sales_partner_key: selectedOption.key,
                              associated_sales_partner_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to archive order'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
        )}
      </Box>
    )
  }

  const rJSX_SalesPartnerTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sales Partner')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedSalesPartnerDetails, us_setMinimizedSalesPartnerDetails, 'us_minimizedSalesPartnerDetails')}
        </Typography>
        {us_minimizedSalesPartnerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedSalesPartnerDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedSalesPartnerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_light }}
              >
                {rLIB('Sales Partner')}
              </Typography>
              {rJSX_SalesPartnerLineItem()}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_light }}
              >
                {rLIB('Sales Rep')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Name'), 'location-dot', us_rootProject, 'associated_sales_rep_name', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Phone'), 'phone', us_rootProject, 'associated_sales_rep_phone', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Email'), 'envelope', us_rootProject, 'associated_sales_rep_email', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Team'), 'people-group', us_rootProject, 'associated_sales_rep_team_name', 'rootProject')}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Financing
  const rJSX_FinancePartnerLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Finance Partner'),
          'credit-card',
          us_rootProject,
          'associated_financing_partner_key',
          'associated_financing_partner_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveFinancePartners_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Finance Partner') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the finance partner on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_financing_partner_key: selectedOption.key,
                              associated_financing_partner_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to archive order'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
        )}
      </Box>
    )
  }

  const rJSX_FinancingTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Financing')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedFinancingDetails, us_setMinimizedFinancingDetails, 'us_minimizedFinancingDetails')}
        </Typography>
        {us_minimizedFinancingDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedFinancingDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedFinancingDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_dark }}
              >
                {rLIB('Finance Partner')}
              </Typography>
              {rJSX_FinancePartnerLineItem()}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_dark }}
              >
                {rLIB('Financing Terms')}
              </Typography>
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Financing Type'),
                'credit-card',
                us_rootProject,
                'financials_financing_type',
                'rootProject',
                objectToArray([
                  { key: 'Cash', value: 'Cash' },
                  { key: 'Lease', value: 'Lease' },
                  { key: 'Loan', value: 'Loan' },
                ]) as unknown as tsI_MultipleChoiceOption[],
              )}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - System
  const rJSX_SystemTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('System')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedSystemDetails, us_setMinimizedSystemDetails, 'us_minimizedSystemDetails')}
        </Typography>
        {us_minimizedSystemDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedSystemDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedSystemDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Type')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Product Package'), 'cart-shopping', us_rootProject, 'associated_product_name', 'rootProject')}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Components')}
              </Typography>
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Panel Quantity'), 'solar-panel', us_rootProject, 'system_panel_quantity', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Panel Manufacturer'), 'solar-panel', us_rootProject, 'system_panel_manufacturer', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Panel Model'), 'solar-panel', us_rootProject, 'system_panel_model', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Inverter Quantity'), 'solar-panel', us_rootProject, 'system_inverter_quantity', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Inverter Manufacturer'), 'solar-panel', us_rootProject, 'system_inverter_manufacturer', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Inverter Model'), 'solar-panel', us_rootProject, 'system_inverter_model', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Storage Quantity'), 'solar-panel', us_rootProject, 'system_storage_quantity', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Storage Manufacturer'), 'solar-panel', us_rootProject, 'system_storage_manufacturer', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Storage Model'), 'solar-panel', us_rootProject, 'system_storage_model', 'rootProject')}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Stats')}
              </Typography>
              {rJSX_DirectlyEditableNumberLineItem(rLIB('System Size'), 'solar-panel', us_rootProject, 'system_size_dc', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Est Annual Production'),
                'solar-panel',
                us_rootProject,
                'system_estimated_annual_production',
                'rootProject',
              )}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Usage Offset'), 'solar-panel', us_rootProject, 'system_usage_offset', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Total Storage (KWH)'), 'solar-panel', us_rootProject, 'system_storage_total_kwh', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Number of Arrays'), 'solar-panel', us_rootProject, 'system_number_of_arrays', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Number of Strings'), 'solar-panel', us_rootProject, 'system_number_of_strings', 'rootProject')}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('Other')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Meter Type'), 'solar-panel', us_additionalProjectData, 'system_meter_type', 'additionalProjectData')}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Attic Run'),
                'solar-panel',
                us_rootProject,
                'system_has_attic_run',
                'rootProject',
                objectToArray([
                  { key: 'yes', value: 'yes' },
                  { key: 'no', value: 'no' },
                ]) as unknown as tsI_MultipleChoiceOption[],
              )}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Home Details
  const rJSX_HomeDetailsTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Home Details')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedHomeDetails, us_setMinimizedHomeDetails, 'us_minimizedHomeDetails')}
        </Typography>
        {us_minimizedHomeDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedHomeDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedHomeDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.error_main }}
              >
                {rLIB('Home Characteristics')}
              </Typography>
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Roof Type'),
                'home',
                us_additionalProjectData,
                'home_roof_type',
                'additionalProjectData',
                objectToArray(roofTypeOptions) as unknown as tsI_MultipleChoiceOption[],
              )}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Max Roof Pitch'), 'home', us_rootProject, 'system_max_roof_pitch', 'rootProject')}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Building Type'),
                'home',
                us_additionalProjectData,
                'home_building_type',
                'additionalProjectData',
                objectToArray(homeBuildingTypeOptions) as unknown as tsI_MultipleChoiceOption[],
              )}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Roof Age'), 'home', us_additionalProjectData, 'home_roof_age', 'additionalProjectData')}
              {rJSX_DirectlyEditableTextLineItem(
                rLIB('Installation Type'),
                'home',
                us_additionalProjectData,
                'home_installation_type',
                'additionalProjectData',
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Shading Type'), 'home', us_additionalProjectData, 'home_shading_type', 'additionalProjectData')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Square Footage'), 'home', us_additionalProjectData, 'home_square_footage', 'additionalProjectData')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Year Built'), 'home', us_additionalProjectData, 'home_year_built', 'additionalProjectData')}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Number of Stories'),
                'home',
                us_additionalProjectData,
                'home_number_of_stories',
                'additionalProjectData',
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.error_main }}
              >
                {rLIB('Entities')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('HOA'), 'home', us_rootProject, 'associated_hoa_name', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Utility Company'), 'utility-pole', us_rootProject, 'associated_utility_company_name', 'rootProject')}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Customer Feedback
  const rJSX_CustomerFeedbackTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Feedback')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedCustomerFeedback, us_setMinimizedCustomerFeedback, 'us_minimizedCustomerFeedback')}
        </Typography>
        {us_minimizedCustomerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedCustomerFeedback(false)
                setPageLocalStorage(tabKey, 'us_minimizedCustomerFeedback', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Box>{rJSX_FeedbackDetails()}</Box>
        )}
      </Box>
    )
  }

  const rJSX_FeedbackDetails = (): JSX.Element => {
    let feedbackDetailsJSX = <></>
    if (us_projectFeedback != null && objectToArray(us_projectFeedback).length > 0) {
      feedbackDetailsJSX = (
        <Box>
          {/* <FeedbackNpsGraph
            feedbackData={ projectFeedback }
            feedbackSettings={ feedbackGraphSettings }
          /> */}
          <FeedbackNpsComments
            feedbackData={us_projectFeedback}
            feedbackSettings={{
              sort_order: 'timestamp_created',
              mapping: {
                rating: 'feedback_rating',
                name: 'associated_user_name',
                text: 'feedback_notes',
                date: 'timestamp_created',
                chips: {
                  feedback_topic: {
                    key: 'feedback_topic',
                    // color: "inherit"
                  },
                },
              },
              display_buttons: {
                associated_project_key: false,
              },
            }}
          />
        </Box>
      )
    } else {
      feedbackDetailsJSX = (
        <Card className="tw-p-2">
          <Box className="tw-text-center">
            <Typography
              variant="subtitle1"
              className="tw-opacity-30 tw-italic"
            >
              {rLIB('No feedback for project')}
            </Typography>
          </Box>
        </Card>
      )
    }
    return feedbackDetailsJSX
  }

  // JSX - Last Contact
  const rJSX_LastContactTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Last Contact')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedLastContact, us_setMinimizedLastContact, 'us_minimizedLastContact')}
        </Typography>
        {us_minimizedLastContact ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedLastContact(false)
                setPageLocalStorage(tabKey, 'us_minimizedLastContact', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box className="">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.warning_main }}
              >
                {rLIB('Last Contact')}
              </Typography>
              {rJSX_NonEditableDateLineItem(rLIB('Last Contact Date'), 'calendar-days', us_rootProject, 'timestamp_last_contact_log')}
              {rJSX_NonEditableTextLineItem(rLIB('Last Contactor'), 'user', us_rootProject, 'last_contact_log_person')}
              {rJSX_NonEditableTextLineItem(rLIB('Last Contact Method'), 'mobile-screen', us_rootProject, 'last_contact_log_type')}
              {rJSX_NonEditableTextLineItem(rLIB('Notes'), 'comment', us_rootProject, 'last_contact_log_notes')}
            </Box>

            {/* <ReactTimeAgo date={returnDateFromUnknownDateFormat(rootProject.timestamp_last_contact_log)} /> */}
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Timeline
  const rJSX_InPhaseDays = (actualDays: number, estimatedDays: number, extensionDays: number, status: string): JSX.Element => {
    let phaseDaysJSX: JSX.Element = <></>
    if (actualDays == null) {
      actualDays = 0
    }
    if (estimatedDays == null) {
      estimatedDays = 0
    }
    if (extensionDays == null) {
      extensionDays = 0
    }
    let color = themeVariables.gray_600
    if (actualDays < estimatedDays) {
      color = themeVariables.success_main
    } else if (actualDays < estimatedDays + extensionDays) {
      color = themeVariables.warning_main
    } else {
      color = themeVariables.error_main
    }
    let textColor = themeVariables.white
    let boxType = 'contained'
    if (status === 'complete') {
      boxType = 'contained'
    } else if (status === 'in_progress') {
      boxType = 'outlined'
    } else if (status === 'not_started') {
      boxType = 'outlined'
      color = themeVariables.gray_600
      textColor = themeVariables.gray_600
    }
    if (boxType === 'contained') {
      if (extensionDays > 0) {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md "
            sx={{ backgroundColor: color, color: textColor }}
          >
            {actualDays} /{' '}
            <Box
              component="span"
              className="tw-italic tw-line-through tw-opacity-50"
            >
              {estimatedDays}
            </Box>{' '}
            {estimatedDays + extensionDays} {estimatedDays + extensionDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      } else {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md "
            sx={{ backgroundColor: color, color: textColor }}
          >
            {actualDays} / {estimatedDays} {estimatedDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      }
    } else {
      if (extensionDays > 0) {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md"
            sx={{ border: `1px solid ${color}`, color: textColor }}
          >
            {actualDays} /{' '}
            <Box
              component="span"
              className="tw-italic tw-line-through tw-opacity-50"
            >
              {estimatedDays}
            </Box>{' '}
            {estimatedDays + extensionDays} {estimatedDays + extensionDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      } else {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md"
            sx={{ border: `1px solid ${color}`, color: textColor }}
          >
            {actualDays} / {estimatedDays} {estimatedDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      }
    }

    // phaseDaysJSX = <Box className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-gray_700">{estimatedDays}</Box>

    return phaseDaysJSX
  }

  const rJSX_PhaseDate = (phaseDate: number, phaseDateType: string, phaseDateOriginal: number | null, typeOverride: string | null): JSX.Element => {
    let phaseStartDateJSX: JSX.Element = <></>
    if (typeOverride === 'sticky_note') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-secondary_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="note"
            className="tw-ml-2"
            tooltip={rLIB('Sticky Note')}
          />
        </Box>
      )
    } else if (phaseDateType === 'actual') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-check"
            className="tw-ml-2"
            tooltip={rLIB('Actual Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'estimated') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-ml-2 tw-text-info_main"
            tooltip={rLIB('Estimated Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'estimated_delayed') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-ml-2 tw-text-info_main"
            tooltip={rLIB('Estimated Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'corrected_actual') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-warning_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {phaseDateOriginal != null ? (
            <>
              <Box className="tw-inline-block tw-mr-2">{returnFormattedDate(phaseDateOriginal, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          {phaseDate != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1 tw-line-through tw-italic tw-opacity-50">{returnFormattedDate(phaseDate, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          <Icon
            icon="circle-info"
            className=" tw-ml-2"
            tooltip={rLIB('Milestone Completed Out of Order')}
          />
        </Box>
      )
    } else if (phaseDateType === 'range') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-gray_600"
          sx={{ minWidth: '110px', textAlign: 'center', color: themeVariables.gray_600 }}
        >
          {phaseDate != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1">{returnFormattedDate(phaseDate, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          {phaseDate != null && phaseDateOriginal != null ? <>{'- '}</> : <></>}
          {phaseDateOriginal != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1">{returnFormattedDate(phaseDateOriginal, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          <Icon
            icon="circle-info"
            className=" tw-ml-2"
            tooltip={rLIB('Estimated Date Range')}
          />
        </Box>
      )
    } else if (phaseDateType === 'corrected_estimated') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-warning_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-text-warning_main tw-ml-2"
            tooltip={rLIB('Milestone Completed Out of Order')}
          />
        </Box>
      )
    }
    return phaseStartDateJSX
  }

  const rJSX_TimelineTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Timeline')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedEstimatedTimeline, us_setMinimizedEstimatedTimeline, 'us_minimizedEstimatedTimeline')}
        </Typography>
        {us_minimizedEstimatedTimeline ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedEstimatedTimeline(false)
                setPageLocalStorage(tabKey, 'us_minimizedEstimatedTimeline', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Box className="">
            {us_sortedMilestonePhaseTasksArray.map((milestoneTask: TsInterface_UnspecifiedObject, index: number) => (
              <Card
                key={index}
                className="tw-mb-1 tw-p-2"
                sx={{ borderLeft: `8px solid ${returnMilestoneColorsAndIcons(us_milestoneTimelineDates[milestoneTask.key]).milestoneColor}` }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  className="tw-mb-0"
                >
                  <Typography
                    variant="body1"
                    className="tw-mb-1"
                    sx={{ fontWeight: 900, fontSize: '18px' }}
                  >
                    {returnMilestoneColorsAndIcons(us_milestoneTimelineDates[milestoneTask.key]).milestoneStatusIconJSX}
                    {milestoneTask.name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className="tw-mb-0"
                >
                  {rJSX_PhaseDate(
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date_type,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date_original,
                    null,
                  )}
                  <Typography className="tw-mt-1">{rLIB('to')}</Typography>
                  {rJSX_PhaseDate(
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date_type,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date_original,
                    null,
                  )}
                  {rJSX_InPhaseDays(
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).actual_days,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).expected_days,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).extension_days,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).status,
                  )}
                </Stack>
              </Card>
            ))}
          </Box>
        )}
      </Box>
    )
  }

  // JSX - Sticky Notes
  const rJSX_EditStickyNoteIcon = (
    readOrWrite: 'read' | 'write',
    note: TsInterface_UnspecifiedObject,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    uc_setUserInterface_FormDialogDisplay: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    projectKey: any,
  ) => {
    let editIconJSX = <></>
    if (readOrWrite === 'write') {
      editIconJSX = (
        <Tooltip title={rLIB('Edit Sticky Note')}>
          <Box
            className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      note: note.note,
                      date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
                    },
                    formInputs: formInputs_NewStickyNote,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: formSettings_StickyNote,
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject = {
                          sticky_notes: {
                            [note.key]: {
                              note: formSubmittedData.note,
                              timestamp: returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)),
                            },
                          },
                        }
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'warning',
                    formDialogHeaderText: rLIB('Edit Sticky Note'),
                    formDialogIcon: (
                      <Icon
                        icon="note"
                        type="solid"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square"></Icon>
          </Box>
        </Tooltip>
      )
    }
    return editIconJSX
  }

  const rJSX_DeleteStickyNoteIcon = (
    readOrWrite: 'read' | 'write',
    noteKey: any,
    note: TsInterface_UnspecifiedObject,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    uc_setUserInterface_ConfirmDialogDisplay: any,
    projectKey: any,
  ) => {
    let deleteIconJSX = <></>
    if (readOrWrite === 'write') {
      deleteIconJSX = (
        <Tooltip title={rLIB('Delete Sticky Note')}>
          <Box className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2">
            <Icon
              icon="trash"
              onClick={() => {
                uc_setUserInterface_ConfirmDialogDisplay({
                  display: true,
                  confirm: {
                    color: 'error',
                    header: <>{rLIB('Delete Sticky Note')}</>,
                    icon: <Icon icon="trash" />,
                    submit_callback: () => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              sticky_notes: {
                                [noteKey]: null,
                              },
                              sticky_notes_deleted: {
                                [noteKey]: note,
                              },
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                    submit_text: rLIB('Delete'),
                    text: rLIB('Are you sure that you want to delete this sticky note?'),
                  },
                })
              }}
            />
          </Box>
        </Tooltip>
      )
    }
    return deleteIconJSX
  }

  const rJSX_StickyNotesTile = (): JSX.Element => {
    let stickyNoteContent = <></>
    let newNoteButtonJSX = <></>
    if (pr_readOrWrite === 'write') {
      newNoteButtonJSX = (
        <Icon
          icon="circle-plus"
          type="solid"
          className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
          tooltip={rLIB('New Sticky Note')}
          tooltipPlacement="top"
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: {
                    date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
                  },
                  formInputs: formInputs_NewStickyNote,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: formSettings_StickyNote,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      let newNoteId = uuidv4()
                      let updateObject = {
                        sticky_notes: {
                          [newNoteId]: {
                            timestamp: returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)),
                            note: formSubmittedData.note,
                            key: newNoteId,
                          },
                        },
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'warning',
                  formDialogHeaderText: rLIB('Add Sticky Note'),
                  formDialogIcon: (
                    <Icon
                      icon="note"
                      type="solid"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
    }
    let stickyNotes = getProp(us_rootProject, 'sticky_notes', {})
    if (objectToArray(stickyNotes).length > 0) {
      stickyNoteContent = (
        <Box>
          {objectToArray(stickyNotes)
            .sort(dynamicSort('timestamp', 'desc'))
            .map((stickyNote: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Icon
                  icon="note"
                  className="tw-mr-1"
                  sx={{ color: themeVariables.secondary_main }}
                />
                <Box
                  component="span"
                  className="tw-inline-block tw-mr-1"
                  sx={{ color: themeVariables.secondary_main }}
                >
                  {returnFormattedDate(stickyNote.timestamp, 'M/D/YY')}
                </Box>
                {stickyNote.note}
                {rJSX_EditStickyNoteIcon(
                  pr_readOrWrite,
                  stickyNote,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_setUserInterface_FormDialogDisplay,
                  uc_setUserInterface_ErrorDialogDisplay,
                  pr_projectKey,
                )}
                {rJSX_DeleteStickyNoteIcon(
                  pr_readOrWrite,
                  stickyNote.key,
                  stickyNote,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_setUserInterface_ErrorDialogDisplay,
                  uc_setUserInterface_ConfirmDialogDisplay,
                  pr_projectKey,
                )}
              </Box>
            ))}
        </Box>
      )
    } else {
      stickyNoteContent = (
        <Box>
          <Typography className="tw-italic tw-opacity-50 tw-inline-block">
            <Box
              className="tw-inline-block"
              component="span"
            >
              {rLIB('No Sticky Notes')}
            </Box>
          </Typography>
        </Box>
      )
    }
    // Return JSX
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sticky Notes')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedStickyNotes, us_setMinimizedStickyNotes, 'us_minimizedStickyNotes')}
          {us_minimizedStickyNotes ? <></> : newNoteButtonJSX}
        </Typography>
        {us_minimizedStickyNotes ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedStickyNotes(false)
                setPageLocalStorage(tabKey, 'us_minimizedStickyNotes', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">{stickyNoteContent}</Card>
        )}
      </Box>
    )
  }

  // JSX - Field Tasks
  const rJSX_ProjectTasksGrid = (): JSX.Element => {
    const taskArray = us_projectTasks
      ? Object.values(us_projectTasks).map((task) => ({
          ...task,
          team_members: task.task_completion_scheduled_team_names ? getUniqueTeamMembers(task.task_completion_scheduled_team_names) : [],
          last_scheduled_date: Array.isArray(task.task_completion_scheduled_dates)
            ? task.task_completion_scheduled_dates[task.task_completion_scheduled_dates.length - 1]
              ? dayjs(new Date(task.task_completion_scheduled_dates[task.task_completion_scheduled_dates.length - 1])).format('DD MMM YYYY')
              : 'N/A'
            : 'N/A',
          completed_date: task.timestamp_completed ? dayjs(task.timestamp_completed.seconds * 1000).format('DD MMM YYYY') : 'N/A',
        }))
      : []
    const filteredTasks = taskArray.filter((task) => task.associated_team_name)
    const sortedTasks = filteredTasks.sort((a, b) => {
      const dateA = a.last_scheduled_date === 'N/A' ? 0 : dayjs(a.last_scheduled_date, 'DD MMM YYYY').valueOf()
      const dateB = b.last_scheduled_date === 'N/A' ? 0 : dayjs(b.last_scheduled_date, 'DD MMM YYYY').valueOf()
      return dateA - dateB
    })

    return (
      <Box>
        <Typography variant="h6">
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Field Tasks')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedProjectTasks, us_setMinimizedProjectTasks, 'us_minimizedProjectTasks')}
        </Typography>
        {us_minimizedProjectTasks ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedProjectTasks(false)
                setPageLocalStorage(tabKey, 'us_minimizedProjectTasks', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Box>
            {filteredTasks.length === 0 ? (
              <Card className="tw-p-2 tw-text-center">
                <Typography>
                  <Icon
                    icon="circle-info"
                    className="tw-mr-2 tw-text-info_main"
                  />
                  <Box
                    component="span"
                    className="tw-italic tw-opacity-30"
                  >
                    {rLIB('No completed field tasks with team members assigned.')}
                  </Box>
                </Typography>
              </Card>
            ) : (
              <Box>
                {sortedTasks.map((task, index) => (
                  <Card
                    key={index}
                    className="tw-p-2 tw-mb-1 tw-rounded-md"
                    sx={{
                      boxShadow: 'none',
                      width: '100%',
                      maxWidth: '800px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      className="tw-font-semibold"
                    >
                      {task.name || 'N/A'}
                    </Typography>

                    <Grid2 container>
                      <Grid2
                        xs={12}
                        sm={4}
                      >
                        <Typography
                          variant="subtitle2"
                          className="tw-font-semibold"
                        >
                          {rLIB('Team Members')}
                        </Typography>
                        <Box className="tw-flex tw-flex-wrap tw-gap-1">
                          {task.team_members.length > 0 ? (
                            task.team_members.map((member: string, idx: number) => (
                              <Chip
                                key={idx}
                                label={member}
                                size="small"
                                className="tw-mr-1 tw-mb-1"
                                sx={{
                                  height: '24px',
                                  fontWeight: 500,
                                }}
                              />
                            ))
                          ) : (
                            <Typography
                              variant="body2"
                              className="tw-opacity-50"
                            >
                              {rLIB('No team members assigned.')}
                            </Typography>
                          )}
                        </Box>
                      </Grid2>
                      <Grid2
                        xs={12}
                        sm={8}
                      >
                        <Box>
                          <Grid2
                            container
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className="tw-font-semibold"
                              >
                                {rLIB('Status')}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="tw-capitalize"
                                sx={{
                                  color:
                                    task.status === 'completed'
                                      ? themeVariables.success_main
                                      : task.status === 'assigned'
                                        ? themeVariables.warning_main
                                        : 'inherit',
                                }}
                              >
                                {task.status || 'N/A'}
                              </Typography>
                            </Grid2>
                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className="tw-font-semibold"
                              >
                                {rLIB('Team Name')}
                              </Typography>
                              <Typography variant="body2">{task.associated_team_name || 'N/A'}</Typography>
                            </Grid2>

                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className={`tw-font-semibold ${task.last_scheduled_date === 'N/A' ? 'tw-opacity-50' : ''}`}
                                sx={{ color: task.last_scheduled_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {rLIB('Scheduled Date')}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: task.last_scheduled_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {task.last_scheduled_date}
                              </Typography>
                            </Grid2>
                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className={`tw-font-semibold ${task.completed_date === 'N/A' ? 'tw-opacity-50' : ''}`}
                                sx={{ color: task.completed_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {rLIB('Completed Date')}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: task.completed_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {task.completed_date}
                              </Typography>
                            </Grid2>
                          </Grid2>
                        </Box>
                      </Grid2>
                    </Grid2>
                  </Card>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    )
  }

  // JSX - Full Page
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3 }}
          spacing={2}
        >
          {rJSX_DetailsTile()}
          {rJSX_SalesPartnerTile()}
          {rJSX_FinancingTile()}
          {rJSX_SystemTile()}
          {rJSX_HomeDetailsTile()}
          {rJSX_LastContactTile()}
          {rJSX_StickyNotesTile()}
          {rJSX_ProjectTasksGrid()}
          {rJSX_CustomerFeedbackTile()}
          {rJSX_TimelineTile()}
        </Masonry>
      </Box>
    )

    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
