///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { tableColumns_SalesUser, tableSettings_SalesUsers } from 'app/models/users/user_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientUserRoles, returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesDatabaseUserListPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  const [us_clientUserRoles, us_setClientUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Users', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setClientUserRoles(returnClientUserRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Functions
  const tableDatabaseEndpoint_User = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]

    // // Apply sales partner filter if it's not set to ALL_SALES_PARTNERS
    // if (us_salesPartnerFilter !== 'ALL_SALES_PARTNERS') {
    //   queryOperatorsArray.push({ prop: 'associated_sales_partner_key', comparator: '==', value: us_salesPartnerFilter })
    // }

    // // Apply task role filter if enabled
    // if (us_showAllWithTaskRole === true && tableAdditionalData != null && tableAdditionalData.user_role != null) {
    //   orderByArray = []
    //   queryOperatorsArray.push({ prop: 'task_roles.' + tableAdditionalData.user_role, comparator: '==', value: true })
    // } else {
    //   // Apply status filter (active/deleted)
    if (tableAdditionalData != null && tableAdditionalData.user_status != null) {
      queryOperatorsArray.push({ prop: 'status', comparator: '==', value: tableAdditionalData.user_status })
    }

    //   // Handle different filters for user type
    //   if (us_userTableFilter === 'ALL_USERS') {
    //     // No need to filter by organization type
    //   } else if (us_userTableFilter === 'ALL_INTERNAL_USERS') {
    //     // Filter only internal users
    //     queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: 'internal' })
    //   } else if (us_userTableFilter === 'ALL_EXTERNAL_USERS') {
    //     // Filter only external users
    //     queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: 'external' })
    //   } else if (tableAdditionalData != null && tableAdditionalData.user_role != null) {
    //     // Filter by user role
    //     queryOperatorsArray.push({ prop: 'user_role', comparator: '==', value: tableAdditionalData.user_role })
    //   }
    // }

    // Handle pagination cursors (startAt, startAfter, endAt, endBefore)
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }

    // Apply limit for pagination
    let limit = getProp(queryGenerationData, 'limit', 5)

    // Generate and return the Firestore query
    return generateDatabaseQuery(DatabaseRef_Users_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // JSX Generation
  const rJSX_NewUserButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesDatabaseUserNewPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="square-plus" />}
        className="tw-mr-2"
      >
        {rLIB('New User')}
      </Button>
    )
  }

  const rJSX_Table = (): JSX.Element => {
    if (uc_RootData_ClientKey != null) {
      let tableJSX = <></>

      let userStatus = 'active'

      let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
        // associated_organization_type: organizationType,
        task_roles: ClientUserRoles,
        // client_type: uc_RootData_ClientPermissions.client_type,
        // sales_partners: us_salesPartnersList,
        user_status: userStatus,
        // uc_RootData_GlobalUser,
        // handleOpenDialog, // For welcome email dialog with preview
        // handleOpenResetDialog, // For password reset dialog
        // handleCloseDialog, // For closing dialogs
        // handleCloseResetDialog, // For closing reset dialog
      }

      tableJSX = (
        <Card className="tw-mt-4">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_AllUsers}
            tableColumns={tableColumns_SalesUser}
            tableDatabaseEndpoint={tableDatabaseEndpoint_User}
            tableSettings={tableSettings_SalesUsers}
          />
        </Card>
      )

      return tableJSX
    }
    return <></>
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Users')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_NewUserButton()}</Box>
            {rJSX_Table()}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
