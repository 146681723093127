///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import { useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { ProjectViewCalendarTab } from '../projects/v2_view_tabs/tab_calendar'
import { ProjectViewDetailsTab } from '../projects/v2_view_tabs/tab_details'
import { ProjectViewDocumentsTab } from '../projects/v2_view_tabs/tab_documents'
import { ProjectViewPhotosTab } from '../projects/v2_view_tabs/tab_photos'
import { ProjectViewTasksTab } from '../projects/v2_view_tabs/tab_tasks'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesInstallPartnerProjectViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_clientKey: string = params.cid as string
  const pr_projectKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Project', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      // TODO - TEMP
      if (newData != null && newData['task_completion_stats'] != null && newData['task_completion_stats']['unknown'] != null) {
        cloudFunctionManageRequest('manageTasks', {
          function: 'refreshProjectTaskProgressBar',
          client_key: pr_clientKey,
          project_key: pr_projectKey,
        })
        ur_forceRerender()
      }
      ur_forceRerender()
    }

    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(pr_clientKey, pr_projectKey), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, pr_clientKey, pr_projectKey])

  // Functions
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    let jobCodeJSX = <></>
    if (us_rootProject != null && us_rootProject.id_number != null) {
      jobCodeJSX = <>{us_rootProject.id_number}</>
    } else {
      jobCodeJSX = <>{rLIB('Project')}</>
    }
    if (us_rootProject != null && us_rootProject.associated_customer_name != null) {
      pageHeader = (
        <>
          {jobCodeJSX}: {us_rootProject.associated_customer_name}
        </>
      )
    } else {
      pageHeader = <>{jobCodeJSX}</>
    }
    return pageHeader
  }

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="chevron-left"></Icon>}
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesInstallPartnerProjectsIndexPage.url())
                }}
              >
                {rLIB('Back to all active projects')}
              </Button>
            </Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Details',
                  tabHeader: rLIB('Details'),
                  tabOnChange: () => {},
                  tabContent: (
                    <Box className="tw-m-auto">
                      <ProjectViewDetailsTab
                        projectKey={pr_projectKey}
                        clientKeyOverride={pr_clientKey}
                        readOrWrite={'read'}
                      />
                    </Box>
                  ),
                },
                // {
                //   tabUrlKey: 'Team',
                //   tabHeader: rLIB('Team'),
                //   tabOnChange: () => {},
                //   tabContent: (
                //     <Box className="tw-m-auto">
                //       <ProjectViewTeamTab
                //         projectKey={pr_projectKey}
                //         clientKeyOverride={pr_clientKey}
                //         readOrWrite={'read'}
                //       />
                //     </Box>
                //   ),
                // },
                {
                  tabUrlKey: 'Tasks',
                  tabHeader: rLIB('Tasks'),
                  tabOnChange: () => {},
                  tabContent: (
                    <Box className="tw-m-auto">
                      <ProjectViewTasksTab
                        projectKey={pr_projectKey}
                        clientKeyOverride={pr_clientKey}
                        readOrWrite={'read'}
                        levelOfAccess={'sales'}
                      />
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'calendar',
                  tabHeader: rLIB('Calendar'),
                  tabOnChange: () => {},
                  tabContent: (
                    <Box className="tw-m-auto">
                      <ProjectViewCalendarTab
                        projectKey={pr_projectKey}
                        clientKeyOverride={pr_clientKey}
                        readOrWrite={'read'}
                      />
                    </Box>
                  ),
                },
                // {
                //   tabUrlKey: 'Contact_Log',
                //   tabHeader: rJSX_ContactLogsTabHeader(us_rootProject),
                //   tabOnChange: () => {},
                //   tabContent: (
                //     <Box className="tw-m-auto">
                //       <ProjectViewContactLogsTab
                //         projectKey={pr_projectKey}
                //         clientKeyOverride={pr_clientKey}
                //         readOrWrite={'read'}
                //       />
                //     </Box>
                //   ),
                // },
                {
                  tabUrlKey: 'Documents',
                  tabHeader: rLIB('Documents'),
                  tabOnChange: () => {},
                  tabContent: (
                    <Box className="tw-m-auto">
                      <ProjectViewDocumentsTab
                        projectKey={pr_projectKey}
                        clientKeyOverride={pr_clientKey}
                        readOrWrite={'read'}
                      />
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'Photos',
                  tabHeader: rLIB('Photos'),
                  tabOnChange: () => {},
                  tabContent: (
                    <Box className="tw-m-auto">
                      <ProjectViewPhotosTab
                        projectKey={pr_projectKey}
                        clientKeyOverride={pr_clientKey}
                        readOrWrite={'read'}
                      />
                    </Box>
                  ),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SalesInstallPartnerProjectViewPage.url(pr_clientKey, pr_projectKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: getProp(us_rootProject, 'id_number', 'Project'),
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
