/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////

import { returnTaskRows } from 'app/models/tasks'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateCorrectedForTimezoneOffset,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const returnProjectPhaseDeadlineData = (
  project: TsInterface_UnspecifiedObject,
  milestoneKey: string,
  taskWorkflow: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  // let currentDate = new Date()
  let projectPhaseDeadlineData: TsInterface_UnspecifiedObject = {
    // phase_current_days
    phase_expected_days: null,
    phase_extension_days: 0,
    // phase_original_expected_completion_date
    // phase_current_expected_completion_date
    // phase_actual_completion_date
  }
  if (
    project != null &&
    milestoneKey != null &&
    taskWorkflow != null &&
    taskWorkflow['filter_milestone_days'] != null &&
    taskWorkflow['filter_milestone_days'][milestoneKey] != null
  ) {
    projectPhaseDeadlineData['phase_expected_days'] = taskWorkflow['filter_milestone_days'][milestoneKey]
  }
  if (project != null && project['phase_deadline_extensions'] != null && project['phase_deadline_extensions'][milestoneKey] != null) {
    for (let loopExtensionKey in project['phase_deadline_extensions'][milestoneKey]) {
      let loopExtension = project['phase_deadline_extensions'][milestoneKey][loopExtensionKey]
      if (loopExtension != null && loopExtension['extension_days'] != null) {
        projectPhaseDeadlineData['phase_extension_days'] += loopExtension['extension_days']
      }
    }
  }
  return projectPhaseDeadlineData
}

const returnProjectCurrentMilestoneKey = (sortedWorkflowPhasesArray: TsInterface_UnspecifiedObject[], project: TsInterface_UnspecifiedObject): string => {
  let firstMilestoneTaskKey = sortedWorkflowPhasesArray[0].key
  let lastMilestoneTaskKey = sortedWorkflowPhasesArray[sortedWorkflowPhasesArray.length - 1].key
  let projectLeastFarMilestoneIndex = sortedWorkflowPhasesArray.length
  let projectLeastFarMilestoneKey = null
  if (project != null && project.task_statuses != null) {
    let foundMilestoneLocation = false
    let allMilestonesCompleted = true
    for (let loopMilestoneTaskIndex in sortedWorkflowPhasesArray) {
      let loopMilestoneTask = getProp(sortedWorkflowPhasesArray, loopMilestoneTaskIndex, {})
      let loopMilestoneTaskKey = getProp(loopMilestoneTask, 'key', '')
      if (project != null && project['task_statuses'] != null && project['task_statuses'][loopMilestoneTaskKey] !== true) {
        if (parseInt(loopMilestoneTaskIndex) <= projectLeastFarMilestoneIndex) {
          projectLeastFarMilestoneIndex = parseInt(loopMilestoneTaskIndex)
          projectLeastFarMilestoneKey = loopMilestoneTaskKey
          foundMilestoneLocation = true
        }
      }
      if (project != null && project['task_statuses'] != null && project['task_statuses'][loopMilestoneTaskKey] === true) {
        // Milestone Completed
      } else {
        allMilestonesCompleted = false
      }
    }
    if (foundMilestoneLocation === true && projectLeastFarMilestoneKey != null) {
      return projectLeastFarMilestoneKey
      // projectsSortedByMilestone[projectLeastFarMilestoneKey][loopProjectKey] = activeProjects[loopProjectKey]
    } else if (allMilestonesCompleted === true) {
      return lastMilestoneTaskKey
      // projectsSortedByMilestone[lastMilestoneTaskKey][loopProjectKey] = activeProjects[loopProjectKey]
    } else {
      return firstMilestoneTaskKey
      // projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = activeProjects[loopProjectKey]
    }
  } else {
    return firstMilestoneTaskKey
    // projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = activeProjects[loopProjectKey]
  }
}

const returnCalculatedPhaseDaysDataForProject = (
  sortedWorkflowPhasesArray: TsInterface_UnspecifiedObject[],
  project: TsInterface_UnspecifiedObject,
  projectTimestamps: TsInterface_UnspecifiedObject,
  milestoneKey: string,
  taskWorkflow: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  let projectPhaseDayData: TsInterface_UnspecifiedObject = {
    phase_expected_days: null,
    phase_extension_days: null,
    days_in_phase: null,
    last_milestone_timestamp: null,
    estimated_phase_completion_timestamp: null,
  }
  let currentDate = new Date()
  let loopProjectDaysInPhase: number | null = null
  let lastMilestoneTimestamp: number | null = null
  // Get the index of the milestone
  let milestoneIndex = sortedWorkflowPhasesArray.findIndex((milestone) => milestone.key === milestoneKey)
  if (projectTimestamps != null && objectToArray(projectTimestamps).length > 0) {
    if (milestoneIndex > 1) {
      // Get the index of the previous milestone
      let previousMilestoneIndex = milestoneIndex - 1
      let previousMilestoneKey = sortedWorkflowPhasesArray[previousMilestoneIndex].key
      // Use the last updated date of the previous milestone
      if (
        projectTimestamps != null &&
        projectTimestamps['task_completion_timestamps'] != null &&
        projectTimestamps['key'] != null &&
        projectTimestamps['task_completion_timestamps'][projectTimestamps['key'] + '_' + previousMilestoneKey] != null
      ) {
        let previousMilestoneDate = returnTimestampFromUnknownDateFormat(
          projectTimestamps['task_completion_timestamps'][projectTimestamps['key'] + '_' + previousMilestoneKey],
        )
        if (previousMilestoneDate != null) {
          loopProjectDaysInPhase = Math.floor((currentDate.getTime() - previousMilestoneDate) / 86400000)
          lastMilestoneTimestamp = previousMilestoneDate
        }
      }
    }
    if (milestoneIndex === -1 || milestoneIndex === 0 || loopProjectDaysInPhase == null) {
      // Use Oldest Task Date
      let oldestTaskDate = null
      if (projectTimestamps != null && projectTimestamps['task_completion_timestamps'] != null) {
        for (let loopTaskKey in projectTimestamps['task_completion_timestamps']) {
          if (
            oldestTaskDate == null ||
            (projectTimestamps['task_completion_timestamps'][loopTaskKey] != null &&
              returnTimestampFromUnknownDateFormat(projectTimestamps['task_completion_timestamps'][loopTaskKey]) < oldestTaskDate)
          ) {
            oldestTaskDate = returnTimestampFromUnknownDateFormat(projectTimestamps['task_completion_timestamps'][loopTaskKey])
          }
        }
      }
      // Number of days between oldest task date and now
      if (oldestTaskDate != null) {
        loopProjectDaysInPhase = Math.floor((currentDate.getTime() - oldestTaskDate) / 86400000)
        lastMilestoneTimestamp = oldestTaskDate
      }
    }
  }
  let projectPhaseDeadlineData = returnProjectPhaseDeadlineData(project, milestoneKey, taskWorkflow)
  projectPhaseDayData['phase_expected_days'] = projectPhaseDeadlineData['phase_expected_days']
  projectPhaseDayData['phase_extension_days'] = projectPhaseDeadlineData['phase_extension_days']
  projectPhaseDayData['days_in_phase'] = loopProjectDaysInPhase
  projectPhaseDayData['last_milestone_timestamp'] = lastMilestoneTimestamp
  if (lastMilestoneTimestamp != null) {
    projectPhaseDayData['estimated_phase_completion_timestamp'] =
      lastMilestoneTimestamp + projectPhaseDeadlineData['phase_expected_days'] * 86400000 + projectPhaseDeadlineData['phase_extension_days'] * 86400000
  }
  return projectPhaseDayData
}

///////////////////////////////
// Exports
///////////////////////////////

export const returnSortedWorkflowPhasesArray = (
  workflowTasks: TsInterface_UnspecifiedObject,
  workflow: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject[] => {
  // Order Tasks
  let sortedMilestoneTasks: TsInterface_UnspecifiedObject[] = []
  let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(workflowTasks))
  let sortedTaskRows = returnTaskRows(workflowTasks, {}, tasksThatReferenceThemselves, ['Active Projects List useEffect'])
  // Loop through grouped rows
  let loopIndex = 0
  let previousMilestoneRowIndex = -1
  for (let loopRowKey in sortedTaskRows) {
    let loopRow = sortedTaskRows[loopRowKey]
    if (loopRow != null && loopRow['tasks'] != null) {
      // Sort Tasks Alphabetically - mostly important for milestone tasks on the same row to keep things consistent
      for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('name', 'asc'))) {
        let loopTask = loopRow['tasks'][loopTaskIndex]
        if (loopTask != null && loopTask.key != null && workflow['filter_milestones'] != null && workflow['filter_milestones'][loopTask.key] === true) {
          // Get all the tasks that occurred after the previous milestone task
          let phaseTasks: TsInterface_UnspecifiedObject = {}
          for (let loopSearchIndex = previousMilestoneRowIndex; loopSearchIndex < parseInt(loopRowKey); loopSearchIndex++) {
            // Loop through tasks on the rows
            let loopSearchRow = sortedTaskRows[loopSearchIndex]
            if (loopSearchRow != null && loopSearchRow['tasks'] != null) {
              for (let loopSearchTaskIndex in loopSearchRow['tasks']) {
                let loopSearchTask = loopSearchRow['tasks'][loopSearchTaskIndex]
                // Add non-milestone tasks to the phase tasks
                if (loopSearchTask != null && loopSearchTask.key != null && workflow['filter_milestones'][loopSearchTask.key] !== true) {
                  phaseTasks[loopSearchTask.key] = loopSearchTask
                }
              }
            }
          }
          // Add milestone task to phaseTasks
          phaseTasks[loopTask.key] = loopTask
          // Add the milestone task to the sorted milestone tasks array
          sortedMilestoneTasks.push({
            task_name: getProp(loopTask, 'name', null),
            name: getProp(loopTask, 'name', null),
            key: getProp(loopTask, 'key', null),
            index: loopIndex,
            phase_tasks: phaseTasks,
          })
          if (workflow != null && workflow['filter_milestone_names'] != null && workflow['filter_milestone_names'][loopTask.key] != null) {
            sortedMilestoneTasks[loopIndex]['name'] = workflow['filter_milestone_names'][loopTask.key]
          }
          if (workflow != null && workflow['filter_milestone_days'] != null && workflow['filter_milestone_days'][loopTask.key] != null) {
            sortedMilestoneTasks[loopIndex]['expected_days'] = workflow['filter_milestone_days'][loopTask.key]
          }
          loopIndex++
          previousMilestoneRowIndex = parseInt(loopRowKey)
        }
      }
    }
  }
  return sortedMilestoneTasks
}

export const returnProjectsSortedByMilestonePhase = (
  sortedWorkflowPhasesArray: TsInterface_UnspecifiedObject[],
  activeProjects: TsInterface_UnspecifiedObject,
  activeProjectTimestamps: TsInterface_UnspecifiedObject,
  taskWorkflow: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  let projectsSortedByMilestone: TsInterface_UnspecifiedObject = {}
  // Loop through milestones and create starting project milestone object
  for (let loopMilestoneIndex in sortedWorkflowPhasesArray) {
    let loopMilestone = sortedWorkflowPhasesArray[loopMilestoneIndex]
    projectsSortedByMilestone[loopMilestone.key] = {}
  }
  // Loop through projects and determine where they fall in the milestone array
  for (let loopProjectKey in activeProjects) {
    let loopProject = activeProjects[loopProjectKey]
    let projectCurrentMilestoneKey = returnProjectCurrentMilestoneKey(sortedWorkflowPhasesArray, loopProject)
    projectsSortedByMilestone[projectCurrentMilestoneKey][loopProjectKey] = activeProjects[loopProjectKey]
  }
  // Determine Days spent in phase
  for (let loopMilestoneKey in projectsSortedByMilestone) {
    let loopMilestone = projectsSortedByMilestone[loopMilestoneKey]
    for (let loopProjectKey in loopMilestone) {
      let loopProject = loopMilestone[loopProjectKey]
      let loopProjectTimestamps = getProp(activeProjectTimestamps, loopProjectKey, {})
      let projectPhaseDayData = returnCalculatedPhaseDaysDataForProject(
        sortedWorkflowPhasesArray,
        loopProject,
        loopProjectTimestamps,
        loopMilestoneKey,
        taskWorkflow,
      )
      if (
        projectsSortedByMilestone != null &&
        projectsSortedByMilestone[loopMilestoneKey] != null &&
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]
      ) {
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_phase_expected_days'] = getProp(projectPhaseDayData, 'phase_expected_days', null)
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_phase_extension_days'] = getProp(projectPhaseDayData, 'phase_extension_days', null)
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_days_in_phase'] = getProp(projectPhaseDayData, 'days_in_phase', null)
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_last_milestone_timestamp'] = getProp(
          projectPhaseDayData,
          'last_milestone_timestamp',
          null,
        )
        if (getProp(projectPhaseDayData, 'last_milestone_timestamp', null) != null) {
          projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_estimated_phase_completion_timestamp'] =
            getProp(projectPhaseDayData, 'last_milestone_timestamp', null) +
            getProp(projectPhaseDayData, 'phase_expected_days', null) * 86400000 +
            getProp(projectPhaseDayData, 'phase_extension_days', null) * 86400000
        }
      }
    }
  }
  return projectsSortedByMilestone
}

export const returnProjectMilestoneTimelineDates = (
  sortedWorkflowPhasesArray: TsInterface_UnspecifiedObject[],
  project: TsInterface_UnspecifiedObject,
  projectTasks: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  let milestoneDates: TsInterface_UnspecifiedObject = {}
  let currentTimestamp = new Date().getTime()
  let previousMilestoneTaskKey = null
  // Check if the phases have been completed out of order and if so resort the phases AND flag out of order milestones
  let milestoneCompletionDates: TsInterface_UnspecifiedObject = {}
  for (let loopMilestoneIndex in sortedWorkflowPhasesArray) {
    let loopMilestone = sortedWorkflowPhasesArray[loopMilestoneIndex]
    let matchingTaskCompletionDate = null
    let taskComplete = false
    for (let loopProjectTaskKey in projectTasks) {
      let loopProjectTask = projectTasks[loopProjectTaskKey]
      if (loopProjectTask.associated_task_key === loopMilestone.key) {
        if (loopProjectTask != null && loopProjectTask.timestamp_completed != null) {
          matchingTaskCompletionDate = returnTimestampFromUnknownDateFormat(loopProjectTask.timestamp_completed)
          taskComplete = true
        }
      }
    }
    if (matchingTaskCompletionDate == null) {
      matchingTaskCompletionDate = new Date().getTime() + parseInt(loopMilestone.index)
    }
    milestoneCompletionDates[loopMilestone.key] = {
      completionDate: matchingTaskCompletionDate,
      index: loopMilestone.index,
      taskComplete: taskComplete,
    }
  }
  let milestoneCompletionDatesArray = objectToArray(milestoneCompletionDates)
  milestoneCompletionDatesArray.sort(dynamicSort('completionDate', 'asc'))
  // Sort milestoneCompletionDates by completionDate
  for (let loopMilestoneIndex in sortedWorkflowPhasesArray) {
    // Specifically using the custom ordered array
    let loopMilestone = sortedWorkflowPhasesArray[loopMilestoneIndex]
    if (loopMilestone != null && loopMilestone.key != null && loopMilestone.index != null) {
      milestoneDates[loopMilestone.key] = {
        key: loopMilestone.key,
        name: loopMilestone.name,
        task_name: loopMilestone.task_name,
        previous_milestone_name: null,
        index: loopMilestone.index,
        expected_days: loopMilestone.expected_days,
        phase_events: {}, // Task Start, Task Scheduled Dates, Task Complete, Sticky Notes
        incomplete_milestone_tasks: {},
      }
      // Previous Milestone Task Name
      if (parseInt(loopMilestoneIndex) > 0) {
        milestoneDates[loopMilestone.key]['previous_milestone_name'] = sortedWorkflowPhasesArray[parseInt(loopMilestoneIndex) - 1].task_name
      } else {
        milestoneDates[loopMilestone.key]['previous_milestone_name'] = 'Project Started'
      }
      // Start Dates
      if (loopMilestone.index === 0) {
        // Find the start date of the first phase
        let earliestStartedTaskDate = null
        let earliestCreatedTaskDate = null
        for (let loopTaskKey in getProp(loopMilestone, 'phase_tasks', {})) {
          for (let loopProjectTaskKey in projectTasks) {
            let loopProjectTask = projectTasks[loopProjectTaskKey]
            if (loopProjectTask.associated_task_key === loopTaskKey) {
              if (
                loopProjectTask != null &&
                loopProjectTask.timestamp_started != null &&
                (earliestStartedTaskDate == null || returnTimestampFromUnknownDateFormat(loopProjectTask.timestamp_started) < earliestStartedTaskDate)
              ) {
                earliestStartedTaskDate = returnTimestampFromUnknownDateFormat(loopProjectTask.timestamp_started)
              }
              if (
                loopProjectTask != null &&
                loopProjectTask.timestamp_created != null &&
                (earliestCreatedTaskDate == null || returnTimestampFromUnknownDateFormat(loopProjectTask.timestamp_created) < earliestCreatedTaskDate)
              ) {
                earliestCreatedTaskDate = returnTimestampFromUnknownDateFormat(loopProjectTask.timestamp_created)
              }
            }
          }
        }
        if (earliestStartedTaskDate != null) {
          milestoneDates[loopMilestone.key]['start_date'] = earliestStartedTaskDate
          milestoneDates[loopMilestone.key]['start_date_type'] = 'actual'
        } else if (earliestStartedTaskDate == null && earliestCreatedTaskDate != null) {
          milestoneDates[loopMilestone.key]['start_date'] = earliestCreatedTaskDate
          milestoneDates[loopMilestone.key]['start_date_type'] = 'actual'
        }
      } else {
        // Use the last milestone end date as the new start date
        if (
          previousMilestoneTaskKey != null &&
          milestoneDates[previousMilestoneTaskKey] != null &&
          milestoneDates[previousMilestoneTaskKey]['end_date'] != null
        ) {
          milestoneDates[loopMilestone.key]['start_date'] = milestoneDates[previousMilestoneTaskKey]['end_date']
          milestoneDates[loopMilestone.key]['start_date_type'] = milestoneDates[previousMilestoneTaskKey]['end_date_type']
        }
        if (
          previousMilestoneTaskKey != null &&
          milestoneDates[previousMilestoneTaskKey] != null &&
          milestoneDates[previousMilestoneTaskKey]['end_date_original'] != null
        ) {
          milestoneDates[loopMilestone.key]['start_date_original'] = milestoneDates[previousMilestoneTaskKey]['end_date_original']
        }
      }
      // End Dates
      let foundEndDate = false
      for (let loopProjectTaskKey in projectTasks) {
        let loopProjectTask = projectTasks[loopProjectTaskKey]
        if (loopProjectTask.associated_task_key === loopMilestone.key) {
          let loopProjectTask = projectTasks[loopProjectTaskKey]
          if (loopProjectTask != null && loopProjectTask.timestamp_completed != null) {
            let loopProjectTaskCompletedDate = returnTimestampFromUnknownDateFormat(loopProjectTask.timestamp_completed)
            if (loopProjectTaskCompletedDate != null) {
              milestoneDates[loopMilestone.key]['end_date'] = loopProjectTaskCompletedDate
              milestoneDates[loopMilestone.key]['end_date_type'] = 'actual'
              foundEndDate = true
            }
          }
        }
      }
      // Expected and Extension Days
      let expectedDays = milestoneDates[loopMilestone.key]['expected_days']
      let expectedDaysInMilliseconds = expectedDays * 86400000
      let extensionDays = 0
      if (project != null && project['phase_deadline_extensions'] != null && project['phase_deadline_extensions'][loopMilestone.key] != null) {
        for (let loopExtensionKey in project['phase_deadline_extensions'][loopMilestone.key]) {
          let loopExtension = project['phase_deadline_extensions'][loopMilestone.key][loopExtensionKey]
          if (loopExtension != null && loopExtension['extension_days'] != null) {
            extensionDays += loopExtension['extension_days']
          }
        }
      }
      milestoneDates[loopMilestone.key]['extension_days'] = extensionDays
      // Correct End Date so that phases are ordered sequentially if a future milestone task is done out of order
      if (milestoneDates[loopMilestone.key]['start_date'] > milestoneDates[loopMilestone.key]['end_date']) {
        milestoneDates[loopMilestone.key]['end_date_original'] = milestoneDates[loopMilestone.key]['end_date']
        milestoneDates[loopMilestone.key]['end_date'] = milestoneDates[loopMilestone.key]['start_date']
        if (milestoneDates[loopMilestone.key]['end_date_type'] === 'actual') {
          milestoneDates[loopMilestone.key]['end_date_type'] = 'corrected_actual'
        } else if (
          milestoneDates[loopMilestone.key]['end_date_type'] === 'estimated' ||
          milestoneDates[loopMilestone.key]['end_date_type'] === 'estimated_delayed'
        ) {
          milestoneDates[loopMilestone.key]['end_date_type'] = 'corrected_estimated'
        }
      }
      // If no actual end date is found, add expected + extension days to the start date
      if (foundEndDate === false) {
        if (milestoneDates[loopMilestone.key]['start_date'] != null && milestoneDates[loopMilestone.key]['expected_days'] != null) {
          let extensionDaysInMilliseconds = extensionDays * 86400000
          let estimatedEndDate = milestoneDates[loopMilestone.key]['start_date'] + expectedDaysInMilliseconds + extensionDaysInMilliseconds
          let estimatedEndDateWithoutExtensionDays = milestoneDates[loopMilestone.key]['start_date'] + expectedDaysInMilliseconds
          // If the end date is in the past, set the end date to the current date and flag as delayed
          if (estimatedEndDate < currentTimestamp) {
            // Past Deadline
            milestoneDates[loopMilestone.key]['end_date'] = currentTimestamp
            milestoneDates[loopMilestone.key]['end_date_type'] = 'estimated_delayed'
            if (extensionDays > 0) {
              milestoneDates[loopMilestone.key]['using_extension_days'] = true
            }
          } else if (estimatedEndDateWithoutExtensionDays < currentTimestamp) {
            // Past Deadline but within extension days
            milestoneDates[loopMilestone.key]['end_date'] = estimatedEndDate
            milestoneDates[loopMilestone.key]['end_date_type'] = 'estimated'
            milestoneDates[loopMilestone.key]['using_extension_days'] = true
          } else {
            milestoneDates[loopMilestone.key]['end_date'] = estimatedEndDate
            milestoneDates[loopMilestone.key]['end_date_type'] = 'estimated'
            milestoneDates[loopMilestone.key]['using_extension_days'] = false
          }
        }
      }
      // Actual Days
      if (
        milestoneDates[loopMilestone.key]['end_date'] != null &&
        milestoneDates[loopMilestone.key]['start_date'] != null &&
        (milestoneDates[loopMilestone.key]['end_date_type'] === 'actual' || milestoneDates[loopMilestone.key]['end_date_type'] === 'corrected_actual')
      ) {
        // Completed Milestones
        let actualDays = Math.floor((milestoneDates[loopMilestone.key]['end_date'] - milestoneDates[loopMilestone.key]['start_date']) / 86400000)
        milestoneDates[loopMilestone.key]['actual_days'] = actualDays
        milestoneDates[loopMilestone.key]['status'] = 'complete'
      } else if (
        milestoneDates[loopMilestone.key]['end_date'] != null &&
        milestoneDates[loopMilestone.key]['start_date'] != null &&
        milestoneDates[loopMilestone.key]['start_date'] < currentTimestamp
      ) {
        let actualDays = parseFloat(Math.floor((currentTimestamp - milestoneDates[loopMilestone.key]['start_date']) / 86400000).toFixed(0))
        milestoneDates[loopMilestone.key]['actual_days'] = actualDays
        milestoneDates[loopMilestone.key]['status'] = 'in_progress'
      } else {
        milestoneDates[loopMilestone.key]['actual_days'] = null
        milestoneDates[loopMilestone.key]['status'] = 'not_started'
      }
    }
    previousMilestoneTaskKey = loopMilestone.key
  }
  // Generate list of milestone tasks
  let milestoneTasks: TsInterface_UnspecifiedObject = {}
  for (let loopMilestoneIndex in milestoneDates) {
    let loopMilestone = milestoneDates[loopMilestoneIndex]
    milestoneTasks[loopMilestone.key] = true
  }
  // Loop through milestones
  for (let loopMilestoneIndex in milestoneDates) {
    let loopMilestone = milestoneDates[loopMilestoneIndex]
    let filterStartDate = loopMilestone['start_date']
    let filterEndDate = loopMilestone['end_date']
    let tasksInMilestone: TsInterface_UnspecifiedObject = {}
    if (
      loopMilestone != null &&
      loopMilestone.index != null &&
      sortedWorkflowPhasesArray != null &&
      sortedWorkflowPhasesArray[parseInt(loopMilestone.index)] != null &&
      sortedWorkflowPhasesArray[parseInt(loopMilestone.index)]['phase_tasks'] != null
    ) {
      tasksInMilestone = sortedWorkflowPhasesArray[parseInt(loopMilestone.index)]['phase_tasks']
    }
    // Loop through tasks and add completed tasks to each if it hits the right criteria
    for (let loopTaskKey in projectTasks) {
      let loopTask = projectTasks[loopTaskKey]
      let isMilestoneTask = false
      for (let loopMilestoneTaskKey in milestoneTasks) {
        if (loopTask.associated_task_key === loopMilestoneTaskKey) {
          isMilestoneTask = true
        }
      }
      if (loopTask.status !== 'deleted') {
        // Completed Tasks in date range
        if (isMilestoneTask === false) {
          if (
            loopTask.timestamp_completed != null &&
            returnTimestampFromUnknownDateFormat(loopTask.timestamp_completed) > filterStartDate &&
            returnTimestampFromUnknownDateFormat(loopTask.timestamp_completed) < filterEndDate
          ) {
            loopMilestone['phase_events']['COMPLETE_' + loopTask.key] = {
              type: 'task_complete',
              // name: loopTask.name + ' (Completed)',
              name: loopTask.name,
              timestamp: loopTask.timestamp_completed,
            }
          }
        }
        // Scheduled Tasks in date range
        if (loopTask.task_completion_scheduled_dates != null) {
          for (let loopScheduledDateIndex in loopTask.task_completion_scheduled_dates) {
            let loopScheduledDate = loopTask.task_completion_scheduled_dates[loopScheduledDateIndex]
            if (
              loopScheduledDate != null &&
              returnTimestampFromUnknownDateFormat(loopScheduledDate) > filterStartDate &&
              returnTimestampFromUnknownDateFormat(loopScheduledDate) < filterEndDate
            ) {
              loopMilestone['phase_events']['SCHEDULED_' + loopTask.key] = {
                type: 'task_scheduled',
                name: loopTask.name + ' (Team on Site)',
                timestamp: returnTimestampFromUnknownDateFormat(returnDateCorrectedForTimezoneOffset(loopScheduledDate)),
              }
            }
          }
        }
        // Incomplete Tasks that are in phase
        if (isMilestoneTask === false) {
          if (loopTask.status_complete === false) {
            for (let loopTaskKey in tasksInMilestone) {
              let loopTaskInMilestone = tasksInMilestone[loopTaskKey]
              if (loopTask.associated_task_key === loopTaskInMilestone.key) {
                loopMilestone['incomplete_milestone_tasks'][loopTask.key] = {
                  type: 'task_incomplete',
                  name: loopTask.name,
                }
              }
            }
          }
        }
      }
    }
    for (let loopStickyNoteKey in getProp(project, 'sticky_notes', {})) {
      let loopStickyNote = getProp(project, 'sticky_notes', {})[loopStickyNoteKey]
      if (
        loopStickyNote != null &&
        returnTimestampFromUnknownDateFormat(loopStickyNote.timestamp) > filterStartDate &&
        returnTimestampFromUnknownDateFormat(loopStickyNote.timestamp) < filterEndDate
      ) {
        loopMilestone['phase_events']['sticky_note_' + loopStickyNoteKey] = {
          type: 'sticky_note',
          name: loopStickyNote.note,
          timestamp: loopStickyNote.timestamp,
        }
      }
    }
    // TODO: Sticky Notes

    // if (loopMilestone != null && loopMilestone.key != null && loopMilestone.index != null) {
    //   let loopMilestoneDates = milestoneDates[loopMilestone.key]
    // }
  }

  return milestoneDates
}
