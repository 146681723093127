///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Material_Imports_By_Project } from 'rfbp_aux/services/database_endpoints/materials/material_imports'
import { DatabaseRef_TasksByTypeAndRange_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { TableCellBasic, TsInterface_TableColumns, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseMaterialAnalysisIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )

  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  const [us_tasks, us_setTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_materialImports, us_setMaterialImports] = useState<TsInterface_UnspecifiedObject>({})

  const today = new Date()

  // Force the first day of the month to midnight (local time)
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0)

  // Force the last day of the month to midnight (local time)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0, 0)

  // Format without using any UTC conversions
  const formatDateString = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const [us_weekStartDateKey, us_setWeekStartDateKey] = useState<string>(formatDateString(firstDayOfMonth))
  const [us_weekEndDateKey, us_setWeekEndDateKey] = useState<string>(formatDateString(lastDayOfMonth))

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Example Page', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction | undefined

    const updateLiveData: any = (newData: TsInterface_UnspecifiedObject[]) => {
      console.log('Raw tasks data received:', newData) // Debug log to check raw data structure

      const tasksArray = objectToArray(newData) // Assuming this converts an object to an array

      tasksArray.forEach((task) => {
        if (!task.id && !task.key && !task.associated_project_key) {
          console.warn('Task missing identifier:', task)
        } else {
          // Handle timestamp conversion
          if (task.timestamp_completed && task.timestamp_completed.seconds) {
            task.timestamp_completed = new Date(task.timestamp_completed.seconds * 1000)
          }
        }
      })

      const tasksObject = tasksArray.reduce<Record<string, TsInterface_UnspecifiedObject>>((acc, task) => {
        const taskId = task.id || task.key || task.associated_project_key
        acc[taskId] = task
        return acc
      }, {})

      console.log('Processed tasks object:', tasksObject) // Debug log for processed tasks

      us_setTasks(tasksObject)
      ur_forceRerender()
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        console.log('Client Key fetched:', res_GCK.clientKey) // Debug log for client key

        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_TasksByTypeAndRange_Query(
            res_GCK.clientKey,
            new Date(us_weekStartDateKey),
            new Date(us_weekEndDateKey),
            'install_pv_install_1695846909084',
          ),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error('Error fetching Tasks By Type And Range:', rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        console.log('Unsubscribing from live task updates')
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDateKey, us_weekEndDateKey])

  useEffect(() => {
    const taskKeys = Object.keys(us_tasks)
    console.log('Task keys available for material import fetching:', taskKeys)

    if (taskKeys.length === 0) {
      console.warn('No tasks found, skipping material imports fetch.')
      us_setMaterialImports({})
      return
    }

    // Extract unique project keys from tasks
    const projectKeySet = new Set<string>(taskKeys.map((taskId) => us_tasks[taskId]?.associated_project_key).filter(Boolean))
    const uniqueProjectKeys = Array.from(projectKeySet)

    console.log('Unique project keys for material imports:', uniqueProjectKeys)

    if (uniqueProjectKeys.length === 0) {
      console.warn('No associated project keys found in tasks.')
      us_setMaterialImports({})
      return
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const clientKey = res_GCK.clientKey
        console.log('Client Key for material imports:', clientKey)

        // Fetch material imports per unique project
        const importPromises = uniqueProjectKeys.map((projectKey) => {
          console.log(`Fetching material imports for projectKey: ${projectKey}`)

          return DatabaseGetCollection(DatabaseRef_Material_Imports_By_Project(clientKey, projectKey)).then((materialData) => ({
            [projectKey]: Array.isArray(materialData) ? materialData : [materialData], // Ensure array structure
          }))
        })

        Promise.all(importPromises)
          .then((allImports) => {
            console.log('Raw material imports fetched:', allImports)

            // Group material imports by project key
            const materialImportsByProject = allImports.reduce<Record<string, TsInterface_UnspecifiedObject[]>>((acc, importData) => {
              const [projectKey] = Object.keys(importData)
              if (!acc[projectKey]) {
                acc[projectKey] = importData[projectKey]
              } else {
                acc[projectKey] = [...acc[projectKey], ...importData[projectKey]] // Merge if multiple imports per project
              }
              return acc
            }, {})

            console.log('Processed material imports grouped by project:', materialImportsByProject)

            us_setMaterialImports(materialImportsByProject)
            ur_forceRerender()
          })
          .catch((err) => {
            console.error('Error fetching Material Imports:', err)
          })
      })
      .catch((err) => {
        console.error('Error fetching client key for material imports:', err)
      })
  }, [us_tasks, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions

  const handleChangeStartDate = (newDateParam: any): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      // 1) Convert string to JS Date
      const newDate = new Date(newDateParam)
      // Check if that's valid:
      if (isNaN(newDate.getTime())) {
        // If invalid, log a warning or set a default
        console.warn('handleChangeStartDate → Invalid date:', newDateParam)
        resolve({ success: false })
        return
      }

      // 2) Create a local "midnight" date
      const localMidnight = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())

      // 3) Format to YYYY-MM-DD
      const formattedDate = localMidnight.toISOString().slice(0, 10)

      // 4) Update your state
      us_setWeekStartDateKey(formattedDate)
      resolve({ success: true })
    })
  }

  const handleChangeEndDate = (newDateParam: any): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      // 1) Convert string to JS Date if needed
      const newDate = new Date(newDateParam)
      if (isNaN(newDate.getTime())) {
        console.warn('handleChangeEndDate → Invalid date:', newDateParam)
        resolve({ success: false })
        return
      }

      // 2) Create local midnight
      const localMidnight = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())

      // 3) Format as "YYYY-MM-DD"
      const formattedDate = localMidnight.toISOString().slice(0, 10)

      // 4) Set state
      us_setWeekEndDateKey(formattedDate)
      resolve({ success: true })
    })
  }

  const tableColumns_MaterialImports: TsInterface_TableColumns = {
    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    date: TableCellBasic('date', 'Date', 'date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Reference Number', 'invoice_reference_number'),
    project_job_code: TableCellBasic('project_job_code', 'Project Job Code', 'project_job_code'),
    associated_task_name: TableCellBasic('associated_task_name', 'Task Name', 'associated_task_name'),
    associated_team_name: TableCellBasic('associated_team_name', 'Team Name', 'associated_team_name'),
    ordered_with_user_name: TableCellBasic('ordered_with_user_name', 'Person', 'ordered_with_user_name'),
    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
    sku_number: TableCellBasic('sku_number', 'SKU Number', 'sku_number'),
    sku_description: TableCellBasic('sku_description', 'SKU Description', 'sku_description'),
  }

  const tableSettings_TaskRedos: TsInterface_TableSettings = {
    paginated: true,
    pagination_rows_per_page_default: 100,
    pagination_rows_per_page_options: [10, 25, 50, 100],
    show_header: true,
    size: 'small',
    sortable: true,
    sort_direction: 'asc',
    sort_property_default: 'timestamp',
  }

  const parseDate = (dateString: string): Date => {
    const [year, month, day] = dateString.split('-').map(Number)
    return new Date(year, month - 1, day) // Month is zero-based
  }

  // JSX Generation

  const rJSX_DatePicker = (): JSX.Element => {
    return (
      <Box className="tw-inline-flex tw-align-top tw-mr-2 tw-gap-2">
        <DatePicker
          key="calendar_date_start"
          datePickerText={rLIB('Start Date')}
          datePickerDate={parseDate(us_weekStartDateKey)} // No timezone issues
          datePickerDisabled={false}
          datePickerDateOnChange={handleChangeStartDate}
          datePickerSettings={{ thin_input: true }}
        />

        <DatePicker
          key="calendar_date_end"
          datePickerText={rLIB('End Date')}
          datePickerDate={parseDate(us_weekEndDateKey)}
          datePickerDisabled={false}
          datePickerDateOnChange={handleChangeEndDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
  }

  const rJSX_materialAnalysis = (): JSX.Element => {
    // First, convert the projects into an array with their total spent,
    // filter out those with zero spent, and sort descending.
    const projects = Object.entries(us_materialImports)
      .map(([projectKey, importEntries]: [any, any]) => {
        const totalSpent = importEntries.reduce((total: any, entry: any) => {
          const materials = Object.values(entry.data || {})
          return total + materials.reduce((sum: any, material: any) => sum + (parseFloat(material.total_price) || 0), 0)
        }, 0)
        return { projectKey, importEntries, totalSpent }
      })
      .filter((project) => project.totalSpent > 0)
      .sort((a, b) => b.totalSpent - a.totalSpent)

    const renderProjectData = () =>
      projects.map(({ projectKey, importEntries, totalSpent }) => {
        // Get the project job code from the first material entry.
        const firstMaterialEntry = importEntries.find((entry: any) => Object.keys(entry.data || {}).length > 0)
        const firstMaterial: any = firstMaterialEntry ? Object.values(firstMaterialEntry.data)[0] : null
        const projectJobCode: any = firstMaterial?.project_job_code || 'Unknown Job Code'

        // Calculate the total number of records (materials) in this project.
        const taskCleanUpCount = importEntries.reduce((acc: number, entry: any) => acc + Object.keys(entry.data || {}).length, 0)

        return (
          <Accordion
            key={projectKey}
            sx={{
              'backgroundColor': 'transparent',
              'mb': 2,
              '&::before': { display: 'none' }, // Remove the default divider line.
            }}
          >
            <AccordionSummary
              expandIcon={
                <Icon
                  icon="chevron-down"
                  tooltip="Expand/Collapse"
                />
              }
              sx={{
                'backgroundColor': 'transparent',
                'px': 2,
                'py': 1,
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(180deg)', // Rotate icon when expanded.
                },
              }}
            >
              <Grid
                container
                alignItems="center"
              >
                <Grid
                  item
                  xs={8}
                >
                  <Typography
                    variant="h6"
                    color="white"
                  >
                    Project Job Code: {projectJobCode}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  textAlign="right"
                >
                  <Typography
                    variant="subtitle1"
                    color="white"
                  >
                    Total Spent: ${totalSpent.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 2, py: 1 }}>
              <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <CardContent sx={{ p: 0 }}>
                  {importEntries.map(
                    (entry: any, index: number) =>
                      Object.keys(entry.data || {}).length > 0 && (
                        <Box
                          key={index}
                          sx={{
                            mb: 2,
                            p: 1,
                            backgroundColor: 'transparent',
                          }}
                        >
                          {Object.values(entry.data).map((material: any, idx: number, arr: any[]) => (
                            <Grid
                              container
                              spacing={2}
                              key={idx}
                              sx={{
                                p: 1,
                                borderBottom: arr.length > 1 && idx < arr.length - 1 ? '1px solid rgba(255,255,255,0.1)' : 'none',
                              }}
                            >
                              <Grid
                                item
                                xs={4}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color="white"
                                >
                                  Material: <strong>{material.sku_description}</strong>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="white"
                                  display="block"
                                >
                                  Supplier: {material.associated_supplier_name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="white"
                                  display="block"
                                >
                                  Task: {material.associated_task_name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                              >
                                <Typography
                                  variant="caption"
                                  color="white"
                                >
                                  Quantity: {material.quantity}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="white"
                                  display="block"
                                >
                                  Total Price: ${parseFloat(material.total_price).toFixed(2)}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                              >
                                <Typography
                                  variant="caption"
                                  color="white"
                                >
                                  Ordered By: {material.ordered_with_user_name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="white"
                                  display="block"
                                >
                                  Date: {material.date}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      ),
                  )}
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
        )
      })

    return (
      <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <CardContent>
          <Box>{renderProjectData()}</Box>
        </CardContent>
      </Card>
    )
  }

  // TABS
  const rJSX_MaterialAnalysisTabs = (): JSX.Element => {
    let temp = <>Under Construction</>

    return temp
    return (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'project_analysis',
            tabHeader: 'Project Analysis',
            tabButtons: [{ fullJSX: rJSX_DatePicker(), minJSX: rJSX_DatePicker(), sizeCutoff: 0 }],
            tabContent: rJSX_materialAnalysis(),
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.AdminDatabaseMaterialAnalysisIndexPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: 'Material Analysis',
        }}
      />
    )
  }

  // Main page render
  const rJSX_Page = (): JSX.Element => {
    return (
      <Box>
        <AuthenticatedContainer
          pageHeader="Material Anaysis"
          pageKey={pageKey}
          content={<>{rJSX_MaterialAnalysisTabs()}</>}
        />
      </Box>
    )
  }

  return <>{rJSX_Page()}</>
}
