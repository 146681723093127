///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Menu, MenuItem, Select } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskWorkflowsActive_Query, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_ActiveProjectsForSpecificWorkflowAndSalesPartner_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Icon } from 'rfbp_core/components/icons'
import { IconMenu } from 'rfbp_core/components/icon_menu/icon_menu'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { PacingProjectPhasesTab } from './tabs/tab_project_phases'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminProjectPacingPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  const [us_snoozeFilter, us_setSnoozeFilter] = useState<'all' | 'snoozed' | 'active'>('all')
  const [us_selectedTaskWorkflowKey, us_setSelectedTaskWorkflowKey] = useState<string>('')
  const [us_taskWorkflowsArray, us_setTaskWorkflowsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsObject, us_setTaskWorkflowsObject] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_filteredProjectPacingTags, us_setFilteredProjectPacingTags] = useState<string[]>([])
  const [us_pacingTagFilterAnchorEl, us_setPacingTagFilterAnchorEl] = useState<null | HTMLElement>(null)
  const [us_visibleProjectPacingTags, us_setVisibleProjectPacingTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeProjects, us_setActiveProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Pacing', false) as string
  }, [])

  useEffect(() => {
    // Generate List of Pacing Tags
    let pacingTags: TsInterface_UnspecifiedObject = {}
    for (let loopProjectKey in us_activeProjects) {
      let loopProject = us_activeProjects[loopProjectKey]
      let loopPacingTags = getProp(loopProject, 'pacing_tags', {})
      for (let loopPacingTagKey in loopPacingTags) {
        let loopPacingTag = loopPacingTags[loopPacingTagKey]
        if (loopPacingTag != null && loopPacingTag.text != null && loopPacingTag.text !== '' && loopPacingTag.color != null && loopPacingTag.color !== '') {
          let labelKey = loopPacingTag.text + '-' + loopPacingTag.color
          pacingTags[labelKey] = loopPacingTag
        }
      }
    }
    us_setVisibleProjectPacingTags(pacingTags)
    return () => {}
  }, [us_activeProjects])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveProjects(newData)
      ur_forceRerender()
    }
    if (
      us_selectedTaskWorkflowKey != null &&
      us_selectedTaskWorkflowKey !== '' &&
      us_taskWorkflow != null &&
      us_taskWorkflow['associated_sales_partner_key'] != null &&
      us_taskWorkflow['associated_sales_partner_key'] != ''
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveProjectsForSpecificWorkflowAndSalesPartner_Query(
              actualClientKey,
              us_selectedTaskWorkflowKey as string,
              us_taskWorkflow['associated_sales_partner_key'] as string,
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedTaskWorkflowKey, us_taskWorkflow])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    if (us_selectedTaskWorkflowKey != null && us_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_TaskWorkflow_Document(actualClientKey, us_selectedTaskWorkflowKey as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_selectedTaskWorkflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let pageLocalStorageData = getPageLocalStorage(pageKey)
    if (getProp(pageLocalStorageData, 'selected_task_workflow_key', null) != null) {
      us_setSelectedTaskWorkflowKey(getProp(pageLocalStorageData, 'selected_task_workflow_key', null))
    }
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            us_setTaskWorkflowsObject(res_DGC.data)
            let workflows: TsInterface_UnspecifiedObject[] = []
            for (let workflowKey in res_DGC.data) {
              let loopWorkflow = res_DGC.data[workflowKey]
              workflows.push({ key: loopWorkflow.key, value: loopWorkflow.name })
            }
            us_setTaskWorkflowsArray(workflows)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_WorkflowSelection = (): JSX.Element => {
    return (
      <FormControl className="bp_thin_select_input">
        <Select
          color="primary"
          value={us_selectedTaskWorkflowKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedTaskWorkflowKey(event.target.value)
              us_setFilteredProjectPacingTags([])
              setPageLocalStorage(pageKey, 'selected_task_workflow_key', event.target.value)
            }
          }}
          variant="outlined"
        >
          {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_SnoozeFilterButtons = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-mr-2 tw-ml-2">
        <IconMenu
          icon={
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Icon icon="filter" />}
            >
              {us_snoozeFilter}
            </Button>
          }
          menuItems={[
            {
              icon: 'alarm-clock',
              text: rLIB('All Projects'),
              onClick: () => {
                us_setSnoozeFilter('all')
              },
            },
            {
              icon: 'alarm-exclamation',
              text: rLIB('Active Projects'),
              onClick: () => {
                us_setSnoozeFilter('active')
              },
            },
            {
              icon: 'alarm-snooze',
              text: rLIB('Snoozed Projects'),
              onClick: () => {
                us_setSnoozeFilter('snoozed')
              },
            },
          ]}
        />
      </Box>
    )
  }

  const rJSX_Tabs = (): JSX.Element => {
    let pageTabs: TsInterface_TabContentUrlArray = [
      {
        tabUrlKey: 'Project_Phases',
        tabHeader: rLIB('Project Phases'),
        tabContent: (
          <PacingProjectPhasesTab
            selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
            taskWorkflows={us_taskWorkflowsObject}
            readOrWrite="write"
            projectViewPageKey={'AdminActiveProjectViewPage'}
            snoozeFilter={us_snoozeFilter}
            filteredProjectPacingTags={us_filteredProjectPacingTags}
          />
        ),
        tabButtons: [
          { fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 },
          { fullJSX: rJSX_SnoozeFilterButtons(), minJSX: rJSX_SnoozeFilterButtons(), sizeCutoff: 0 },
          { fullJSX: rJSX_PacingTagFilterMultiSelect(), minJSX: rJSX_PacingTagFilterMultiSelect(), sizeCutoff: 0 },
        ],
      },
    ]
    let tabsJSX = <></>
    if (us_taskWorkflowsArray.length > 0) {
      tabsJSX = (
        <TabsUrl
          tabs={pageTabs}
          tabsSettings={{
            tabQueryParam: 'tab',
            baseUrl: ApplicationPages['AdminProjectPacingPage'].url(),
            overridePageTitle: true,
            basePageTitle: rLIB('Pacing', false) as string,
          }}
        />
      )
    }
    return tabsJSX
  }

  const rJSX_PacingTagFilterMultiSelect = (): JSX.Element => {
    const open = Boolean(us_pacingTagFilterAnchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      us_setPacingTagFilterAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      us_setPacingTagFilterAnchorEl(null)
    }

    const handleTagToggle = (tagKey: string) => {
      const currentIndex = us_filteredProjectPacingTags.indexOf(tagKey)
      const newChecked = [...us_filteredProjectPacingTags]

      if (currentIndex === -1) {
        newChecked.push(tagKey)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      us_setFilteredProjectPacingTags(newChecked)
    }

    return (
      <Box className="tw-inline-block tw-mr-2">
        <Button
          onClick={handleClick}
          startIcon={<Icon icon="tag" />}
          endIcon={<Icon icon={open ? 'chevron-up' : 'chevron-down'} />}
          variant="outlined"
          color="secondary"
        >
          {rLIB('Tags')} ({us_filteredProjectPacingTags.length})
        </Button>
        <Menu
          anchorEl={us_pacingTagFilterAnchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 300,
              minWidth: 200,
            },
          }}
        >
          <MenuItem dense>
            <FormControlLabel
              control={
                <Checkbox
                  checked={us_filteredProjectPacingTags.length === objectToArray(us_visibleProjectPacingTags).length}
                  indeterminate={
                    us_filteredProjectPacingTags.length > 0 && us_filteredProjectPacingTags.length < objectToArray(us_visibleProjectPacingTags).length
                  }
                  onChange={() => {
                    if (us_filteredProjectPacingTags.length === objectToArray(us_visibleProjectPacingTags).length) {
                      us_setFilteredProjectPacingTags([])
                    } else {
                      us_setFilteredProjectPacingTags(Object.keys(us_visibleProjectPacingTags))
                    }
                  }}
                />
              }
              label={rLIB('Select All')}
            />
          </MenuItem>
          <Divider />
          {objectToArray(us_visibleProjectPacingTags)
            .sort(dynamicSort('text', 'asc'))
            .map((tag: TsInterface_UnspecifiedObject) => {
              const tagKey = tag.text + '-' + tag.color
              return (
                <MenuItem
                  key={tagKey}
                  dense
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={us_filteredProjectPacingTags.includes(tagKey)}
                        onChange={() => handleTagToggle(tagKey)}
                      />
                    }
                    label={
                      <Box
                        className="tw-inline-block tw-px-2 tw-py-1"
                        sx={{ backgroundColor: tag.color, borderRadius: '6px', color: themeVariables.white, paddingX: '8px', paddingY: '4px' }}
                      >
                        <Icon
                          icon="tag"
                          className="tw-mr-2"
                          sx={{ marginRight: '4px' }}
                        />
                        {tag.text}
                      </Box>
                    }
                  />
                </MenuItem>
              )
            })}
        </Menu>
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Pacing')}
        pageKey={pageKey}
        content={<Box>{rJSX_Tabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
