///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Base
export const DatabaseRef_Jurisdictions_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main')
}

export const DatabaseRef_Jurisdiction_Document = (clientKey: string, jurisdictionKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main', jurisdictionKey)
}

export const DatabaseRef_Jurisdictions_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    limit,
  )
}

// Files
export const DatabaseRef_JurisdictionFiles_Collection = (clientKey: string, jurisdictionKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main', jurisdictionKey, 'files')
}

export const DatabaseRef_JurisdictionFile_Document = (clientKey: string, jurisdictionKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main', jurisdictionKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_JurisdictionForumThreads_Collection = (clientKey: string, jurisdictionKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main', jurisdictionKey, 'forum')
}

export const DatabaseRef_JurisdictionForumThread_Document = (
  clientKey: string,
  jurisdictionKey: string,
  threadKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main', jurisdictionKey, 'forum', threadKey)
}

export const DatabaseRef_JurisdictionForumMessages_Collection = (
  clientKey: string,
  jurisdictionKey: string,
  threadKey: string,
): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main', jurisdictionKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_JurisdictionForumMessage_Document = (
  clientKey: string,
  jurisdictionKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main', jurisdictionKey, 'forum', threadKey, 'messages', messageKey)
}

export const DatabaseRef_ActiveJurisdictions_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'jurisdictions', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}
