/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			example component description

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Masonry } from '@mui/lab'
import { Box, Button, Card, Chip, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material/'
import { BaseInvoiceViewDialog } from 'app/pages/invoices/dialogs/base_invoice_view'
import { downloadBasePricingPDF } from 'app/pages/invoices/services/invoice_pdf_templates'
import { generateProjectInvoice, openApproveBaseInvoiceDialog } from 'app/pages/invoices/services/invoice_progress_functions'
import { tableColumns_AdditionalWorkInvoicesBasic, tableSettings_AdditionalWorkInvoicesBasic } from 'app/pages/invoices/tables/additional_work_invoices'
import { invoiceBillingTypeOptions } from 'app/pages/invoices/tables/project_base_pricing'
import { invoiceBillToOptions } from 'app/pages/invoices/tables/task_invoice_mapping'
import { getStartAndEndOfWeek } from 'app/pages/payroll/data/payroll_calculations'
import React, { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Document,
  DatabaseRef_FinalizedPayroll_UnitPayTask_Document,
} from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_AdditionalWorkInvoicesForProject_Query } from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_BaseInvoicesForProject_Query } from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { DatabaseRef_ProjectFinances_Collection, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetDocument, DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  formatCurrency,
  formatCurrencyWithExtraDigit,
  getProp,
  objectToArray,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { capitalizeFirstLetterInString } from 'rfbp_core/services/helper_functions/capitalize_first_letter_in_string'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_FinanceTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  projectUsingInvoicingModule: boolean
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewFinanceTab = forwardRef((props: TsInterface_ProjectView_FinanceTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_FinanceTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_projectUsingInvoicingModule: TsInterface_ProjectView_FinanceTab['projectUsingInvoicingModule'] = getProp(props, 'projectUsingInvoicingModule', false)
  // let pr_readOrWrite: TsInterface_ProjectView_FinanceTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_loadedPayrollData, us_setLoadedPayrollData] = useState<boolean>(false)
  const [us_payrollTotalUnitPay, us_setPayrollTotalUnitPay] = useState<number>(0)
  const [us_payrollTotalExcessPay, us_setPayrollTotalExcessPay] = useState<number>(0)
  const [us_payrollTaskData, us_setPayrollTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_baseInvoices, us_setBaseInvoices] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectAdditionalWorkInvoices, us_setProjectAdditionalWorkInvoices] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectFinances, us_setProjectFinances] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setBaseInvoices(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_BaseInvoicesForProject_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setBaseInvoices({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectAdditionalWorkInvoices(newData)
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (pr_projectKey != null) {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AdditionalWorkInvoicesForProject_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        } else {
          us_setProjectAdditionalWorkInvoices({})
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_projectKey, ur_forceRerender, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFinances(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectFinances_Collection(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectFinances({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (us_loadedPayrollData === false && us_projectTasks != null && Object.keys(us_projectTasks).length > 0) {
          let totalUnitPay = 0
          let totalExcessPay = 0
          let promiseArray1: Promise<any>[] = []
          let promiseArray2: Promise<any>[] = []
          let payrollTaskData: TsInterface_UnspecifiedObject = {}
          let userDataToLoadHoursFor: TsInterface_UnspecifiedObject = {}
          for (let loopTaskKey in us_projectTasks) {
            let task = us_projectTasks[loopTaskKey]
            if (task.task_completion_type === 'scheduled' && task.status !== 'deleted') {
              payrollTaskData[task.key] = {
                task: task,
                name: task.name,
                has_payroll_data: false,
                has_completion_date: false,
                payroll_data: {},
                excess_hour_data: {},
              }
              let taskCompletionDate = getProp(task, 'timestamp_completed', null)
              if (taskCompletionDate != null) {
                taskCompletionDate = returnDateFromUnknownDateFormat(taskCompletionDate)
                payrollTaskData[task.key].has_completion_date = true
                payrollTaskData[task.key].completion_date = taskCompletionDate
                let weekBoundingDates = getStartAndEndOfWeek(taskCompletionDate)
                let formattedDateKey = returnFormattedDateKey(weekBoundingDates.startOfWeek)

                promiseArray1.push(
                  DatabaseGetDocument(DatabaseRef_FinalizedPayroll_UnitPayTask_Document(res_GCK.clientKey, formattedDateKey, task.key))
                    .then((res_DGD) => {
                      payrollTaskData[task.key].has_payroll_data = true
                      payrollTaskData[task.key].payroll_data = res_DGD.data
                      payrollTaskData[task.key].payroll_date_key = formattedDateKey
                      payrollTaskData[task.key].payroll_date_end_key = returnFormattedDateKey(weekBoundingDates.endOfWeek)
                      // Loop through and sum up the unit pay
                      let unitPay = 0

                      console.log(res_DGD.data.unit_pay_totals)

                      for (let loopUserKey in res_DGD.data.unit_pay_totals) {
                        console.log(loopUserKey)

                        userDataToLoadHoursFor[formattedDateKey + '_' + loopUserKey] = {
                          invoice_date_key: formattedDateKey,
                          user_key: loopUserKey,
                        }

                        unitPay += res_DGD.data.unit_pay_totals[loopUserKey].total_unit_pay
                      }
                      totalUnitPay += unitPay
                    })
                    .catch((rej_DGD) => {
                      console.error(rej_DGD)
                    }),
                )
              }

              // let taskPayroll = getProp(task, 'payroll', {})
              // let payrollDate = getMondayDateKey
              // TODO: Get payroll date and load from finalized payroll data
              // console.log(taskPayroll)
            }
          }
          Promise.all(promiseArray1).then(() => {
            // Get Payroll Hours Data
            let payrollHoursData: TsInterface_UnspecifiedObject = {}
            for (let loopCombinedKey in userDataToLoadHoursFor) {
              let loopUserData = userDataToLoadHoursFor[loopCombinedKey]

              console.log('==============')
              console.log(loopUserData)

              promiseArray2.push(
                DatabaseGetDocument(
                  DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Document(res_GCK.clientKey, loopUserData.invoice_date_key, loopUserData.user_key),
                )
                  .then((res_DGD) => {
                    payrollHoursData[loopUserData.invoice_date_key + '_' + loopUserData.user_key] = {
                      payroll_hours_data: res_DGD.data,
                      user_key: loopUserData.user_key,
                      invoice_date_key: loopUserData.invoice_date_key,
                    }
                    console.log(res_DGD.data)
                  })
                  .catch((rej_DGD) => {
                    console.error('?????')
                    console.error(rej_DGD)
                  }),
              )
            }
            Promise.all(promiseArray2).then(() => {
              // Loop through and Add Payroll Excess to each task in payrollTaskData
              for (let loopTaskKey in payrollTaskData) {
                let task = payrollTaskData[loopTaskKey]

                console.log('1<><>')
                console.log(task)
                console.log(task.payroll_date_key)
                if (task != null && task['payroll_date_key'] != null && task['payroll_data'] != null && task['payroll_data']['unit_pay_totals'] != null) {
                  console.log('2<><>')
                  console.log(task['payroll_data']['unit_pay_totals'])
                  // for (let loopPayrollUserCombinedKey in payrollHoursData) {
                  for (let loopPayrollUserKey in task['payroll_data']['unit_pay_totals']) {
                    console.log('3<><>')
                    console.log(loopPayrollUserKey)

                    if (payrollHoursData != null && payrollHoursData[task.payroll_date_key + '_' + loopPayrollUserKey] != null) {
                      let userPayrollFullData = payrollHoursData[task.payroll_date_key + '_' + loopPayrollUserKey]

                      if (
                        loopTaskKey != null &&
                        userPayrollFullData != null &&
                        userPayrollFullData['payroll_hours_data'] != null &&
                        userPayrollFullData['payroll_hours_data']['unit_pay_task_breakdown'] != null &&
                        userPayrollFullData['payroll_hours_data']['unit_pay_task_breakdown'][loopTaskKey] != null &&
                        userPayrollFullData['payroll_hours_data']['unit_pay_task_breakdown'][loopTaskKey]['value'] != null &&
                        userPayrollFullData['payroll_hours_data']['wages_paid_on_task_details'] != null &&
                        userPayrollFullData['payroll_hours_data']['wages_paid_on_task_details'][loopTaskKey] != null &&
                        userPayrollFullData['payroll_hours_data']['wages_paid_on_task_details'][loopTaskKey]['total_pay'] != null
                      ) {
                        let unitPayAmount = userPayrollFullData['payroll_hours_data']['unit_pay_task_breakdown'][loopTaskKey]['value']
                        let wagesPaidOnTaskAmount = userPayrollFullData['payroll_hours_data']['wages_paid_on_task_details'][loopTaskKey]['total_pay']
                        let excessPay = wagesPaidOnTaskAmount - unitPayAmount
                        if (excessPay > 0) {
                          totalExcessPay += excessPay
                          payrollTaskData[loopTaskKey].payroll_data.unit_pay_totals[loopPayrollUserKey].total_excess_pay = excessPay
                        } else {
                          payrollTaskData[loopTaskKey].payroll_data.unit_pay_totals[loopPayrollUserKey].total_excess_pay = 0
                        }
                      }

                      console.log('4<><>')
                      console.log(loopTaskKey)
                      console.log(userPayrollFullData)
                    }
                  }
                }
              }
              console.log(payrollTaskData)
              console.log('|||||||||||||||||||||||||||||||')
              console.log(payrollHoursData)
              us_setLoadedPayrollData(true)
              us_setPayrollTotalUnitPay(totalUnitPay)
              us_setPayrollTotalExcessPay(totalExcessPay)
              us_setPayrollTaskData(payrollTaskData)
            })
          })
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [us_projectTasks, us_loadedPayrollData, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const getUniqueTeamMembers = (teamNamesMap: Record<string, Record<string, string>>): string[] => {
    const uniqueNamesSet = new Set<string>()

    if (teamNamesMap && typeof teamNamesMap === 'object') {
      Object.values(teamNamesMap).forEach((dailyMap) => {
        if (dailyMap && typeof dailyMap === 'object') {
          Object.values(dailyMap).forEach((memberName) => {
            uniqueNamesSet.add(memberName)
          })
        }
      })
    }

    return Array.from(uniqueNamesSet)
  }

  const returnTotalProjectDollars = (): number => {
    let totalDollars = 0
    if (us_payrollTotalUnitPay != null && !isNaN(us_payrollTotalUnitPay)) {
      totalDollars -= us_payrollTotalUnitPay
    }
    if (us_payrollTotalExcessPay != null && !isNaN(us_payrollTotalExcessPay)) {
      totalDollars -= us_payrollTotalExcessPay
    }
    return totalDollars
  }

  // task_completion_type === 'scheduled

  // JSX Generation
  const rJSX_SummaryExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Summary')}
        </Typography>
        <Card className="tw-p-0">
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Base Invoice')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: themeVariables.warning_main }}>
                      <Icon
                        icon="wrench"
                        className="tw-mr-2"
                      />
                      {rLIB('Under Construction')}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Additional Work')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: themeVariables.warning_main }}>
                      <Icon
                        icon="wrench"
                        className="tw-mr-2"
                      />
                      {rLIB('Under Construction')}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Payroll Unit Pay')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: themeVariables.error_main }}>{formatCurrency(us_payrollTotalUnitPay)}</Typography>
                  </TableCell>
                  <TableCell>
                    {getProp(us_rootProject, 'system_size_dc', null) != null &&
                    getProp(us_rootProject, 'system_size_dc', null) > 0 &&
                    us_payrollTotalUnitPay != null ? (
                      <Box sx={{ color: themeVariables.error_main }}>
                        {formatCurrencyWithExtraDigit(us_payrollTotalUnitPay / (getProp(us_rootProject, 'system_size_dc', null) * 1000))} {rLIB('/watt')}
                      </Box>
                    ) : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Payroll Inefficiency')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: themeVariables.error_main }}>{formatCurrency(us_payrollTotalExcessPay)}</Typography>
                  </TableCell>
                  <TableCell>
                    {getProp(us_rootProject, 'system_size_dc', null) != null &&
                    getProp(us_rootProject, 'system_size_dc', null) > 0 &&
                    us_payrollTotalExcessPay != null ? (
                      <Box sx={{ color: themeVariables.error_main }}>
                        {formatCurrencyWithExtraDigit(us_payrollTotalExcessPay / (getProp(us_rootProject, 'system_size_dc', null) * 1000))} {rLIB('/watt')}
                      </Box>
                    ) : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Materials')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: themeVariables.warning_main }}>
                      <Icon
                        icon="wrench"
                        className="tw-mr-2"
                      />
                      {rLIB('Under Construction')}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Vehicles')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: themeVariables.warning_main }}>
                      <Icon
                        icon="wrench"
                        className="tw-mr-2"
                      />
                      {rLIB('Under Construction')}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Overhead')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: themeVariables.warning_main }}>
                      <Icon
                        icon="wrench"
                        className="tw-mr-2"
                      />
                      {rLIB('Under Construction')}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>{rLIB('Total')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: returnTotalProjectDollars() < 0 ? themeVariables.error_main : themeVariables.success_main }}>
                      {formatCurrency(returnTotalProjectDollars())}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return cardJSX
  }

  // Base Price Card
  const rJSX_FinanceLineItem = (icon: string, label: string | JSX.Element, color: string, propKey: string): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{propValue}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_CustomFinanceLineItem = (
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
    rJSX_customFormatFunction: (propValue: any) => JSX.Element,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      // propValueJSX = <Typography variant="body1">{propValue}</Typography>
      propValueJSX = rJSX_customFormatFunction(propValue)
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_FinanceTimestampLineItem = (icon: string, label: string | JSX.Element, color: string, propKey: string): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{returnFormattedDate(propValue, 'MM/DD/YYYY h:mm a')}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_ApproveInvoiceButton = (): JSX.Element => {
    let projectInvoiceStatus = getProp(us_rootProject, 'invoice_status', null)
    let buttonJSX = <></>
    if (
      projectInvoiceStatus === 'unapproved' &&
      us_projectFinances != null &&
      us_projectFinances['base'] != null &&
      (us_projectFinances['base']['approval_type'] === 'internal_no_evidence' ||
        us_projectFinances['base']['approval_type'] === 'internal_optional_evidence' ||
        us_projectFinances['base']['approval_type'] === 'internal_required_evidence')
    ) {
      buttonJSX = (
        <Button
          variant="contained"
          color="success"
          className="tw-mt-2 tw-ml-2"
          startIcon={<Icon icon="circle-check" />}
          onClick={() => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                openApproveBaseInvoiceDialog(res_GCK.clientKey, pr_projectKey, uc_setUserInterface_CustomDialogDisplay)
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }}
        >
          {rLIB('Approve Base Project Quote')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_CreateInvoicesButton = (): JSX.Element => {
    let projectInvoiceStatus = getProp(us_rootProject, 'invoice_status', null)
    let buttonsJSX = <></>
    let generateBaseInvoiceButtonJSX = <></>
    if (projectInvoiceStatus === 'missing') {
      generateBaseInvoiceButtonJSX = (
        <Button
          variant="outlined"
          color="inherit"
          className="tw-mt-2"
          startIcon={<Icon icon="file-invoice" />}
          disabled={projectInvoiceStatus != 'missing' && projectInvoiceStatus != 'unapproved'}
          onClick={() => {
            generateProjectInvoice(us_rootProject, uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay)
          }}
        >
          {rLIB('Generate Base Invoice')}
        </Button>
      )
    }
    buttonsJSX = (
      <Box className="tw-text-center">
        {generateBaseInvoiceButtonJSX}
        {rJSX_ApproveInvoiceButton()}
      </Box>
    )
    return buttonsJSX
  }

  const rJSX_BillingMilestoneStackContent = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let stackContentJSX = <></>
    let projectFinanceBasePricing = getProp(us_projectFinances, 'base', {})
    if (projectFinanceBasePricing != null && projectFinanceBasePricing['billing_type'] === 'percent') {
      stackContentJSX = (
        <>
          <Typography variant="body1">{milestone.invoice_amount_percent}%</Typography>
          <Typography
            variant="body1"
            className="tw-opacity-50 tw-italic"
          >
            ({formatCurrency((milestone.invoice_amount_percent * projectFinanceBasePricing.total_price) / 100)})
          </Typography>
        </>
      )
    } else if (projectFinanceBasePricing != null && projectFinanceBasePricing['billing_type'] === 'deposit') {
      // Remainder After Deposits
      let totalDue = getProp(projectFinanceBasePricing, 'total_price', 0)
      for (let loopBillingTaskKey in getProp(projectFinanceBasePricing, 'billing_times', {})) {
        let billingTask = getProp(projectFinanceBasePricing, 'billing_times', {})[loopBillingTaskKey]
        if (billingTask != null && billingTask.invoice_amount_type === 'deposit' && billingTask.invoice_deposit_amount != null) {
          totalDue -= billingTask.invoice_deposit_amount
        }
      }
      stackContentJSX = (
        <>
          {milestone.invoice_amount_type === 'deposit' ? (
            <Typography variant="body1">
              {formatCurrency(milestone.invoice_deposit_amount)} ({rLIB('Deposit')})
            </Typography>
          ) : (
            <Typography variant="body1">{formatCurrency(totalDue)}</Typography>
          )}
        </>
      )
    }
    return stackContentJSX
  }

  const rJSX_BillingMilestoneStatus = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let statusJSX = <></>
    let foundTaskStatus = false
    if (milestone.associated_task_status === 'completed') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.associated_task_status)}</Box>
          <Icon
            icon="circle-check"
            className="tw-ml-2"
            sx={{ color: themeVariables.primary_main }}
            tooltip={rLIB('Completed but not yet billed')}
          />
          {/* <Icon
            icon="paper-plane"
            className="tw-ml-2 tw-cursor-pointer"
            tooltip={rLIB('Email Invoice')}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  openSendProjectBaseInvoiceEmailDialog(
                    res_GCK.clientKey,
                    us_rootProject.key,
                    milestone.associated_task_key,
                    milestone.key,
                    milestone, // TODO
                    uc_setUserInterface_CustomDialogDisplay,
                    uc_setUserInterface_ErrorDialogDisplay,
                  )
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            }}
          /> */}
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.associated_task_status === 'active') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.associated_task_status)}</Box>
          <Icon
            icon="circle-play"
            className="tw-ml-2"
            sx={{ color: themeVariables.success_main }}
            tooltip={capitalizeFirstLetterInString(milestone.associated_task_status)}
          />
          {/* <Icon
            icon="paper-plane"
            className="tw-ml-2 tw-cursor-pointer"
            tooltip={rLIB('Email Invoice')}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  openSendProjectBaseInvoiceEmailDialog(
                    res_GCK.clientKey,
                    us_rootProject.key,
                    milestone.associated_task_key,
                    milestone.key,
                    milestone, // TODO
                    uc_setUserInterface_CustomDialogDisplay,
                    uc_setUserInterface_ErrorDialogDisplay,
                  )
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            }}
          /> */}
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.status === 'billed') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.status)}</Box>
          <Icon
            icon="circle-envelope"
            className="tw-ml-2"
            sx={{ color: themeVariables.info_main }}
            tooltip={rLIB('Billed and waiting for payment')}
          />
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.status === 'paid') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.status)}</Box>
          <Icon
            icon="circle-dollar"
            className="tw-ml-2"
            sx={{ color: themeVariables.success_dark }}
            tooltip={rLIB('Invoice Paid')}
          />
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.status === 'cancelled') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.status)}</Box>
          <Icon
            icon="circle-trash"
            className="tw-ml-2"
            sx={{ color: themeVariables.error_main }}
            tooltip={rLIB('Cancelled')}
          />
        </Stack>
      )
      foundTaskStatus = true
    }
    if (foundTaskStatus === false) {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.associated_task_status)}</Box>
          <Icon
            icon="circle-pause"
            className="tw-ml-2"
            sx={{ color: themeVariables.warning_main }}
            tooltip={milestone.associated_task_status}
          />
        </Stack>
      )
    }
    return statusJSX
  }

  const rJSX_DeletedBillingTaskWarning = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let warningJSX = <></>
    if (
      pr_projectKey != null &&
      us_projectTasks != null &&
      milestone != null &&
      milestone.associated_task_key != null &&
      us_projectTasks[pr_projectKey + '_' + milestone.associated_task_key] != null
    ) {
      let task = us_projectTasks[us_rootProject.key + '_' + milestone.associated_task_key]
      if (task != null && task.status == 'deleted') {
        warningJSX = (
          <Icon
            icon="triangle-exclamation"
            className="tw-text-warning tw-ml-2"
            sx={{ color: themeVariables.error_main }}
            tooltip={rLIB('This billing task has been deleted.')}
          />
        )
      }
    }
    return warningJSX
  }

  const rJSX_BillingMilestoneList = (): JSX.Element => {
    let listJSX = <></>
    let projectFinanceBasePricing = getProp(us_projectFinances, 'base', {})
    listJSX = (
      <Box className="tw-ml-6">
        {objectToArray(getProp(projectFinanceBasePricing, 'billing_times', {}))
          .sort(dynamicSort('invoice_suffix', 'asc'))
          .map((milestone: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Box
                  className="tw-inline-block tw-mb-1"
                  sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
                >
                  {milestone.invoice_suffix}
                </Box>
                {rJSX_DeletedBillingTaskWarning(milestone)}
                <Typography variant="body1">{milestone.associated_task_name}:</Typography>
                {rJSX_BillingMilestoneStackContent(milestone)}
                {us_rootProject.invoice_status === 'approved' ? (
                  <Icon
                    icon="magnifying-glass"
                    className="tw-ml-2 tw-cursor-pointer"
                    sx={{ color: themeVariables.info_main }}
                    onClick={() => {
                      // TODO: Missing Data
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          uc_setUserInterface_CustomDialogDisplay({
                            display: true,
                            dialog: {
                              dialog_jsx: (
                                <BaseInvoiceViewDialog
                                  clientKey={res_GCK.clientKey}
                                  invoiceKey={(us_rootProject.key + '_' + milestone.associated_task_key) as string}
                                />
                              ),
                              settings: {
                                max_width: 'xl',
                              },
                            },
                          })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                  />
                ) : (
                  <></>
                )}
                <Icon
                  icon="file-pdf"
                  className="tw-ml-2 tw-cursor-pointer"
                  sx={{ color: themeVariables.error_main }}
                  onClick={() => {
                    // TODO: Missing Data
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        downloadBasePricingPDF(res_GCK.clientKey, us_rootProject.key, milestone.associated_task_key, 'download', 'project', {})
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }}
                />
              </Stack>
              <Box className="tw-ml-8 tw-mb-2">{rJSX_BillingMilestoneStatus(milestone)}</Box>
            </Box>
          ))}
      </Box>
    )
    return (
      <Box>
        <Typography
          variant="body1"
          sx={{ color: themeVariables.primary_main }}
        >
          <Icon
            icon="calendar-days"
            className="tw-mr-2"
          />
          {rLIB('Prospective Billing Milestones')}:
        </Typography>
        {listJSX}
      </Box>
    )
  }

  const rJSX_InvoiceBreakdown = (): JSX.Element => {
    let projectFinanceBasePricing = getProp(us_projectFinances, 'base', {})
    return (
      <Box>
        <Typography
          variant="body1"
          sx={{ color: themeVariables.primary_main }}
        >
          <Icon
            icon="file-invoice-dollar"
            className="tw-mr-2"
          />
          {rLIB('Base Invoice Breakdown')}:
        </Typography>
        <Box className="tw-ml-6">
          {objectToArray(getProp(projectFinanceBasePricing, 'base_pricing', {}))
            .sort(dynamicSort('name', 'asc'))
            .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body1">{getProp(lineItem, 'name', '')}:</Typography>
                  <Typography
                    variant="body1"
                    className="tw-opacity-50"
                  >
                    {formatCurrency(getProp(lineItem, 'calculated_price', 0))}
                  </Typography>
                </Stack>
              </Box>
            ))}
          {objectToArray(getProp(projectFinanceBasePricing, 'adder_pricing', {}))
            .sort(dynamicSort('name', 'asc'))
            .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body1">{getProp(lineItem, 'name', '')}:</Typography>
                  <Typography
                    variant="body1"
                    className="tw-opacity-50"
                  >
                    {formatCurrency(getProp(lineItem, 'calculated_price', 0))}
                  </Typography>
                </Stack>
              </Box>
            ))}
          <Divider className="tw-my-1 tw-w-40" />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Typography
              sx={{ fontWeight: 'bold' }}
              variant="body1"
            >
              {rLIB('Total')}:
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold' }}
              variant="body1"
              className="tw-opacity-50"
            >
              {formatCurrency(getProp(projectFinanceBasePricing, 'total_price', 0))}
            </Typography>
          </Stack>
        </Box>
      </Box>
    )
  }

  const rJSX_BasePriceCard = (): JSX.Element => {
    let warningIcon = <></>
    let cardJSX = <></>
    if (pr_projectUsingInvoicingModule === true) {
      if (us_rootProject != null && (us_rootProject.invoice_status == null || us_rootProject.invoice_status == 'missing')) {
        warningIcon = (
          <Icon
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main, fontSize: '1.25rem' }}
            icon="triangle-exclamation"
          />
        )
      }

      // Show
      cardJSX = (
        <Box>
          <Stack
            direction="row"
            alignItems="center"
          >
            {warningIcon}
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {rLIB('Project Base Invoice')}
            </Typography>
          </Stack>
          <Card className="tw-p-2">
            {getProp(us_rootProject, 'invoice_status', null) === 'approved' ? rJSX_ProjectInvoicesCardContent() : rJSX_ProjectBasePriceCardContent()}
            {rJSX_CreateInvoicesButton()}
          </Card>
        </Box>
      )
    } else {
      cardJSX = (
        <Box>
          <Stack
            direction="row"
            alignItems="center"
          >
            {warningIcon}
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {rLIB('Project Base Invoice')}
            </Typography>
          </Stack>
          <Card
            className="tw-p-2 tw-text-center"
            sx={{ color: themeVariables.warning_main }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.warning_main }}
            />
            {rLIB('Invoicing Module Not Enabled for this Project Workflow')}
          </Card>
        </Box>
      )
    }
    return cardJSX
  }

  const rJSX_AdditionalWorkCard = (): JSX.Element => {
    let cardJSX = <></>
    if (pr_projectUsingInvoicingModule === true) {
      cardJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-opacity-50"
          >
            {rLIB('Additional Work Invoices')}
          </Typography>
          <Card>
            <TableBasic
              tableAdditionalData={{
                pr_projectKey: pr_projectKey,
              }}
              tableColumns={tableColumns_AdditionalWorkInvoicesBasic}
              tableData={objectToArray(us_projectAdditionalWorkInvoices)}
              tableSettings={tableSettings_AdditionalWorkInvoicesBasic}
            />
          </Card>
        </Box>
      )
    } else {
      cardJSX = (
        <Box>
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {rLIB('Additional Work Invoices')}
            </Typography>
          </Stack>
          <Card
            className="tw-p-2 tw-text-center"
            sx={{ color: themeVariables.warning_main }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.warning_main }}
            />
            {rLIB('Invoicing Module Not Enabled for this Project Workflow')}
          </Card>
        </Box>
      )
    }
    return cardJSX
  }

  const rJSX_PayrollTaskIcon = (taskObject: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (taskObject.has_payroll_data === true) {
      iconJSX = (
        <Icon
          icon="circle-dollar"
          sx={{ color: themeVariables.success_main }}
          className="tw-mr-2"
          tooltip={rLIB('Paid')}
        />
      )
    } else if (taskObject.has_completion_date === true) {
      iconJSX = (
        <Icon
          icon="circle-check"
          sx={{ color: themeVariables.info_main }}
          className="tw-mr-2"
          tooltip={rLIB('Complete but no payroll data')}
        />
      )
    } else {
      iconJSX = (
        <Icon
          icon="circle-pause"
          sx={{ color: themeVariables.gray_500 }}
          className="tw-mr-2"
          tooltip={rLIB('Incomplete')}
        />
      )
    }
    return iconJSX
  }

  const rJSX_TeamMemberChips = (taskObject: TsInterface_UnspecifiedObject): JSX.Element => {
    let teamMemberChipsJSX = <></>
    if (taskObject.has_payroll_data === true) {
      teamMemberChipsJSX = (
        <Box className="tw-mt-2">
          {objectToArray(taskObject.payroll_data.unit_pay_totals).map((member: TsInterface_UnspecifiedObject, idx: number) => (
            <Chip
              key={idx}
              label={
                <>
                  {member.total_excess_pay > 0 ? (
                    <>
                      {member.name} - {formatCurrency(member.total_unit_pay)} + {formatCurrency(member.total_excess_pay)}
                    </>
                  ) : (
                    <>
                      {' '}
                      {member.name} - {formatCurrency(member.total_unit_pay)}
                    </>
                  )}
                </>
              }
              size="small"
              className="tw-mr-1 tw-mb-1"
              color={member.total_excess_pay > 0 ? 'warning' : 'success'}
            />
          ))}
        </Box>
      )
    } else {
      let taskTeamMembers: string[] = []
      if (taskObject != null && taskObject.task != null && taskObject.task.task_completion_scheduled_team_names != null) {
        taskTeamMembers = getUniqueTeamMembers(taskObject.task.task_completion_scheduled_team_names)
      }
      teamMemberChipsJSX = (
        <Box className="tw-mt-2">
          {taskTeamMembers.length > 0 ? (
            <Box>
              {taskTeamMembers.map((member: string, idx: number) => (
                <Chip
                  key={idx}
                  label={member}
                  size="small"
                  className="tw-mr-1 tw-mb-1"
                  sx={{
                    height: '24px',
                    fontWeight: 500,
                  }}
                />
              ))}
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )
    }
    return teamMemberChipsJSX
  }

  const rJSX_PayrollTaskInfo = (taskObject: TsInterface_UnspecifiedObject): JSX.Element => {
    let infoJSX = <></>
    let taskTeamMembers: string[] = []
    if (taskObject != null && taskObject.task != null && taskObject.task.task_completion_scheduled_team_names != null) {
      taskTeamMembers = getUniqueTeamMembers(taskObject.task.task_completion_scheduled_team_names)
    }
    infoJSX = (
      <Box className="tw-ml-6">
        {/* Scheduled Date(s) */}
        <Stack
          direction="row"
          spacing={1}
        >
          <Typography variant="body1">{rLIB('Scheduled Date(s)')}:</Typography>
          <Typography variant="body1">
            {taskObject.task.task_completion_scheduled_dates != null && taskObject.task.task_completion_scheduled_dates.length > 0 ? (
              taskObject.task.task_completion_scheduled_dates.map((date: string, idx: number) => (
                <Box
                  component="span"
                  key={idx}
                  sx={{ color: themeVariables.primary_main }}
                >
                  {returnFormattedDate(returnDateCorrectedForTimezoneOffset(date), 'D MMM YYYY')}
                  {idx < taskObject.task.task_completion_scheduled_dates.length - 1 ? ', ' : ''}
                </Box>
              ))
            ) : (
              <Box
                component="span"
                className="tw-opacity-30 tw-italic"
              >
                {rLIB('Not Scheduled')}
              </Box>
            )}
          </Typography>
        </Stack>
        {/* Completion Date */}
        <Stack
          direction="row"
          spacing={1}
        >
          <Typography variant="body1">{rLIB('Completion Date')}:</Typography>
          <Typography variant="body1">
            {taskObject.completion_date != null ? (
              <Box
                component="span"
                sx={{ color: themeVariables.primary_main }}
              >
                {returnFormattedDate(taskObject.completion_date, 'D MMM YYYY')}
              </Box>
            ) : (
              <Box
                component="span"
                className="tw-opacity-30 tw-italic"
              >
                {rLIB('Not Complete')}
              </Box>
            )}
          </Typography>
        </Stack>
        {/* Payroll Period */}
        <Stack
          direction="row"
          spacing={1}
        >
          <Typography variant="body1">{rLIB('Payroll Period')}:</Typography>
          <Typography variant="body1">
            {taskObject.payroll_date_key != null && taskObject.payroll_date_end_key != null ? (
              <Box
                component="span"
                sx={{ color: themeVariables.primary_main }}
              >
                {returnFormattedDate(returnDateCorrectedForTimezoneOffset(taskObject.payroll_date_key), 'D MMM YYYY')} -{' '}
                {returnFormattedDate(returnDateCorrectedForTimezoneOffset(taskObject.payroll_date_end_key), 'D MMM YYYY')}
              </Box>
            ) : (
              <Box
                component="span"
                className="tw-opacity-30 tw-italic"
              >
                {rLIB('Not Paid')}
              </Box>
            )}
          </Typography>
        </Stack>
        {/* Team Members */}
        {taskTeamMembers.length > 0 ? (
          <Box>
            <Stack
              direction="row"
              spacing={1}
            >
              <Typography variant="body1">{rLIB('Team Members')}:</Typography>
            </Stack>
            <Box className="tw-mt-2">{rJSX_TeamMemberChips(taskObject)}</Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    )
    return infoJSX
  }

  const rJSX_PayrollExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Payroll Expenses')}
        </Typography>
        <Card className="tw-p-2">
          <Box>
            {objectToArray(us_payrollTaskData)
              .sort(dynamicSort('name', 'asc'))
              .map((taskObject: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>
                  <Typography variant="body1">
                    {rJSX_PayrollTaskIcon(taskObject)}
                    {taskObject.task.name}
                  </Typography>
                  {rJSX_PayrollTaskInfo(taskObject)}
                  <Divider className="tw-my-1" />
                </Box>
              ))}
          </Box>
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_MaterialExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Material Expenses')}
        </Typography>
        <Card
          className="tw-p-2 tw-text-center"
          sx={{ color: themeVariables.warning_main }}
        >
          <Icon
            icon="wrench"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
          {rLIB('Under Construction')}
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_VehicleExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Vehicle Expenses')}
        </Typography>
        <Card
          className="tw-p-2 tw-text-center"
          sx={{ color: themeVariables.warning_main }}
        >
          <Icon
            icon="wrench"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
          {rLIB('Under Construction')}
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_OverheadExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Overhead Expenses')}
        </Typography>
        <Card
          className="tw-p-2 tw-text-center"
          sx={{ color: themeVariables.warning_main }}
        >
          <Icon
            icon="wrench"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
          {rLIB('Under Construction')}
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_EvidenceUploadedList = (): JSX.Element => {
    let evidenceUploadedListJSX = <></>
    if (
      us_projectFinances != null &&
      us_projectFinances.base != null &&
      (getProp(us_projectFinances.base, 'approval_type', null) === 'internal_required_evidence' ||
        getProp(us_projectFinances.base, 'approval_type', null) === 'internal_optional_evidence') &&
      objectToArray(getProp(us_projectFinances.base, 'approval_evidence_files', {})).length > 0
    ) {
      evidenceUploadedListJSX = (
        <Box className="tw-mb-1">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.primary_main }}
            >
              <Icon
                icon="image"
                className="tw-mr-2"
              />
              {rLIB('Approval Documentation')}:
            </Typography>
            <Box></Box>
          </Stack>
          <Box className="tw-ml-6">
            {objectToArray(getProp(us_projectFinances.base, 'approval_evidence_files', {}))
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body1">{option.name}</Typography>
                  <Icon
                    icon="cloud-arrow-down"
                    className="tw-cursor-pointer tw-ml-2 hover:tw-opacity-100 tw-opacity-50"
                    tooltip={rLIB('Download')}
                    tooltipPlacement="right"
                    onClick={() => {
                      // Download URL
                      window.open(option.url, '_blank')
                    }}
                  />
                </Stack>
              ))}
          </Box>
        </Box>
      )
    }
    return evidenceUploadedListJSX
  }

  const rJSX_ActualBaseInvoiceLineItem = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let totalAmount = getProp(milestone, 'total_price', 0)
    let lineItemJSX = (
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box
            className="tw-inline-block tw-mb-0"
            sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
          >
            {milestone.invoice_suffix}
          </Box>
          {rJSX_DeletedBillingTaskWarning(milestone)}
          <Typography variant="body1">{milestone.invoice_id_number}</Typography>
        </Stack>
        <Box className="tw-ml-8">{rJSX_BillingMilestoneStatus(milestone)}</Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className="tw-mb-4 tw-ml-8"
        >
          <Typography
            variant="body1"
            className="tw-opacity-50 tw-italic"
          >
            {formatCurrency(totalAmount)}
          </Typography>
          {us_rootProject.invoice_status === 'approved' ? (
            <Icon
              icon="magnifying-glass"
              className="tw-ml-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_main }}
              onClick={() => {
                // TODO: Missing Data
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    uc_setUserInterface_CustomDialogDisplay({
                      display: true,
                      dialog: {
                        dialog_jsx: (
                          <BaseInvoiceViewDialog
                            clientKey={res_GCK.clientKey}
                            invoiceKey={(us_rootProject.key + '_' + milestone.associated_task_key) as string}
                          />
                        ),
                        settings: {
                          max_width: 'xl',
                        },
                      },
                    })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }}
            />
          ) : (
            <></>
          )}
          <Icon
            icon="file-pdf"
            className="tw-ml-2 tw-cursor-pointer"
            sx={{ color: themeVariables.error_main }}
            onClick={() => {
              // TODO: Missing Data
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  downloadBasePricingPDF(res_GCK.clientKey, us_rootProject.key, milestone.associated_task_key, 'download', 'invoice', {})
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            }}
          />
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_ActualBaseInvoicesList = (): JSX.Element => {
    let listJSX = <></>
    listJSX = (
      <Box className="tw-ml-6">
        {objectToArray(us_baseInvoices)
          .sort(dynamicSort('invoice_suffix', 'asc'))
          .map((milestone: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_ActualBaseInvoiceLineItem(milestone)}</Box>
          ))}
      </Box>
    )
    return (
      <Box>
        <Typography
          variant="body1"
          sx={{ color: themeVariables.primary_main }}
        >
          <Icon
            icon="calendar-days"
            className="tw-mr-2 tw-mb-2"
          />
          {rLIB('Project Invoices')}:
        </Typography>
        {listJSX}
      </Box>
    )
  }

  const rJSX_ProjectInvoicesCardContent = (): JSX.Element => {
    let contentJSX = <></>
    contentJSX = (
      <Box>
        {rJSX_FinanceLineItem('list', rLIB('Invoice Status'), themeVariables.primary_main, 'invoice_status')}
        {rJSX_FinanceLineItem('building', rLIB('Sales Partner'), themeVariables.primary_main, 'associated_sales_partner_name')}
        {rJSX_FinanceLineItem('sitemap', rLIB('Task Workflow'), themeVariables.primary_main, 'associated_task_workflow_name')}
        {rJSX_FinanceLineItem('location-dot', rLIB('Region'), themeVariables.primary_main, 'associated_region_name')}
        {rJSX_FinanceTimestampLineItem('clock', rLIB('Bill Generated'), themeVariables.primary_main, 'timestamp_invoice_generated')}
        {rJSX_CustomFinanceLineItem('paper-plane', rLIB('Bill To'), themeVariables.primary_main, 'invoice_bill_to', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillToOptions != null && invoiceBillToOptions[propValue] != null && invoiceBillToOptions[propValue].name != null) {
            mappedPropValue = invoiceBillToOptions[propValue].name
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {rJSX_CustomFinanceLineItem('ballot-check', rLIB('Billing Type'), themeVariables.primary_main, 'invoice_billing_type', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillingTypeOptions != null && invoiceBillingTypeOptions[propValue] != null && invoiceBillingTypeOptions[propValue].value != null) {
            mappedPropValue = invoiceBillingTypeOptions[propValue].value
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {rJSX_ActualBaseInvoicesList()}
        {rJSX_EvidenceUploadedList()}
      </Box>
    )
    return contentJSX
  }

  const rJSX_ProjectBasePriceCardContent = (): JSX.Element => {
    return (
      <Box>
        {rJSX_FinanceLineItem('list', rLIB('Invoice Status'), themeVariables.primary_main, 'invoice_status')}
        {rJSX_FinanceLineItem('building', rLIB('Sales Partner'), themeVariables.primary_main, 'associated_sales_partner_name')}
        {rJSX_FinanceLineItem('sitemap', rLIB('Task Workflow'), themeVariables.primary_main, 'associated_task_workflow_name')}
        {rJSX_FinanceLineItem('location-dot', rLIB('Region'), themeVariables.primary_main, 'associated_region_name')}
        {rJSX_FinanceTimestampLineItem('clock', rLIB('Bill Generated'), themeVariables.primary_main, 'timestamp_invoice_generated')}
        {rJSX_CustomFinanceLineItem('paper-plane', rLIB('Bill To'), themeVariables.primary_main, 'invoice_bill_to', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillToOptions != null && invoiceBillToOptions[propValue] != null && invoiceBillToOptions[propValue].name != null) {
            mappedPropValue = invoiceBillToOptions[propValue].name
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {rJSX_CustomFinanceLineItem('ballot-check', rLIB('Billing Type'), themeVariables.primary_main, 'invoice_billing_type', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillingTypeOptions != null && invoiceBillingTypeOptions[propValue] != null && invoiceBillingTypeOptions[propValue].value != null) {
            mappedPropValue = invoiceBillingTypeOptions[propValue].value
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {objectToArray(getProp(us_projectFinances, 'base', {}).billing_times).length > 0 ? rJSX_InvoiceBreakdown() : <></>}
        {objectToArray(getProp(us_projectFinances, 'base', {}).billing_times).length > 0 ? rJSX_BillingMilestoneList() : <></>}
        {objectToArray(getProp(us_projectFinances, 'base', {}).billing_times).length > 0 ? rJSX_EvidenceUploadedList() : <></>}
      </Box>
    )
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, xl: 3 }}
          spacing={2}
        >
          {rJSX_SummaryExpensesCard()}
          {rJSX_BasePriceCard()}
          {rJSX_AdditionalWorkCard()}
          {rJSX_PayrollExpensesCard()}
          {rJSX_MaterialExpensesCard()}
          {rJSX_VehicleExpensesCard()}
          {rJSX_OverheadExpensesCard()}
        </Masonry>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
