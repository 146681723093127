///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_ProjectSystemDetails: TsInterface_FormInputs = {
  system_panel_quantity: {
    key: 'system_panel_quantity',
    label: rLIB('Panel Quantity'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  system_panel_manufacturer: {
    key: 'system_panel_manufacturer',
    label: rLIB('Panel Manufacturer'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 8,
      md: 8,
      lg: 8,
    },
  },
  system_panel_model: {
    key: 'system_panel_model',
    label: rLIB('Panel Model'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_inverter_quantity: {
    key: 'system_inverter_quantity',
    label: rLIB('Inverter Quantity'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  system_inverter_manufacturer: {
    key: 'system_inverter_manufacturer',
    label: rLIB('Inverter Manufacturer'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 8,
      md: 8,
      lg: 8,
    },
  },
  system_inverter_model: {
    key: 'system_inverter_model',
    label: rLIB('Inverter Model'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_storage_quantity: {
    key: 'system_storage_quantity',
    label: rLIB('Storage Quantity'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  system_storage_manufacturer: {
    key: 'system_storage_manufacturer',
    label: rLIB('Storage Manufacturer'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 8,
      md: 8,
      lg: 8,
    },
  },
  system_storage_model: {
    key: 'system_storage_model',
    label: rLIB('Storage Model'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_size_dc: {
    key: 'system_size_dc',
    label: rLIB('System Size'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  system_usage_offset: {
    key: 'system_usage_offset',
    label: rLIB('Usage Offset'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  system_meter_type: {
    key: 'system_meter_type',
    label: rLIB('Meter Type'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_storage_total_kwh: {
    key: 'system_storage_total_kwh',
    label: rLIB('Total Storage (KWH)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  system_number_of_arrays: {
    key: 'system_number_of_arrays',
    label: rLIB('Number of Arrays'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  system_number_of_strings: {
    key: 'system_number_of_strings',
    label: rLIB('Number of Strings'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  system_max_roof_pitch: {
    key: 'system_max_roof_pitch',
    label: rLIB('Max Roof Pitch'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 0,
    max: 50,
  },
  system_has_attic_run: {
    key: 'system_has_attic_run',
    label: rLIB('Attic Run'),
    input_type: 'multiple_choice_radio',
    required: false,
    data_type: 'boolean',
    options: [
      { key: 'yes', value: rLIB('Yes') },
      { key: 'no', value: rLIB('No') },
    ],
  },
  system_failed_first_inspection: {
    data_type: 'string',
    key: 'header1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-0">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-0"
          >
            {rLIB('If install inspection failed, go to the scheduler for the install task and fill out the QA tab')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
    // key: "system_failed_first_inspection",
    // label: s_FAILED_FIRST_INSPECTION,
    // input_type: "multiple_choice_radio",
    // required: false,
    // data_type: "boolean",
    // options: [
    // 	{ key: "yes", value: rLIB("Yes"), },
    // 	{ key: "no", value: rLIB("No"), },
    // ]
  },
}
