/* eslint-disable react/prop-types */
///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, Stack, TextField } from '@mui/material'
import React, { memo, useEffect, useRef, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rLIB } from 'rfbp_core/localization/library'
import { Icon } from '../icons/icon'

///////////////////////////////
// Typescript
///////////////////////////////

interface tsI_DirectTextEditInput {
  text: string
  onEnter: (value: string) => Promise<unknown>
  cancelEdit: () => void
}

interface tsI_DirectTextEdit {
  fullyClickable?: boolean
  displayText?: string | JSX.Element
  text: string
  onEnter: (value: string) => Promise<unknown>
  textCssClassName?: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const DirectTextEdit: React.FC<tsI_DirectTextEdit> = memo(function DirectTextEdit({ fullyClickable, displayText, text, onEnter, textCssClassName }) {
  // Hooks
  const [us_mode, us_setMode] = useState<'view' | 'edit'>('view')

  // Functions

  // JSX
  const rJSX_DisplayText = (): JSX.Element => {
    if (displayText != null && displayText != '') {
      return <>{displayText}</>
    } else {
      return <>{text}</>
    }
  }
  const rJSX_Component = (): JSX.Element => {
    if (us_mode === 'view') {
      if (textCssClassName == null) {
        textCssClassName = ''
      }
      textCssClassName += ' tw-cursor-default tw-inline-flex'
      if (fullyClickable === true) {
        textCssClassName += ' tw-cursor-pointer'
      }
      let editIconJSX = (
        <Box
          className="edit-icon tw-cursor-pointer tw-ml-2"
          sx={{
            opacity: 0,
            transition: 'opacity 0.2s',
          }}
        >
          <Icon
            icon="pen-circle"
            onClick={() => {
              us_setMode('edit')
            }}
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            sx={{
              'color': themeVariables.gray_400,
              '&:hover': {
                color: themeVariables.success_main,
              },
            }}
          />
        </Box>
      )
      if (fullyClickable === true) {
        editIconJSX = <></>
      }
      return (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            '&:hover .edit-icon': {
              opacity: 1,
            },
          }}
        >
          <Box
            className={textCssClassName}
            onClick={() => {
              if (fullyClickable === true) {
                us_setMode('edit')
              }
            }}
          >
            {rJSX_DisplayText()}
          </Box>
          {editIconJSX}
        </Stack>
      )
    } else {
      return (
        <Stack
          direction="row"
          spacing={1}
          className="tw-inline-flex"
        >
          <DirectTextEditInput
            text={text}
            onEnter={onEnter}
            cancelEdit={() => {
              us_setMode('view')
            }}
          />
          <Box
            sx={{
              'color': themeVariables.gray_400,
              '&:hover': {
                color: themeVariables.error_main,
              },
              'paddingTop': '6px',
              'marginLeft': '8px',
            }}
          >
            <Icon
              icon="square-xmark"
              className="tw-cursor-pointer tw-inline-block"
              tooltip={rLIB('Cancel')}
              tooltipPlacement="right"
              size="xl"
              onClick={() => {
                us_setMode('view')
              }}
            />
          </Box>
        </Stack>
      )
    }
  }

  return <>{rJSX_Component()}</>
})

export const DirectTextEditInput: React.FC<tsI_DirectTextEditInput> = memo(function DirectTextEditInput({ text, onEnter, cancelEdit }) {
  // Hooks
  const [us_text, us_setText] = useState(text)
  const [us_submitting, us_setSubmitting] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  // Focus input on mount and move cursor to end
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(text.length, text.length)
    }
  }, [text])

  // Functions
  const handleSubmit = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault()
    }
    if (onEnter) {
      us_setSubmitting(true)
      onEnter(us_text).then(() => {
        us_setSubmitting(false)
        cancelEdit()
      })
    }
  }

  // JSX
  return (
    <Box component="span">
      <form onSubmit={handleSubmit}>
        <FormControl className="tw-m-0">
          <TextField
            inputRef={inputRef}
            color="primary"
            value={us_text || ''}
            margin="normal"
            sx={{ width: '300px' }}
            className="bp_thin_text_input tw-m-0"
            disabled={us_submitting}
            onChange={(event: any) => {
              if (event?.target?.value != null) {
                us_setText(event.target.value)
              }
            }}
            onBlur={(event: any) => {
              if (event?.target?.value != null) {
                us_setText(event.target.value)
              }
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              // Submit edit on enter
              if (event.key === 'Enter' || event.key === 'Return') {
                event.preventDefault()
                handleSubmit()
              }
              // Cancel edit on escape
              if (event.key === 'Escape' || event.key === 'Esc') {
                event.preventDefault()
                cancelEdit()
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: us_submitting ? (
                <Box
                  className="tw-flex tw-items-center tw-pr-2"
                  sx={{ color: themeVariables.gray_400 }}
                >
                  <Icon
                    icon="spinner"
                    className="tw-animate-spin"
                  />
                </Box>
              ) : null,
            }}
          />
        </FormControl>
      </form>
    </Box>
  )
})
