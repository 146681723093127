/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { importProjectDocument, returnIconKeyForFileType, returnProjectDocumentFolders } from 'app/models/projects/project_document_folders'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_Project_Documents_Collection, DatabaseRef_Project_Documents_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { TsType_DocumentTableFilterOptions } from '../active_projects_view'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_DocumentsTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Tables
const tableSettings_DocumentsFolder: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'file_name',
  sortable: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'archived',
        conditions: [],
      },
    },
  ],
}

const tableColumns_DocumentsFolder = {
  manage: TableCellManage({
    view: {
      icon: <Icon icon="magnifying-glass" />,
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        window.open(rowData.url as string, '_blank')
      },
    },
    archive: {
      icon: <Icon icon="box-archive" />,
      label: <>{rLIB('Archive')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'archived',
        conditions: [],
      },
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'readOrWrite',
        comparator: '!=',
        value: 'write',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Archive Document'),
            icon: (
              <Icon
                icon="box-archive"
                type="solid"
              />
            ),
            submit_text: rLIB('Archive'),
            text: rLIB('Are you sure that you want to archive this document?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject = {
                      status: 'archived',
                      visible_to_customer: false,
                    }
                    DatabaseSetMergeDocument(
                      DatabaseRef_Project_Documents_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.projectKey,
                        tableAdditionalData.folderKey,
                        rowData.key as string,
                      ),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        tableHooks.ur_forceRerender()
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
    unarchive: {
      icon: <Icon icon="wand-magic-sparkles" />,
      label: <>{rLIB('Unarchive')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'archived',
        conditions: [],
      },
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'readOrWrite',
        comparator: '!=',
        value: 'write',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'secondary',
            header: rLIB('Unarchive Document'),
            icon: (
              <Icon
                icon="wand-magic-sparkles"
                type="solid"
              />
            ),
            submit_text: rLIB('Unarchive'),
            text: rLIB('Are you sure that you want to unarchive this document?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject = {
                      status: 'active',
                    }
                    DatabaseSetMergeDocument(
                      DatabaseRef_Project_Documents_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.projectKey,
                        tableAdditionalData.folderKey,
                        rowData.key as string,
                      ),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        tableHooks.ur_forceRerender()
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
  }),
  file_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box sx={{ color: returnIconKeyForFileType(rowData.file_type as string).color }}>
            <Icon
              size="3x"
              icon={returnIconKeyForFileType(rowData.file_type as string).icon}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  file_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('File')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Typography variant="subtitle1">{getProp(rowData, 'file_name', '')}</Typography>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  associated_uploader_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Uploaded By')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Typography variant="subtitle1">{getProp(rowData, 'associated_uploader_name', '')}</Typography>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_uploaded: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Uploaded At')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let timestampText = <></>
        let uploadTimestamp = getProp(rowData, 'timestamp_uploaded', null)
        if (uploadTimestamp != null) {
          timestampText = <Box>{returnFormattedDate(uploadTimestamp, 'D MMM YYYY - h:mm a')}</Box>
        }
        let cellJSX = (
          <Box className="tw-opacity-50 tw-italic">
            <Typography variant="subtitle1">{timestampText}</Typography>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  visible_to_customer: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Visible to Customer')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Switch
              disabled={rowData == null || rowData['status'] === 'archived' || tableAdditionalData['readOrWrite'] === 'read'}
              checked={rowData.visible_to_customer as boolean}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.checked != null && rowData != null && rowData.key != null) {
                  let updateObject = {
                    visible_to_customer: event.target.checked,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_Project_Documents_Document(
                          res_GCK.clientKey,
                          tableAdditionalData.projectKey,
                          tableAdditionalData.folderKey,
                          rowData.key as string,
                        ),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

// Form
const documentFilterOptions: TsInterface_UnspecifiedObject = {
  all: {
    key: 'all',
    value: rLIB('All Documents'),
  },
  archived: {
    key: 'archived',
    value: rLIB('Archived Documents'),
  },
  not_archived: {
    key: 'not_archived',
    value: rLIB('Hide Archived Documents'),
  },
  visible_to_customer: {
    key: 'visible_to_customer',
    value: rLIB('Visible to Customer'),
  },
  hidden_from_customer: {
    key: 'hidden_from_customer',
    value: rLIB('Hidden from Customer'),
  },
}

const formInputs_DocumentTableFilter: TsInterface_FormInputs = {
  table_filter: {
    key: 'table_filter',
    label: rLIB('Table Filter'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(documentFilterOptions),
  },
}

const tableSettings_Documents: TsInterface_TableSettings = {
  paginated: false,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'folder',
  sortable: true,
  alternate_row_color_hex: themeVariables.background_hover,
  alternate_row_colors: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-50', // Apply only opacity to the entire row
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'archived',
        conditions: [],
      },
    },
  ],
}

const tableColumns_Documents: TsInterface_TableColumns = {
  manage1: TableCellManage({
    view: {
      icon: <Icon icon="magnifying-glass" />,
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        window.open(rowData.url as string, '_blank')
      },
    },
    archive: {
      icon: <Icon icon="box-archive" />,
      label: <>{rLIB('Archive')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'archived',
        conditions: [],
      },
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'readOrWrite',
        comparator: '!=',
        value: 'write',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Archive Document'),
            icon: (
              <Icon
                icon="box-archive"
                type="solid"
              />
            ),
            submit_text: rLIB('Archive'),
            text: rLIB('Are you sure that you want to archive this document?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject = {
                      status: 'archived',
                      visible_to_customer: false,
                    }
                    DatabaseSetMergeDocument(
                      DatabaseRef_Project_Documents_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.projectKey,
                        rowData.associated_folder_key as string,
                        rowData.key as string,
                      ),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        tableHooks.ur_forceRerender()
                        rowData.status = 'archived'
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
    unarchive: {
      icon: <Icon icon="wand-magic-sparkles" />,
      label: <>{rLIB('Unarchive')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'archived',
        conditions: [],
      },
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'readOrWrite',
        comparator: '!=',
        value: 'write',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'secondary',
            header: rLIB('Unarchive Document'),
            icon: (
              <Icon
                icon="wand-magic-sparkles"
                type="solid"
              />
            ),
            submit_text: rLIB('Unarchive'),
            text: rLIB('Are you sure that you want to unarchive this document?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject = {
                      status: 'active',
                    }
                    DatabaseSetMergeDocument(
                      DatabaseRef_Project_Documents_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.projectKey,
                        rowData.associated_folder_key as string,
                        rowData.key as string,
                      ),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        tableHooks.ur_forceRerender()
                        rowData.status = 'active'
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
  }),
  // manage: {
  //   header: {
  //     header_jsx: () => <Box>Actions</Box>,
  //     header_sort_by: null, // No sorting for action buttons
  //   },
  //   cell: {
  //     cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
  //       const isArchived = rowData.status === 'archived' // Determine if the row is archived

  //       return (
  //         <Box
  //           display="flex"
  //           gap={2}
  //         >
  //           {/* View Button */}
  //           <Tooltip title="View Document">
  //             <IconButton
  //               onClick={() => {
  //                 const url = typeof rowData.url === 'string' ? rowData.url : String(rowData.url)
  //                 if (url) {
  //                   window.open(url, '_blank')
  //                 }
  //               }}
  //               color="primary"
  //             >
  //               <Icon icon="magnifying-glass" />
  //             </IconButton>
  //           </Tooltip>

  //           {/* Archive/Unarchive Button */}
  //           {isArchived ? (
  //             <Tooltip title="Unarchive Document">
  //               <IconButton
  //                 onClick={() => {
  //                   console.log('Initiating unarchive operation for:', rowData)
  //                   tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
  //                     display: true,
  //                     confirm: {
  //                       color: 'secondary',
  //                       header: 'Unarchive Document',
  //                       icon: (
  //                         <Icon
  //                           icon="wand-magic-sparkles"
  //                           type="solid"
  //                         />
  //                       ),
  //                       submit_text: 'Unarchive',
  //                       text: 'Are you sure that you want to unarchive this document?',
  //                       submit_callback: () => {
  //                         // Unarchive operation logic
  //                       },
  //                     },
  //                   })
  //                 }}
  //                 color="secondary"
  //               >
  //                 <Icon icon="wand-magic-sparkles" />
  //               </IconButton>
  //             </Tooltip>
  //           ) : (
  //             <Tooltip title="Archive Document">
  //               <IconButton
  //                 onClick={() => {
  //                   console.log('Initiating archive operation for:', rowData)
  //                   tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
  //                     display: true,
  //                     confirm: {
  //                       color: 'error',
  //                       header: 'Archive Document',
  //                       icon: (
  //                         <Icon
  //                           icon="box-archive"
  //                           type="solid"
  //                         />
  //                       ),
  //                       submit_text: 'Archive',
  //                       text: 'Are you sure that you want to archive this document?',
  //                       submit_callback: () => {
  //                         // Archive operation logic
  //                       },
  //                     },
  //                   })
  //                 }}
  //                 color="error"
  //               >
  //                 <Icon icon="box-archive" />
  //               </IconButton>
  //             </Tooltip>
  //           )}
  //         </Box>
  //       )
  //     },
  //   },
  // },
  associated_folder_name: {
    header: {
      header_jsx: () => <Box>Folder</Box>,
      header_sort_by: 'associated_folder_name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
        <Typography className={rowData.status === 'archived' ? 'tw-opacity-50 tw-line-through' : ''}>
          {rowData.associated_folder_name || 'Unknown Folder'}
        </Typography>
      ),
    },
  },
  file_name: {
    header: {
      header_jsx: () => rLIB('File Name'),
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow) => (
        <Typography
          variant="subtitle1"
          className={rowData.status === 'archived' ? 'tw-opacity-50 tw-line-through' : ''}
        >
          {rowData.file_name || 'Unknown File'}
        </Typography>
      ),
    },
  },
  file_size: {
    header: {
      header_jsx: () => rLIB('File Size'),
      header_sort_by: 'file_size',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow) => {
        const fileSize: any = rowData.file_size
        const displaySize = fileSize ? `${(fileSize / 1024).toFixed(2)} KB` : ''
        return <Typography className={rowData.status === 'archived' ? 'tw-opacity-50 tw-line-through' : ''}>{displaySize}</Typography>
      },
    },
  },
  origin: {
    header: {
      header_jsx: () => <Box>{rLIB('Origin')}</Box>,
      header_sort_by: 'origin',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
        <Typography className={rowData.status === 'archived' ? 'tw-opacity-50 tw-line-through' : ''}>{rowData.origin || 'Unknown'}</Typography>
      ),
    },
  },
  associated_uploader_name: {
    header: {
      header_jsx: () => <Box>{rLIB('Uploaded By')}</Box>,
      header_sort_by: 'associated_uploader_name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
        <Typography className={rowData.status === 'archived' ? 'tw-opacity-50 tw-line-through' : ''}>{rowData.associated_uploader_name}</Typography>
      ),
    },
  },
  timestamp_uploaded: {
    header: {
      header_jsx: () => <Box>{rLIB('Uploaded At')}</Box>,
      header_sort_by: 'timestamp_uploaded',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
        <Typography className={rowData.status === 'archived' ? 'tw-opacity-50 tw-line-through' : ''}>
          {rowData.timestamp_uploaded ? returnFormattedDate(rowData.timestamp_uploaded, 'D MMM YYYY h:mm a') : 'No Date'}
        </Typography>
      ),
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewDocumentsTab = forwardRef((props: TsInterface_ProjectView_DocumentsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_DocumentsTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ProjectView_DocumentsTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_readOrWrite: TsInterface_ProjectView_DocumentsTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_folderKeys, us_setFolderKeys] = useState<{ key: string; name: string }[]>([])
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null)
  const [us_allProjectDocuments, us_setAllProjectDocuments] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_documentTableFilter, us_setDocumentTableFilter] = useState<TsType_DocumentTableFilterOptions>('not_archived')
  const [us_documentsViewLevel, us_setDocumentsViewLevel] = useState<string>('root') // root | folder
  const [us_projectDocumentFolders, us_setProjectDocumentFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDocumentsFolder, us_setSelectedDocumentsFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDocumentsFolderData, us_setSelectedDocumentsFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        us_setProjectDocumentFolders(returnProjectDocumentFolders(actualClientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_clientKey])

  useEffect(() => {
    // Step 1: Fetch Folder Keys and Folder Names Dynamically
    if (!uc_RootData_ClientKey || !pr_projectKey) return

    // Get the project document folders using the returnProjectDocumentFolders function
    let actualClientKey = pr_clientKey ? pr_clientKey : uc_RootData_ClientKey
    const projectFolders = returnProjectDocumentFolders(actualClientKey)

    // Create an array of objects with folder key and name inline
    const folderKeysWithNames = Object.keys(projectFolders).map((folderKey) => ({
      key: folderKey,
      name: projectFolders[folderKey].name, // Inline folder name extraction
    }))

    us_setFolderKeys(folderKeysWithNames) // Store folder keys with names in state
  }, [uc_RootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    // Step 2: Fetch Documents For Each Folder with Live Updates
    if (!us_folderKeys || us_folderKeys.length === 0) return

    // Store unsubscribe functions to clean them up later
    let unsubscribeList: TsType_VoidFunction[] = []

    const fetchDocumentsForFolders = () => {
      us_folderKeys.forEach((folder) => {
        // Create the collection reference for the documents inside the folder
        let actualClientKey = pr_clientKey ? pr_clientKey : uc_RootData_ClientKey
        const collectionRef = DatabaseRef_Project_Documents_Collection(actualClientKey as any, pr_projectKey, folder.key)

        // Subscribe to live updates
        const unsubscribe = DatabaseGetLiveCollection(collectionRef, (data) => {
          // Attach folder name to the data fetched
          const dataWithFolder = Object.keys(data).map((docKey) => ({
            ...data[docKey],
            associated_folder_name: folder.name, // Inline folder name attachment
            associated_folder_key: folder.key,
          }))
          // Update documents state while preserving existing documents from other folders
          us_setAllProjectDocuments((prevDocuments: TsInterface_UnspecifiedObject[]) => {
            // Remove previous documents for the same folder
            const filteredDocs = prevDocuments.filter((doc) => doc.folder !== folder.name)
            return [...filteredDocs, ...dataWithFolder] // Add updated documents for the folder
          })
        })

        // Store the unsubscribe function
        unsubscribeList.push(unsubscribe)
      })
    }

    fetchDocumentsForFolders()

    // Cleanup function to unsubscribe from live updates when component unmounts or folder changes
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe())
    }
  }, [uc_RootData_ClientKey, pr_projectKey, us_folderKeys, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedDocumentsFolderData(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null && us_selectedDocumentsFolder != null && us_selectedDocumentsFolder.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_Project_Documents_Collection(actualClientKey, pr_projectKey, us_selectedDocumentsFolder.key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedDocumentsFolderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedDocumentsFolder, pr_projectKey, ur_forceRerender, pr_clientKey])

  // Functions
  const uploadProjectDocument = (
    event: React.ChangeEvent<HTMLInputElement>,
    additionalFileUploadParams: TsInterface_UnspecifiedObject,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let file = event.target.files[0]
        const fileSize = file.size // File size in bytes

        const reader = new FileReader()
        reader.addEventListener('load', (imageLoadEvent) => {
          if (imageLoadEvent != null && imageLoadEvent.target != null && imageLoadEvent.target.result != null) {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
                // Pass file size to the importProjectDocument function
                importProjectDocument(
                  actualClientKey,
                  getProp(uc_RootData_ClientUser, 'name', null),
                  getProp(uc_RootData_ClientUser, 'key', null),
                  pr_projectKey,
                  additionalFileUploadParams.folderKey,
                  file,
                  file.name,
                  fileSize, // Pass file size
                )
                  .then((res_IPD: any) => {
                    resolve(res_IPD)
                  })
                  .catch((rej_IPD: any) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_IPD.error })
                    reject(rej_IPD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to upload file'),
                details: rLIB('Invalid document selection'),
                code: 'ER-D-APV-UPD-01',
              },
            })
          }
        })
        reader.readAsDataURL(file)
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to upload file'),
            details: rLIB('Invalid document selection'),
            code: 'ER-D-APV-UPD-02',
          },
        })
      }
    })
  }

  const handleMultipleFileChange = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<void> => {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files // Use the FileList directly

      // Process each file individually
      const uploadPromises = Array.from(files).map((file) => {
        const fileEvent = {
          ...event,
          target: {
            ...event.target,
            files: createFileList([file]), // Create a new FileList containing a single file
          },
        } as React.ChangeEvent<HTMLInputElement>

        return uploadProjectDocument(fileEvent, additionalFileUploadParams)
      })

      return Promise.all(uploadPromises)
        .then((results) => {
          console.log('All files uploaded successfully', results)
        })
        .catch((error) => {
          console.error('Error uploading one or more files', error)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: error.message || 'An error occurred during file upload.',
          })
        })
    } else {
      return Promise.reject({
        success: false,
        error: {
          message: 'No files selected for upload.',
        },
      })
    }
  }

  function createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer()
    files.forEach((file) => dataTransfer.items.add(file))
    return dataTransfer.files
  }

  const returnTaskDocuments = (): TsInterface_UnspecifiedObject[] => {
    let filteredDocuments: TsInterface_UnspecifiedObject[] = []
    if (us_documentTableFilter === 'all') {
      filteredDocuments = objectToArray(us_selectedDocumentsFolderData)
    } else if (us_documentTableFilter === 'archived') {
      for (let loopDocumentKey in us_selectedDocumentsFolderData) {
        let loopDocument = us_selectedDocumentsFolderData[loopDocumentKey]
        if (loopDocument['status'] === 'archived') {
          filteredDocuments.push(loopDocument)
        }
      }
    } else if (us_documentTableFilter === 'not_archived') {
      for (let loopDocumentKey in us_selectedDocumentsFolderData) {
        let loopDocument = us_selectedDocumentsFolderData[loopDocumentKey]
        if (loopDocument['status'] !== 'archived') {
          filteredDocuments.push(loopDocument)
        }
      }
    } else if (us_documentTableFilter === 'visible_to_customer') {
      for (let loopDocumentKey in us_selectedDocumentsFolderData) {
        let loopDocument = us_selectedDocumentsFolderData[loopDocumentKey]
        if (loopDocument['visible_to_customer'] === true) {
          filteredDocuments.push(loopDocument)
        }
      }
    } else if (us_documentTableFilter === 'hidden_from_customer') {
      for (let loopDocumentKey in us_selectedDocumentsFolderData) {
        let loopDocument = us_selectedDocumentsFolderData[loopDocumentKey]
        if (loopDocument['visible_to_customer'] !== true) {
          filteredDocuments.push(loopDocument)
        }
      }
    } else {
      filteredDocuments = objectToArray(us_selectedDocumentsFolderData)
    }
    return filteredDocuments
  }

  // JSX Generation
  const rJSX_DocumentsTable = () => {
    return (
      <TableBasic
        tableColumns={tableColumns_Documents}
        tableData={objectToArray(us_allProjectDocuments)}
        tableSettings={tableSettings_Documents}
        tableAdditionalData={{
          projectKey: pr_projectKey,
          readOrWrite: pr_readOrWrite,
        }}
      />
    )
  }

  const rJSX_DocumentsRootButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        color="inherit"
        className="tw-opacity-50 tw-mr-2 tw-mb-1"
        onClick={() => {
          us_setDocumentsViewLevel('root')
          us_setSelectedDocumentsFolder({})
          us_setSelectedDocumentsFolderData({})
        }}
      >
        <Icon
          icon="folder"
          className="tw-mr-2.5"
          type="regular"
        ></Icon>
        {rLIB('Project')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DocumentsUploadButton = (): JSX.Element => {
    // Handle folder selection with the correct MUI SelectChangeEvent
    const handleFolderChange = (event: SelectChangeEvent<string>) => {
      setSelectedFolder(event.target.value)
    }

    // Handle file upload and make sure a folder is selected
    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): Promise<any> => {
    //   if (selectedFolder) {
    //     return uploadProjectDocument(event, {
    //       folderKey: selectedFolder,
    //     })
    //       .then((res) => {
    //         console.log('Document uploaded successfully', res)
    //       })
    //       .catch((error) => {
    //         console.error('Failed to upload document', error)
    //         uc_setUserInterface_ErrorDialogDisplay({
    //           display: true,
    //           error: error.message || 'Unknown error occurred during file upload',
    //         })
    //       })
    //   } else {
    //     return Promise.reject({
    //       success: false,
    //       error: {
    //         message: 'Please select a folder before uploading.',
    //       },
    //     })
    //   }
    // }

    return (
      <Box className="tw-mb-4 tw-mt-2">
        {/* Folder Selection Dropdown */}
        <FormControl
          variant="outlined"
          sx={{ width: '300px', height: '40px' }} // Adjust the height
          className="tw-mr-2"
          size="small" // Make the select smaller
        >
          <InputLabel>Select Folder to Upload Document</InputLabel>
          <Select
            value={selectedFolder || ''}
            onChange={handleFolderChange}
            label="Select Folder to Upload Document"
            sx={{ height: '38px', lineHeight: '1.5' }} // Adjust the Select height
          >
            {us_folderKeys.map((folder) => (
              <MenuItem
                key={folder.key}
                value={folder.key}
              >
                {folder.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Tooltip for disabled upload button */}
        <Tooltip title={!selectedFolder ? 'Please select a folder before uploading' : ''}>
          <span>
            {' '}
            {/* Wrap in a span to ensure the tooltip works with disabled button */}
            <FileUploadButton
              multiple={true} // Allow selecting multiple files
              button={{
                text: <span>Upload Documents</span>,
                icon: (
                  <Icon
                    icon="cloud-arrow-up"
                    className="tw-mr-2"
                  ></Icon>
                ),
                color: 'info',
                variant: 'contained',
                className: '',
                disabled: !selectedFolder, // Disable if no folder is selected
              }}
              accept="*"
              onChange={(event) =>
                handleMultipleFileChange(event, {
                  projectKey: pr_projectKey,
                  folderKey: selectedFolder,
                  uc_RootData_ClientKey: uc_RootData_ClientKey,
                  uc_setRootData_ClientKey: uc_setRootData_ClientKey,
                  uc_RootData_ClientUser: uc_RootData_ClientUser,
                  uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
                })
              }
              additionalFileUploadParams={{
                projectKey: pr_projectKey,
                folderKey: selectedFolder,
                uc_RootData_ClientKey: uc_RootData_ClientKey,
                uc_setRootData_ClientKey: uc_setRootData_ClientKey,
                uc_RootData_ClientUser: uc_RootData_ClientUser,
                uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
              }}
            />
          </span>
        </Tooltip>
      </Box>
    )
  }

  const rJSX_SelectedDocumentsFolderButton = (folderName: JSX.Element | string): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        color="inherit"
        className="tw-mr-2 tw-mb-1"
      >
        <Icon
          icon="folder-open"
          className="tw-mr-2"
        ></Icon>
        {folderName}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ProjectRootDocumentsFolders = (): JSX.Element => {
    let foldersJSX = (
      <Box>
        <Box className="tw-mb-2">{rJSX_SelectedDocumentsFolderButton(rLIB('Project'))}</Box>
        <Grid2
          container
          spacing={2}
          columns={24}
        >
          {objectToArray(us_projectDocumentFolders).map((folder: TsInterface_UnspecifiedObject, index: number) => (
            <Grid2
              key={index}
              xs={8}
              sm={6}
              md={4}
              lg={3}
            >
              <Box
                className="tw-cursor-pointer tw-text-center"
                onClick={(event) => {
                  if (event.detail === 2) {
                    us_setDocumentsViewLevel('folder')
                    us_setSelectedDocumentsFolder(folder)
                  }
                }}
              >
                <Icon
                  icon="folder-open"
                  size="4x"
                />
                <br />
                {folder.name}
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    )
    return foldersJSX
  }

  const rJSX_DocumentFilterButton = (): JSX.Element => {
    let documentFilterButton = (
      <Button
        className="tw-m-auto tw-opacity-30 tw-ml-2"
        color="inherit"
        variant="outlined"
        startIcon={
          <Icon
            icon="filter"
            type="light"
          ></Icon>
        }
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {
                  table_filter: us_documentTableFilter,
                },
                formInputs: formInputs_DocumentTableFilter,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    us_setDocumentTableFilter(formSubmittedData.table_filter)
                    resolve({ success: true })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Select a Filter'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="filter"
                  />
                ),
              },
            },
          })
        }}
      >
        {documentFilterOptions[us_documentTableFilter]['value']}
      </Button>
    )
    return documentFilterButton
  }

  const rJSX_FolderListCaretButton = (): JSX.Element => {
    let buttonJSX = (
      <Icon
        icon="chevron-right"
        className="tw-mr-2 tw-opacity-40"
      ></Icon>
    )
    return buttonJSX
  }

  const rJSX_SelectedPhotoFolderButton = (folderName: JSX.Element | string): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        color="inherit"
        className="tw-mr-3 tw-mb-1"
      >
        <Icon
          icon="folder-open"
          className="tw-mr-2"
        ></Icon>
        {folderName}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ProjectTaskDocumentsFolders = (): JSX.Element => {
    // Fetch available folders using the clientKey

    let documentTableJSX = <></>
    if (us_selectedDocumentsFolderData != null && returnTaskDocuments().length > 0) {
      documentTableJSX = (
        <Card>
          <TableBasic
            tableAdditionalData={{
              projectKey: pr_projectKey,
              folderKey: us_selectedDocumentsFolder.key,
              readOrWrite: pr_readOrWrite,
            }}
            tableColumns={tableColumns_DocumentsFolder}
            tableData={returnTaskDocuments()}
            tableSettings={tableSettings_DocumentsFolder}
          />
        </Card>
      )
    } else {
      documentTableJSX = (
        <Box className="tw-text-center">
          <Typography variant="h6">{rLIB('No documents')}</Typography>
        </Box>
      )
    }
    let uploadButtonJSX = <></>
    if (pr_readOrWrite === 'write') {
      uploadButtonJSX = (
        <FileUploadButton
          multiple={false}
          button={{
            text: rLIB('Upload Document') as JSX.Element,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              ></Icon>
            ),
            color: 'info',
            className: '',
            variant: 'contained',
            disabled: false,
          }}
          accept="*"
          onChange={uploadProjectDocument}
          additionalFileUploadParams={{
            projectKey: pr_projectKey,
            folderKey: us_selectedDocumentsFolder.key,
          }}
        />
      )
    }
    let documentsListJSX = (
      <Box>
        <Box className="tw-mb-2">
          {rJSX_DocumentsRootButton()}
          {rJSX_FolderListCaretButton()}
          {rJSX_SelectedPhotoFolderButton(us_selectedDocumentsFolder.name)}
          {uploadButtonJSX}
          {rJSX_DocumentFilterButton()}
        </Box>
        {documentTableJSX}
      </Box>
    )

    return documentsListJSX
  }

  const rJSX_DocumentsTab = (): JSX.Element => {
    // Transform folder data into an array of { key, name }
    // const availableFolders = Object.values(us_projectDocumentFolders).map((folder: any) => ({
    //   key: folder.key,
    //   name: folder.name,
    // }))
    let tabJSX = <></>
    if (us_documentsViewLevel === 'root') {
      tabJSX = (
        <Box>
          {/* Render the upload button at the root level */}
          {rJSX_DocumentsUploadButton()}
          {/* Render the root document folders */}
          {rJSX_ProjectRootDocumentsFolders()}
        </Box>
      )
    } else if (us_documentsViewLevel === 'folder') {
      tabJSX = <Box>{rJSX_ProjectTaskDocumentsFolders()}</Box>
    } else {
      tabJSX = <Box>{rJSX_ProjectRootDocumentsFolders()}</Box>
    }
    return tabJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Box className="tw-m-auto">{rJSX_DocumentsTab()}</Box>

        {Object.keys(us_selectedDocumentsFolder).length === 0 && (
          <Card className="tw-mt-12">
            <Box
              sx={{
                borderRadius: '8px', // Rounded corners
              }}
            >
              {rJSX_DocumentsTable()}
            </Box>
          </Card>
        )}
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
