/* eslint-disable react/prop-types */
///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, MenuItem, Stack, TextField } from '@mui/material'
import React, { memo, useEffect, useRef, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { Icon } from '../icons/icon'

///////////////////////////////
// Typescript
///////////////////////////////

export interface tsI_MultipleChoiceOption {
  key: string | number | boolean
  value: string | JSX.Element
}

interface tsI_DirectMultipleChoiceEditInput {
  selectedOption: tsI_MultipleChoiceOption
  options: tsI_MultipleChoiceOption[]
  onEnter: (value: TsInterface_UnspecifiedObject) => Promise<unknown>
  cancelEdit: () => void
}

interface tsI_DirectMultipleChoiceEdit {
  fullyClickable?: boolean
  optionType: 'static' | 'dynamic'
  displayText?: string | JSX.Element
  selectedOption: tsI_MultipleChoiceOption
  options?: tsI_MultipleChoiceOption[]
  loadOptions?: () => Promise<tsI_MultipleChoiceOption[]>
  onEnter: (value: TsInterface_UnspecifiedObject) => Promise<unknown>
  textCssClassName?: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const DirectMultipleChoiceEdit: React.FC<tsI_DirectMultipleChoiceEdit> = memo(function DirectMultipleChoiceEdit({
  fullyClickable,
  displayText,
  selectedOption,
  optionType,
  options,
  loadOptions,
  onEnter,
  textCssClassName,
}) {
  // Hooks
  const [us_mode, us_setMode] = useState<'view' | 'edit'>('view')
  const [us_loading, us_setLoading] = useState(false)
  const [us_options, us_setOptions] = useState<tsI_MultipleChoiceOption[]>(options || [])

  // UseEffect
  useEffect(() => {
    if (optionType === 'dynamic' && loadOptions != null && us_mode === 'edit') {
      us_setLoading(true)
      loadOptions()
        .then((options) => {
          us_setOptions(options)
          us_setLoading(false)
        })
        .catch((error) => {
          console.error(error)
          us_setLoading(false)
        })
    }
  }, [optionType, loadOptions, us_mode])

  // Functions

  // JSX
  const rJSX_DisplayText = (): JSX.Element => {
    if (displayText != null && displayText != '') {
      return <>{displayText}</>
    } else {
      return <>{selectedOption.value}</>
    }
  }

  const rJSX_Component = (): JSX.Element => {
    let loadingIconJSX = <></>
    if (us_loading === true) {
      loadingIconJSX = (
        <Box className="tw-mr-2">
          <Icon
            icon="spinner"
            className="tw-animate-spin"
          />
        </Box>
      )
    }
    if (us_mode === 'view' || us_loading === true || us_options.length === 0) {
      if (textCssClassName == null) {
        textCssClassName = ''
      }
      textCssClassName += ' tw-cursor-default tw-inline-flex'
      if (fullyClickable === true) {
        textCssClassName += ' tw-cursor-pointer'
      }
      let editIconJSX = (
        <Box
          className="edit-icon tw-cursor-pointer tw-ml-2"
          sx={{
            opacity: 0,
            transition: 'opacity 0.2s',
          }}
        >
          <Icon
            icon="pen-circle"
            onClick={() => {
              us_setMode('edit')
            }}
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            sx={{
              'color': themeVariables.gray_400,
              '&:hover': {
                color: themeVariables.success_main,
              },
            }}
          />
        </Box>
      )
      if (fullyClickable === true) {
        editIconJSX = <></>
      }
      if (us_loading === true) {
        editIconJSX = loadingIconJSX
      }
      return (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            '&:hover .edit-icon': {
              opacity: 1,
            },
          }}
        >
          <Box
            className={textCssClassName}
            onClick={() => {
              if (fullyClickable === true) {
                us_setMode('edit')
              }
            }}
          >
            {rJSX_DisplayText()}
          </Box>
          {editIconJSX}
        </Stack>
      )
    } else {
      return (
        <Stack
          direction="row"
          spacing={1}
          className="tw-inline-flex"
        >
          <DirectMultipleChoiceEditInput
            selectedOption={selectedOption}
            options={us_options || []}
            onEnter={onEnter}
            cancelEdit={() => {
              us_setMode('view')
            }}
          />
          <Box
            sx={{
              'color': themeVariables.gray_400,
              '&:hover': {
                color: themeVariables.error_main,
              },
              'paddingTop': '6px',
              'marginLeft': '8px',
            }}
          >
            <Icon
              icon="square-xmark"
              className="tw-cursor-pointer tw-inline-block"
              tooltip={rLIB('Cancel')}
              tooltipPlacement="right"
              size="xl"
              onClick={() => {
                us_setMode('view')
              }}
            />
          </Box>
        </Stack>
      )
    }
  }

  return <>{rJSX_Component()}</>
})

export const DirectMultipleChoiceEditInput: React.FC<tsI_DirectMultipleChoiceEditInput> = memo(function DirectMultipleChoiceEditInput({
  selectedOption,
  options,
  onEnter,
  cancelEdit,
}) {
  // Hooks
  const [us_selectedOption, us_setSelectedOption] = useState<TsInterface_UnspecifiedObject>(selectedOption)
  const [us_submitting, us_setSubmitting] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  // Focus input on mount
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  // Functions
  const handleSubmit = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault()
    }
    if (onEnter) {
      us_setSubmitting(true)
      onEnter(us_selectedOption).then(() => {
        us_setSubmitting(false)
        cancelEdit()
      })
    }
  }

  // JSX
  return (
    <Box component="span">
      <form onSubmit={handleSubmit}>
        <FormControl className="tw-m-0">
          <TextField
            inputRef={inputRef}
            select
            color="primary"
            value={us_selectedOption.key || ''}
            margin="normal"
            sx={{ width: '300px' }}
            className="bp_thin_select_input tw-m-0"
            disabled={us_submitting}
            onChange={(event: any) => {
              if (event?.target?.value != null) {
                us_setSubmitting(true)
                const selectedOption = options.find((option) => option.key === event.target.value)
                if (selectedOption) {
                  us_setSelectedOption(selectedOption)
                }
                if (selectedOption != null) {
                  onEnter(selectedOption).then(() => {
                    us_setSubmitting(false)
                    cancelEdit()
                  })
                }
              }
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              // Cancel edit on escape
              if (event.key === 'Escape' || event.key === 'Esc') {
                event.preventDefault()
                cancelEdit()
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: us_submitting ? (
                <Box
                  className="tw-flex tw-items-center tw-pr-2"
                  sx={{ color: themeVariables.gray_400 }}
                >
                  <Icon
                    icon="spinner"
                    className="tw-animate-spin"
                  />
                </Box>
              ) : null,
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option['key'] as string}
                value={option['key'] as string}
              >
                {option['value']}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </form>
    </Box>
  )
})
