///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Material_Imports_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main')
}

export const DatabaseRef_Material_Imports_Document = (clientKey: string, importKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main', importKey)
}

export const DatabaseRef_Imports_Missing_Tasks = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_task_key', comparator: '==', value: 'missing' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Material_Imports_By_Project = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}
