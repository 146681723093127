///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select } from '@mui/material/'
import { tableColumns_AllSalesInstallPartnerProjects, tableSettings_AllSalesProjects } from 'app/models/projects/project_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveSalesPartnerTaskWorkflows_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_Projects_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ClientSharingSettings_Document } from 'rfbp_aux/services/database_endpoints/settings/sharing'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetLiveDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { PacingProjectPhasesTab } from '../pacing/tabs/tab_project_phases'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesInstallPartnerProjectsIndexPage']['key']

// Local Storage Keys
const globalInstallPartnerLocalStorageKey: string = 'globalInstallPartnerSettings'

// Table Status Filter Options
let projectStatusOptions: TsInterface_UnspecifiedObject = {
  active: { key: 'active', value: rLIB('Active'), disabled: false },
  on_hold: { key: 'on_hold', value: rLIB('On Hold'), disabled: false },
  cancelled: { key: 'cancelled', value: rLIB('Cancelled'), disabled: false },
  completed: { key: 'completed', value: rLIB('Completed'), disabled: false },
}

const formInputs_TableStatusFilter: TsInterface_FormInputs = {
  status: {
    key: 'status',
    label: rLIB('Project Status'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(projectStatusOptions),
    submit_on_change: true,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_clientSharingSettings, us_setClientSharingSettings] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedInstallPartnerKey, us_setSelectedInstallPartnerKey] = useState<string | null>(null)
  const [us_installPartnerAssociatedSalesPartnerKey, us_setInstallPartnerAssociatedSalesPartnerKey] = useState<string | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const [us_tableFilterStatus, us_setTableFilterStatus] = useState<string>('active')
  const [us_selectedTaskWorkflowKey, us_setSelectedTaskWorkflowKey] = useState<string>('')
  const [us_taskWorkflowsArray, us_setTaskWorkflowsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsObject, us_setTaskWorkflowsObject] = useState<TsInterface_UnspecifiedObject>({})

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Projects', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientSharingSettings(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ClientSharingSettings_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    // Runs one time after data is loaded from cloud function
    let pageLocalStorageData = getPageLocalStorage(globalInstallPartnerLocalStorageKey)
    // Get Local Storage Data and set to state
    if (pageLocalStorageData != null && pageLocalStorageData['selected_install_partner_key'] != null) {
      us_setSelectedInstallPartnerKey(pageLocalStorageData['selected_install_partner_key'])
      us_setInstallPartnerAssociatedSalesPartnerKey(null)
    }
    if (getProp(pageLocalStorageData, 'selected_task_workflow_key', null) != null) {
      us_setSelectedTaskWorkflowKey(getProp(pageLocalStorageData, 'selected_task_workflow_key', null))
    }
    return () => {}
  }, [])

  useEffect(() => {
    if (
      us_selectedInstallPartnerKey != null &&
      us_selectedInstallPartnerKey != '' &&
      us_clientSharingSettings != null &&
      us_clientSharingSettings[us_selectedInstallPartnerKey] != null &&
      us_clientSharingSettings[us_selectedInstallPartnerKey]['project_associated_sales_partner_key'] != null
    ) {
      us_setInstallPartnerAssociatedSalesPartnerKey(us_clientSharingSettings[us_selectedInstallPartnerKey]['project_associated_sales_partner_key'])
    }
    return () => {}
  }, [us_selectedInstallPartnerKey, us_clientSharingSettings])

  useEffect(() => {
    if (
      us_installPartnerAssociatedSalesPartnerKey != null &&
      us_installPartnerAssociatedSalesPartnerKey != '' &&
      us_selectedInstallPartnerKey != null &&
      us_selectedInstallPartnerKey != ''
    ) {
      DatabaseGetCollection(DatabaseRef_ActiveSalesPartnerTaskWorkflows_Query(us_selectedInstallPartnerKey, us_installPartnerAssociatedSalesPartnerKey))
        .then((res_DGC) => {
          us_setTaskWorkflowsObject(res_DGC.data)
          let workflows: TsInterface_UnspecifiedObject[] = []
          for (let workflowKey in res_DGC.data) {
            let loopWorkflow = res_DGC.data[workflowKey]
            workflows.push({ key: loopWorkflow.key, value: loopWorkflow.name })
          }
          us_setTaskWorkflowsArray(workflows)
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedInstallPartnerKey, us_installPartnerAssociatedSalesPartnerKey])

  // Functions
  const tableDatabaseEndpoint_AllSalesProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    queryOperatorsArray = [
      { prop: 'associated_sales_partner_key', comparator: '==', value: us_installPartnerAssociatedSalesPartnerKey as string },
      { prop: 'status', comparator: '==', value: us_tableFilterStatus },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_customer_name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_Projects_Collection(us_selectedInstallPartnerKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_ProjectTable = (): JSX.Element => {
    let tableJSX = <></>
    if (
      us_installPartnerAssociatedSalesPartnerKey != null &&
      us_installPartnerAssociatedSalesPartnerKey != '' &&
      us_selectedInstallPartnerKey != null &&
      us_selectedInstallPartnerKey != ''
    ) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{
              clientKey: us_selectedInstallPartnerKey,
            }}
            tableColumns={tableColumns_AllSalesInstallPartnerProjects}
            tableDatabaseEndpoint={tableDatabaseEndpoint_AllSalesProjects}
            tableSettings={tableSettings_AllSalesProjects}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_TableStatusFilterButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        color="secondary"
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="filters"
            type="solid"
          />
        }
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: { status: us_tableFilterStatus },
                formInputs: formInputs_TableStatusFilter,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {
                  submit_button_hide: true,
                },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    us_setTableFilterStatus(formSubmittedData.status)
                    resolve({ success: true })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Table Status Filter'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="filters"
                  />
                ),
              },
            },
          })
        }}
      >
        {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {rLIB('Projects')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_InstallPartnerSelection = (): JSX.Element => {
    let dropdownJSX = <></>
    if (us_clientSharingSettings != null && objectToArray(us_clientSharingSettings).length > 0) {
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              us_setSelectedInstallPartnerKey(event.target.value)
              us_setInstallPartnerAssociatedSalesPartnerKey(null)
              setPageLocalStorage(globalInstallPartnerLocalStorageKey, 'selected_install_partner_key', event.target.value)
            }}
            value={us_selectedInstallPartnerKey || ''}
          >
            {objectToArray(us_clientSharingSettings).map((installPartner: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={installPartner['key']}
              >
                {installPartner['name']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_WorkflowSelection = (): JSX.Element => {
    if (us_taskWorkflowsArray.length > 0) {
      return (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedTaskWorkflowKey}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedTaskWorkflowKey(event.target.value)
                setPageLocalStorage(globalInstallPartnerLocalStorageKey, 'selected_task_workflow_key', event.target.value)
              }
            }}
            variant="outlined"
          >
            {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    } else {
      return <></>
    }
  }

  const rJSX_PacingTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_selectedInstallPartnerKey != null && us_selectedInstallPartnerKey !== '') {
      tabJSX = (
        <Box className="tw-h-full">
          <PacingProjectPhasesTab
            selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
            clientKeyOverride={us_selectedInstallPartnerKey}
            useSalesPartnerInstallPartnerProjectView={true}
            taskWorkflows={us_taskWorkflowsObject}
            readOrWrite="read"
            projectViewPageKey={'AdminActiveProjectViewPage'}
          />
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_PageTabs = (): JSX.Element => {
    let tabsJSX = <></>
    tabsJSX = (
      <TabsUrl
        tabsSettings={{
          baseUrl: ApplicationPages.SalesInstallPartnerProjectsIndexPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: rLIB('Projects', false) as string,
        }}
        tabs={[
          {
            tabHeader: rLIB('Projects'),
            tabUrlKey: 'projects',
            tabButtons: [
              { fullJSX: rJSX_InstallPartnerSelection(), minJSX: rJSX_InstallPartnerSelection(), sizeCutoff: 0 },
              { fullJSX: rJSX_TableStatusFilterButton(), minJSX: rJSX_TableStatusFilterButton(), sizeCutoff: 0 },
            ],
            tabContent: <Box className="tw-h-full">{rJSX_ProjectTable()}</Box>,
          },
          {
            tabHeader: rLIB('Pacing'),
            tabUrlKey: 'pacing',
            tabButtons: [
              { fullJSX: rJSX_InstallPartnerSelection(), minJSX: rJSX_InstallPartnerSelection(), sizeCutoff: 0 },
              { fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 },
            ],
            tabContent: <Box className="tw-h-full">{rJSX_PacingTab()}</Box>,
          },
        ]}
      />
    )
    return tabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Projects')}
        pageKey={pageKey}
        content={<Box>{rJSX_PageTabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
